import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchClasses } from '../../../actions/class/classList'
import { columns } from '../../../components/ClassListTable/ClassListColumns'
import ClassesList from '../../components/ClassesList'
import { FilterKind } from '../../components/Utils/Filters/index'

const { ruee, department, school, group, day, date, time, kind, status, reportButton } = columns

const RemoteTeacherClassesList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchClasses(history.location.search))
  }, [dispatch, history.location.search])

  return <ClassesList
    columns={[ruee, department, school, group, day, date, time, kind, status, reportButton]}
  >
    <FilterKind />
    {/* TODO: Horario ¿Qué tipo? */}
  </ClassesList>
}

export default RemoteTeacherClassesList
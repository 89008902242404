import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  fabAdd: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
  },
  fabAction: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3) + 75
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))
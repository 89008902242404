import 'moment/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'
import * as moment from 'moment'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import indigo from '@material-ui/core/colors/indigo'
import createStore from './utils/createStore'
import MomentUtils from '@date-io/moment'

import Alert from './views/components/Utils/Alert'
import MainLayout from './views/Layout/MainLayout'
import AuthWrapper from './views/Auth/AuthWrapper'
import AdminWrapper from './views/Administrator/AdminWrapper'
import TeacherWrapper from './views/RemoteTeacher/RemoteTeacherWrapper'
import ReferentWrapper from './views/Referent/ReferentWrapper'
import DownloadReports from './views/components/DownloadReports'

import Login from './views/Auth/Login'
import PasswordRecovery from './views/Auth/PasswordRecovery'

import AdminSchoolList from './views/Administrator/SchoolList'
import AdminSchoolCsvCreation from './views/Administrator/SchoolCsvCreation'
import AdminSchoolContactList from './views/Administrator/SchoolContactList'
import AdminSchoolContactCsvCreation from './views/Administrator/SchoolContactCsvCreation'
import AdminSchoolJabberUserList from './views/Administrator/SchoolJabberUserList'
import AdminSchoolJabberUserCsvCreation from './views/Administrator/SchoolJabberUserCsvCreation'
import AdminNonSchoolDayList from './views/Administrator/NonSchoolDayList'
import AdminNonSchoolDayCsvCreation from './views/Administrator/NonSchoolDayCsvCreation'
import AdminRemoteTeacherList from './views/Administrator/RemoteTeacherList'
import AdminConfiguration from './views/Administrator/Configuration'
import AdminRemoteTeacherCsvCreation from './views/Administrator/RemoteTeacherCsvCreation'
import AdminAssignationList from './views/Administrator/AssignationList'
import AdminAssignationCsvCreation from './views/Administrator/AssignationCsvCreation'
import AdminAssignationDetail from './views/Administrator/AssignationDetail'
import AdminClassesList from './views/Administrator/ClassesList'
import AdminSettlement from './views/Administrator/PaymentMails'
import AdminSettlementRecords from './views/Administrator/SettlementRecords'

import TeacherDashboard from './views/RemoteTeacher/Dashboard'
import TeacherAssignationList from './views/RemoteTeacher/AssignationList'
import TeacherClassesList from './views/RemoteTeacher/ClassesList'
import TeacherAssignationDetail from './views/RemoteTeacher/AssignationDetail'
import TeacherProfile from './views/RemoteTeacher/Profile'
import TeacherAvailability from './views/RemoteTeacher/Availability'
import TeacherTutorials from './views/RemoteTeacher/Tutorials'
import TeacherNonSchoolDayList from './views/RemoteTeacher/NonSchoolDayList'

import ReferentAssignationList from './views/Referent/AssignationList/index'
import ReferentAssignationDetail from './views/Referent/AssignationDetail'
import ReferentClassesList from './views/Referent/ClassesList'
import { ADMIN, REFERENT, Role, RT } from './models/User'
import CeiBot from './components/ceiBot'

moment.locale('es')

export const roleToURLPrefix = (role: Role): string => {
  switch(role){
    case ADMIN: return 'admin'
    case REFERENT: return 'referent'
    case RT: return 'teacher'
  }
}

const theme = createMuiTheme({
  palette: {
    primary: indigo,
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
}))

const App: React.FC = () => {
  const classes = useStyles()
  
  return (
    <Provider store={createStore()}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Alert />
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />          
            <div className={classes.root}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/password-recovery" component={PasswordRecovery} />
                <AuthWrapper>
                  <MainLayout>
                    <Route path="/admin">
                      <AdminWrapper redirect={true}>
                        <Route path={["/admin", "/admin/schools"]} exact component={AdminSchoolList} />
                        <Route path="/admin/schools/create" exact component={AdminSchoolCsvCreation} />
                        <Route path="/admin/school-contacts" exact component={AdminSchoolContactList} />
                        <Route path="/admin/school-contacts/create" exact component={AdminSchoolContactCsvCreation} />
                        <Route path="/admin/school-jabber-users" exact component={AdminSchoolJabberUserList} />
                        <Route path="/admin/school-jabber-users/create" exact component={AdminSchoolJabberUserCsvCreation} />
                        <Route path="/admin/non-school-days" exact component={AdminNonSchoolDayList} />
                        <Route path="/admin/non-school-days/create" exact component={AdminNonSchoolDayCsvCreation} />
                        <Route path="/admin/remote-teachers" exact component={AdminRemoteTeacherList} />
                        <Route path="/admin/remote-teachers/create" exact component={AdminRemoteTeacherCsvCreation} />
                        <Route path="/admin/assignations" exact component={AdminAssignationList} />
                        <Route path="/admin/classes" exact component={AdminClassesList} />
                        <Route path="/admin/download" exact component={DownloadReports} />
                        <Switch>
                          <Route path="/admin/assignations/create" exact component={AdminAssignationCsvCreation} />
                          <Route path="/admin/assignations/:assignationId" exact component={AdminAssignationDetail} />
                        </Switch>
                        <Route path="/admin/settlement" exact component={AdminSettlement} />
                        <Route path="/admin/settlement/records" exact component={AdminSettlementRecords} />
                        <Route path="/admin/configuration" exact component={AdminConfiguration} />
                      </AdminWrapper>
                    </Route>
                    <Route path="/teacher">
                      <TeacherWrapper redirect={true}>
                        <Route path={["/teacher", "/teacher/dashboard"]} exact component={TeacherDashboard} />
                        <Route path="/teacher/profile" exact component={TeacherProfile} />
                        <Route path="/teacher/availability" exact component={TeacherAvailability} />
                        <Route path="/teacher/assignations" exact component={TeacherAssignationList} />
                        <Route path="/teacher/assignations/:assignationId" exact component={TeacherAssignationDetail} />
                        <Route path="/teacher/classes" exact component={TeacherClassesList} />
                        <Route path="/teacher/download" exact component={DownloadReports} />
                        <Route path="/teacher/tutorials" exact component={TeacherTutorials} />
                        <Route path="/teacher/non-school-days" exact component={TeacherNonSchoolDayList} />
                      </TeacherWrapper>
                    </Route>
                    <Route path="/referent">
                      <ReferentWrapper redirect={true}>
                        <Route path={["/referent", "/referent/assignations"]} exact component={ReferentAssignationList} />
                        <Route path="/referent/assignations/:assignationId" exact component={ReferentAssignationDetail} />
                        <Route path="/referent/classes" exact component={ReferentClassesList} />
                        <Route path="/referent/download" exact component={DownloadReports} />
                      </ReferentWrapper>
                    </Route>
                  </MainLayout>
                </AuthWrapper>
              </Switch>
            </div>
            <CeiBot/>
          </MuiThemeProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  )
}

export default App

import React, { useState, useRef } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Field, parse } from '../../utils/csvParser';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2)
    },
    root: {
      display: 'flex',
      flexDirection: 'row'
    },
    columnContent: {
      flex: 1
    },
    columnButton: {
      marginLeft: theme.spacing(3),
      flexBasis: 180,
      flex: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: 5
    },
    error: {
      marginTop: theme.spacing(1),
      color: theme.palette.error.dark
    },
    button: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    info: {
      borderStyle: 'dashed',
      borderColor: '#999',
      borderWidth: 2,
      padding: theme.spacing(3),
      color: '#888',
      fontSize: 14,
      fontFamily: 'Monospace',
      fontWeight: 500
    }
  }),
);

export enum FieldType { String, Number }

interface Props {
  fields: Field[];
  onSubmit: (records: any[]) => void;
}

const BulkUpsertUpload: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const [error, setError] = useState<string|null>(null);
  const fileInput = useRef(null);

  const handleSubmit = () => {
    const files = (fileInput as any).current.files as File[];

    if (!files.length) {
      setError('Debe seleccionar un archivo .csv');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = (evt: any) => {
      if (evt.target.readyState === FileReader.DONE) {
        try {
          const records = parse(evt.target.result, props.fields)
          setError(null)
          props.onSubmit(records)
        } catch (err) {
          console.log(err);
          setError('Error al procesar el archivo');
        }
      } else {
        setError('Error al subir el archivo');
      }
    };

    reader.readAsText(files[0]);
  };

  return (
    <div>
      <Paper square className={classes.paper}>
        <div className={classes.root}>
          <div className={classes.columnContent}>
            <TextField
              InputLabelProps={{ shrink: true }}
              inputRef={fileInput}
              label="Archivo CSV"
              margin="normal"
              variant="outlined"
              type="file"
              fullWidth
            />
          </div>
          <div className={classes.columnButton}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleSubmit}
            >
              Subir archivo
            </Button>
          </div>
        </div>
        <Typography variant="body2">
          Recordá que la primera línea del CSV siempre deben ser los nombres de las columnas
        </Typography>
        { error ?
          <Typography align="center" variant="subtitle2" className={classes.error}>
            {error}
          </Typography>
        : null }
      </Paper>
      <div className={classes.info}>
        <Box>
          Formato de csv esperado<br/>
          -----------------------
        </Box>
        {props.fields.map((field, index) =>
          <Box key={index}>{`- ${index >= 9 ? index+1 : '0'+(index+1)}. ${field.display}`}</Box>
        )}
      </div>
    </div>
  );
}

export default BulkUpsertUpload
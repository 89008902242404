import React, { FC, useState } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { startOfTheWeek, endOfTheWeek } from '../../../utils/days';
import { Container, Paper, Typography } from "@material-ui/core";
import { Moment } from "moment";
import useStyles from "./ExportReportsCard/styles";

export interface DateInterval {
  from: Moment
  to: Moment
}

export interface DateIntervalButtonProps {
  dateInterval: DateInterval
}
interface Props {
  title: string
  Button: FC<DateIntervalButtonProps>
}

export default (props: Props) => {
  const classes = useStyles();
  const [interval, setInterval] = useState<DateInterval>({
    from: startOfTheWeek().subtract(1, 'week'),
    to: endOfTheWeek().subtract(1, 'week')
  })

  const onChangeFrom = (date) => {
    setInterval({
      ...interval,
      from: date
    })
  }

  const onChangeTo = (date) => {
    setInterval({
      ...interval,
      to: date
    })
  }

  return (
    <Container>
      <Paper square className={classes.paper}>
        <Typography variant="h5">
          {props.title}
        </Typography>
        <div>
          <KeyboardDatePicker
            label={"Fecha desde"}
            margin="normal"
            format="DD/MM/YYYY"
            value={interval.from}
            onChange={onChangeFrom}
            disableToolbar
            KeyboardButtonProps={{ 'aria-label': "Fecha desde" }}
          />

          <KeyboardDatePicker
            label={"Fecha hasta"}
            margin="normal"
            format="DD/MM/YYYY"
            value={interval.to}
            onChange={onChangeTo}
            disableToolbar
            KeyboardButtonProps={{ 'aria-label': "Fecha hasta" }}
          />

          <props.Button dateInterval={interval} />

        </div>
      </Paper>
    </Container>
  )
}
import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchSchools } from '../../../actions/school/schoolList'
import { upsertSchool } from '../../../actions/school/schoolUpsert'
import SchoolEditDialog from '../../../components/School/SchoolEditDialog'
import SchoolListTable from '../../../components/School/SchoolListTable'
import School, { SchoolInput } from '../../../models/School'
import { AppState } from '../../../reducers'
import { SchoolListState } from '../../../reducers/school/schoolList'
import { FiltersBar } from '../../components/Utils/Filters/Filter'
import { FilterRue, FilterDepartment, FilterSchoolName } from '../../components/Utils/Filters'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
)

interface Props {
  schoolList: SchoolListState
}

const SchoolList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedSchool, setSelectedSchool] = useState<School | null>(null)

  useEffect(() => {
    dispatch(fetchSchools(history.location.search))
  }, [dispatch, history.location.search])

  const handleUpdateSchool = (data: SchoolInput) => {
    if (selectedSchool) {

      dispatch(
        upsertSchool(
          {
            id: selectedSchool.id,
            ...data
          } as School
        )
      )
      setSelectedSchool(null)
    }
  }

  return (
    <div>
      <FiltersBar>
        <FilterRue />
        <FilterDepartment />
        <FilterSchoolName />
      </FiltersBar>

      <SchoolListTable
        rows={(props.schoolList.schools || []).map(s => ({ data: s }))}
        loading={props.schoolList.isFetching}
        pagination={props.schoolList.pagination || undefined}
        showEditButton={true}
        onClickEdit={row => setSelectedSchool(row.data as School)}
      />

      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/schools/create', { title: 'Crear escuelas' })}>
        <AddIcon />
      </Fab>

      {selectedSchool &&
        <SchoolEditDialog
          school={selectedSchool}
          onDismiss={() => setSelectedSchool(null)}
          onSubmit={handleUpdateSchool}
        />
      }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  schoolList: state.schoolList,
})

export default connect(mapStateToProps, null)(SchoolList)
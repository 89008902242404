import React, { FC } from 'react'
import Pagination from '../models/Pagination'
import { useHistory } from 'react-router-dom'
import { TablePagination } from '@material-ui/core'

interface PaginationProps {
  pagination?: Pagination
}

const PaginationButtons: FC<PaginationProps> = ({ pagination }) => {
  const history = useHistory()
  
  const handleChangePage = (page: number) => {
    const urlSearch = new URLSearchParams(history.location.search)
    urlSearch.set('page', page.toString())
    history.push({ search: urlSearch.toString() })
  }
  
  if (!pagination) return <></>
  return <TablePagination
    component="div"
    page={pagination.page}
    count={pagination.count}
    rowsPerPage={pagination.size}
    rowsPerPageOptions={[pagination.size]}
    onChangePage={(mouseEvent, page) => { handleChangePage(page) }}
    labelDisplayedRows={info => `${info.from}-${info.to} de ${info.count}`}
  />
}

export default PaginationButtons
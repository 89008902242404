import React from 'react'
import Assignation from '../../../models/Assignation'
import ClassroomTeacher from '../../../models/ClassroomTeacher'
import EntityDetails from '../Utils/EntityDetails'
import AssignationClassroomTeacherEdit from './AssignationClassroomTeacherEdit'

interface AssignationClassroomTeacherProps {
  teacher: ClassroomTeacher
  assignation: Assignation
  disabled: boolean
}

const AssignationClassroomTeacher: React.FC<AssignationClassroomTeacherProps> = ({ teacher, assignation, disabled }) => (
  <EntityDetails
    title="Docente de aula"
    entity={teacher}
    rows={[
      ['Cédula', 'documentNumber'],
      ['Nombre', 'firstName'],
      ['Apellido', 'lastName'],
      ['Email', 'email'],
      ['Teléfono', 'fullPhoneNumber'],
    ]}
    gadgets={!disabled && <AssignationClassroomTeacherEdit classroomTeacher={teacher} assignation={assignation} />}
  />
)

export default AssignationClassroomTeacher
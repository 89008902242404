import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row'
    },
    formInputLeft: {
      flex: 1,
      marginRight: theme.spacing(1.5)
    },
    formInputRight: {
      flex: 1,
      marginLeft: theme.spacing(1.5)
    }
  }),
)
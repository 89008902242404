import React from 'react'
import { default as DialogActions, default as DialogContent } from '@material-ui/core/DialogContent'
import { Dialog, DialogTitle } from '@material-ui/core'
import { not } from 'ramda'
import { isNilOrEmpty } from '../../../../utils/StringUtils'
import { useStyles } from './styles'

interface AnahiDialogProps {
  title?: string
  onClose?: () => void
  onSubmit?: () => void
  open?: boolean
  body?: React.ReactNode
  actions?: React.ReactNode
}

const AnahiDialog: React.FC<AnahiDialogProps> = ({ title, open = true, onClose, body, actions, onSubmit }) => (
  <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
    <DialogTitle>{title || ""}</DialogTitle>
    <form className={useStyles().form} onSubmit={(event) => { event.preventDefault(); onSubmit && onSubmit() }}>
      <DialogContent dividers={not(isNilOrEmpty(body))}>
        {body}
      </DialogContent>
      <DialogActions className={useStyles().actions}>
        {actions}
      </DialogActions>
    </form>
  </Dialog>
)

export default AnahiDialog
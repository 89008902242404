import React from 'react'
import moment from 'moment'
import Class, { reasonToShortDescription } from '../../models/Class'
import { capitalize } from '../../utils/StringUtils'
import { statusToDescription } from '../../models/Class'
import classUpdate from '../../actions/class/classUpdate'
import { SentToCeibal } from '../SentToCeibal'
import { ReportButton, SeeReportButton, ViewHistoryGroupClasses } from './ClassListButtons'

//TODO: Move
export type TableColumn<Entity> = {
  name?: string,
  value: (entity: Entity) => any
}

export type ClassTableColumn = TableColumn<Class>

//TODO: Tipar esto cuando FIXME
export const columns = {
  ruee: { name: 'RUEE', value: (aClass: Class) => aClass.assignation.group.school.rue },
  department: { name: 'Departamento', value: (aClass: Class) => aClass.assignation.group.school.department },
  school: { name: 'Escuela', value: (aClass: Class) => aClass.assignation.group.school.number },
  group: { name: 'Grupo', value: (aClass: Class) => <ViewHistoryGroupClasses aClass={aClass} /> },
  day: { name: 'Día', value: (aClass: Class) => capitalize(moment(aClass.sinceDate).format('dddd')) },
  date: { name: 'Fecha', value: (aClass: Class) => `${moment(aClass.sinceDate).date()}/${moment(aClass.sinceDate).month() + 1}` },
  time: { name: 'Horario', value: (aClass: Class) => moment(aClass.sinceDate).format("HH:mm") },
  remoteTeacher: { name: 'Docente Remoto', value: (aClass: Class) => aClass.teacher?.fullName },
  kind: { name: 'Tipo', value: (aClass: Class) => aClass.kind },
  status: { name: 'Estado', value: (aClass: Class) => capitalize(statusToDescription(aClass.status)) },
  cancellationReason: { name: 'Motivo', value: (aClass: Class) => aClass.cancellationReason && reasonToShortDescription(aClass.cancellationReason) },
  reportButton: { value: (aClass: Class) => <ReportButton aClass={aClass} /> },
  seeReportButton: { value: (aClass: Class) => <SeeReportButton aClass={aClass} /> },
  sentToCeibal: (dispatch) => //FIXME: El componente SentToCeibal debería crear el dispatch dentro, no recibirlo acá.
  ({
    name: 'En SVC', value: (aClass: Class) => <SentToCeibal defaultChecked={aClass.sentToCeibal} onChange={(sentToCeibal: boolean) => dispatch(classUpdate({ id: aClass.id, sentToCeibal } as Class, true))} />,
  })
}


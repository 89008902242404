import React from "react";
import { PluralPaymentMailConcept, SingularPaymentMailConcept } from ".";
import RemoteTeacher from "../../../models/RemoteTeacher";
import DatePickerButton, { DateInterval, DateIntervalButtonProps } from "../../components/Utils/DatePickerButton";
import { PaymentMailsSenderButton } from "./PaymentMailsSenderButton";

interface PaymentMailsPeriodPickerProps {
    singularConcept: SingularPaymentMailConcept
    pluralConcept: PluralPaymentMailConcept
    teachers: RemoteTeacher[]
    action: (interval: DateInterval, teachers: RemoteTeacher[]) => void
}

export const PaymentMailsPeriodPicker = ({singularConcept, pluralConcept, teachers, action}: PaymentMailsPeriodPickerProps) => {
    return (
      <DatePickerButton 
      title={`Envío de ${pluralConcept} del período`} 
      Button={(props: DateIntervalButtonProps) => 
        <PaymentMailsSenderButton 
          teachersToSend={teachers}
          singularMailConcept={singularConcept}
          pluralMailConcept={pluralConcept}
          action={() => action(props.dateInterval, teachers)}        
        />}
      />
    )
}
import requestAction from '../../utils/requestAction'
import { volatilAction } from '../../utils/sequenceActions'
import { pushAlert } from '../alert'

export const confirmAssignation = (assignationId: number) =>
  volatilAction(requestAction('ASSIGNATION_CONFIRM')(
    `assignations/${assignationId}/confirm`, 'POST', undefined,
    (dispatch, getState, response) => {
      if (response && response.assignation && response.assignation.id) {
        dispatch({
          type: 'ASSIGNATION_DETAIL',
          newApiState: {
            isFetching: false,
            didInvalidate: false,
            assignation: {
              ...getState().assignationDetail.assignation,
              ...response.assignation
            }
          } as any
        })
        dispatch(pushAlert('Asignación asignada'))
      }
    }
  ))

import { createStyles, IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core"
import { not } from "ramda"
import React, { FC } from "react"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            padding: 5
        }
    })
)

interface TooltipIconButtonProps {
    children?: any
    disabled?: boolean
    hidden?: boolean
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    tooltip?: string
}

const TooltipIconButton: FC<TooltipIconButtonProps> = ({ children, disabled = false, hidden = false, onClick, tooltip = "" }) => {
    const classes = useStyles()

    return (
        <div>
            {not(hidden) &&
                <Tooltip title={tooltip} aria-label={tooltip}>
                    <IconButton disabled={disabled} size="small" className={classes.iconButton} onClick={onClick}>
                        {children}
                    </IconButton>
                </Tooltip>
            }
        </div>
    )
}

export default TooltipIconButton
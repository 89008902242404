import { PaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import RemoteTeacher from '../../models/RemoteTeacher'


export interface RemoteTeacherListState extends PaginatedApiState {
  teachers?: RemoteTeacher[]
}

export default requestReducer<RemoteTeacherListState>('REMOTE_TEACHER_LIST')

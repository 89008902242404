import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(10),
      display: 'flex',
      flex: 1,
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
    entityDetails: {
      '& tr:nth-child(odd)': {
        backgroundColor: '#F4F6F8'
      }
    },
    cardTitle: {
      fontWeight: 'bold',
      fontSize: 'large'
    },
    gadgets: {
      textAlign: 'end'
    },
    infoCard: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%'
    }
  }),
)

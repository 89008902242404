import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import RemoteTeacher from '../../../models/RemoteTeacher'
import Assignation from '../../../models/Assignation'
import AutocompleteAsyncTextField from '../Utils/AutocompleteAsyncTextField'
import EditButtonDialog from '../Utils/Dialogs/EditButtonDialog'
import ConfirmDialog from '../Utils/Dialogs/ConfirmDialog'
import fetchApi, { getToken } from '../../../utils/fetchApi'
import { updateAssignation } from '../../../actions/assignation/assignationUpdate'

const AssignationRemoteTeacherEdit: React.FC<Assignation> = (assignation) => {
  const dispatch = useDispatch()
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [teacher, setTeacher] = useState<RemoteTeacher | null>(null)

  const close = () => { setOpenConfirm(false) }

  const handleDismiss = () => { close() }

  const handleAccept = () => { setOpenConfirm(true) }

  const handleConfirm = () => {
    dispatch(updateAssignation({ ...assignation, teacher: teacher! }))
    close()
  }

  const searchTeachers = (search: string) =>
    fetchApi(getToken(), 'GET', `remote-teachers?omnisearch=${search}`)
      .then(response => response.teachers)

  return (<>
    <EditButtonDialog
      title="Cambiar docente remoto"
      onAccept={handleAccept}
      disabled={teacher === null}
    >
      <AutocompleteAsyncTextField<RemoteTeacher>
        label="Buscar nuevo docente remoto"
        search={searchTeachers}
        selectLabel={teacher => `${teacher.documentNumber} - ${teacher.fullName}`}
        onSelect={setTeacher}
      />
    </EditButtonDialog>

    <ConfirmDialog
      open={openConfirm}
      title="Confirmar nuevo docente remoto"
      message={`¿Confirma que desea cambiar esta asignación a ${teacher?.fullName}? Tené en cuenta que esta acción cambiará todas las clases futuras de la asignación.`}
      onConfirm={handleConfirm}
      onDismiss={handleDismiss}
    />

  </>
  )
}

export default AssignationRemoteTeacherEdit


import React, { ReactElement, useState, useEffect } from 'react'
import Selector, { SelectorProps } from '../../../../components/Selector'
import { Fab, TextField, TextFieldProps, Grid, Button } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardDatePickerProps, KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'
import { useStyles } from './styles'
import { identity, isEmpty } from 'ramda'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { AccessTime } from '@material-ui/icons'
import { spacing } from "@material-ui/system"
import { styled } from "@material-ui/core/styles"
import { paramsToQueryString } from '../../../../utils/paramsToQueryString'
import AutocompleteAsyncTextField, { AutocompleteAsyncTextFieldProps } from '../AutocompleteAsyncTextField'

export type Filters = { [key: string]: string }

//TODO: Read further https://medium.com/@martin_hotell/react-children-composition-patterns-with-typescript-56dfc8923c64
export type FilterElement = ReactElement<FilterProps>

export interface FiltersBarProps {
  defaultFilters?: Filters
  children?: FilterElement | FilterElement[]
}

export interface FilterProps {
  name: string
  value?: any
  onChange?: (name: string, value: string) => void
  filters?: Filters
}

interface WeekFilterButton {
  from: Moment
  text: string
}

const StyledButton = styled(Button)(spacing)

export const FiltersBar: React.FC<FiltersBarProps> = ({ children, defaultFilters }) => {
  const classes = useStyles()
  const history = useHistory()

  const initFilters = defaultFilters || {}
  const [filters, setFilters] = useState<Filters>(searchToFilters(history.location.search, initFilters))

  useEffect(() => {
    if (isEmpty(history.location.search) && defaultFilters !== null) { submitSearch(filters) }
    if (!isEmpty(history.location.search)) {
      const searchFilters = searchToFilters(history.location.search)
      setFilters(searchFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters, history.location.search])

  const submitSearch = (newFilters: Filters) => {
    history.push({ search: filtersToSearch(newFilters), state: history.location.state })
  }

  const clearFilters = () => {
    setFilters(initFilters)
    submitSearch(initFilters)
  }

  const onChange = (parentOnChange: (name: string, value: any) => void = identity) => (name: string, value: any) => {
    setFilters({ ...filters, [name]: value })
    parentOnChange(name, value)
    submitSearch({ ...filters, [name]: value })
  }

  if (!children) { return <></> }
  return (<form className={classes.filterContainer}>
    <Grid container>
      {React.Children.map(children, (child: ReactElement<FilterProps>) =>
        <Grid item className={classes.filter}>
          {React.cloneElement<FilterProps>(child, {
            filters: filters,
            onChange: onChange(child.props.onChange)
          })}
        </Grid>
      )}
    </Grid>

    <Fab
      color="secondary"
      className={classes.filterButtonClear}
      onClick={clearFilters}
    >
      <ClearIcon />
    </Fab>
  </form >)
}

export const FilterAutocompleteField = <T extends {}>(props: Omit<AutocompleteAsyncTextFieldProps<T>, 'onSelect'> & FilterProps) => {
  return <AutocompleteAsyncTextField<T>
    {...props}
    search={props.search}
    inputValue={props.value || (props.filters && props.filters[props.name]) || ''}
    onSelect={selected => { props.name && props.onChange && props.onChange(props.name, selected ? selected![props.name] : '' ) }}
  />
}

export const FilterTextField: React.FC<Omit<TextFieldProps, keyof FilterProps> & FilterProps> = (props) => {
  const classes = useStyles()

  return (<TextField
    {...props}
    className={classes.filterInput}
    margin='none'
    variant="standard"
    value={props.value || (props.filters && props.filters[props.name]) || ''}
    onChange={event => { props.onChange && props.onChange(props.name, event.target.value) }}
  />)
}

export const FilterSelector: React.FC<SelectorProps & FilterProps> = (props) => {
  const classes = useStyles()

  return (<Selector
    {...props}
    margin="none"
    className={classes.formControl}
    value={props.value || (props.filters && props.filters[props.name]) || [] } 
    onChange={event => { props.onChange && props.onChange(props.name, event.target.value) }}
  />)
}

type TimeCorrector = { timeCorrector: (date: Moment) => Moment }  //TODO find better name :D

export const FilterDate: React.FC<Omit<KeyboardDatePickerProps, keyof FilterProps> & FilterProps & TimeCorrector> = ({ timeCorrector, ...props }) => {
  const classes = useStyles()

  return (<KeyboardDatePicker
    {...props}
    className={classes.filterDate}
    variant="inline"
    format="DD/MM"
    margin="none"
    value={props.value || (props.filters && props.filters[props.name]) || null}
    onChange={date => { if (date) { props.onChange && props.onChange(props.name, timeCorrector(date).format()) } }}
    KeyboardButtonProps={{
      'aria-label': 'change date',
    }}
  />)
}


export const FilterTime: React.FC<Omit<KeyboardTimePickerProps, keyof FilterProps> & FilterProps> = (props) => {
  const classes = useStyles()

  return (<KeyboardTimePicker
    {...props}
    className={classes.filterDate}
    variant="inline"
    margin="none"
    ampm={false}
    value={props.value || (props.filters && props.filters[props.name] && moment(props.filters[props.name], 'HH:mm')) || null}
    onChange={time => { if (time) { props.onChange && props.onChange(props.name, time.format('HH:mm')) } }}
    KeyboardButtonProps={{ 'aria-label': 'Hora de inicio' }}
    keyboardIcon={<AccessTime />}
  />)
}

export const WeekFilterButton: React.FC<WeekFilterButton> = (props) => {

  const history = useHistory()

  const changeDateInterval = (from: Moment) => {
    const startOfWeek = moment(from).startOf('week')
    const endOfWeek = moment(from).endOf('week')
    const newFilters: Filters = { ...searchToFilters(history.location.search), from: startOfWeek.format(), to: endOfWeek.format() }
    history.push({ search: filtersToSearch(newFilters), state: history.location.state })
  }

  return <StyledButton color="primary" variant="contained" mt={1.5} mr={0.5} onClick={() => changeDateInterval(props.from)}>{props.text}</StyledButton>
}

export const searchToFilters = (search: string, defaultFilters: Filters = {}) => {
  const filters = { ...defaultFilters }
  new URLSearchParams(search).forEach((value, key) => {
    filters[key] = value
  })
  return filters
}

export const filtersToSearch = (filters: Filters): string => paramsToQueryString(filters)

// Returns the date in from filter. If the filter does not exists,
// it returns the current date
export const currentFromFilter = (): Moment => {
  return moment(searchToFilters(window.location.search)['from'])
}
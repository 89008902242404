import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  success: {
    color: "green"
  },
  error: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.dark,
  },
}))

interface ShowLabelProps {
  show: boolean | "" | undefined,
  message: string,
}

const ErrorLabel: React.FC<ShowLabelProps> = ({show, message}) => {
  const classes = useStyles();

  return (
    <div>
      {show &&
        <Typography align="center" variant="subtitle2" className={classes.error}>
          {message}
        </Typography>
      }
    </div>
  )
}

const SuccessLabel: React.FC<ShowLabelProps> = ({show, message}) => {
  const classes = useStyles();

  return (
    <div>
      {show &&
        <Typography align="center" variant="subtitle2" className={classes.success}>
          {message}
        </Typography>
      }
    </div>
  )
}

export { SuccessLabel, ErrorLabel }
export interface AlertState {
  message?: string
  isError?: boolean
}

type AlertActionType = 'NEW_MESSAGE' | 'HIDE'
export interface AlertAction {
  type: AlertActionType
  message?: string
  isError?: boolean
}


export default (
  state: AlertState = {},
  action: AlertAction
) => {
  switch (action.type) {
    case 'NEW_MESSAGE':
      return { message: action.message, isError: action.isError }
    case 'HIDE':
      return {}
  }
  return state
}
import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import { SchoolJabberUserResult } from '../../models/SchoolJabberUser';


export interface SchoolJabberUserBulkUpsertState extends ApiState {
  results?: SchoolJabberUserResult[]
}

export default requestReducer<SchoolJabberUserBulkUpsertState>('SCHOOL_JABBER_USER_BULK_UPSERT')

import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import { AssignationResult } from '../../models/Assignation';


export interface AssignationBulkUpsertState extends ApiState {
  results?: AssignationResult[]
}

export default requestReducer<AssignationBulkUpsertState>('ASSIGNATION_BULK_UPSERT')

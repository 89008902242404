import NonSchoolDay, { NonSchoolDayInput } from '../../models/NonSchoolDay';
import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';

export interface ImportantDayResult{
  record: NonSchoolDay | NonSchoolDayInput
  success: boolean
  action: "create" | "error"
  error?: string
}

export interface NonSchoolDayBulkUpsertState extends ApiState {
  results?: ImportantDayResult[]
}

export default requestReducer<NonSchoolDayBulkUpsertState>('NON_SCHOOL_DAY_BULK_UPSERT')

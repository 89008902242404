import React from 'react'
import { Typography } from '@material-ui/core'
import { replaceSemicolon, toUpperCase } from '../../utils/StringUtils'
import ClassroomTeacher from '../../models/ClassroomTeacher'

interface AssignationSummaryProp {
  department: string
  schoolNumber: string
  grade: string
  classroomTeacher?: ClassroomTeacher
}
const AssignationSummary: React.FC<AssignationSummaryProp> = ({ department, schoolNumber, grade, classroomTeacher }) => {

  return (<>
    <Typography>Departamento <b>{department}</b> Escuela N° <b>{schoolNumber}</b></Typography>
    <Typography>Grupo: <b>{grade}</b> Docente de Aula: <b>{classroomTeacher && replaceSemicolon(',', toUpperCase(classroomTeacher.fullName))}</b></Typography>
  </>)
}

export default AssignationSummary
import React from 'react'
import { Redirect } from 'react-router-dom'

export interface WrapperProps {
  show: boolean
  redirect?: boolean
}

const Wrapper: React.FC<WrapperProps> = ({ children, show, redirect = false }) => {
  if (!show) {
    if (redirect) {
      return <Redirect to='/login' /> //TODO: Redirect by role?
    } else {
      return <></>
    }
  }
  return <>{children}</>
}

export default Wrapper



import { CircularProgress, Paper, Table as MaterialTable, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TableProps as MaterialTableProps } from '@material-ui/core/Table'
import React from 'react'
import { generate as generateKey } from 'shortid'
import PaginationButtons from './PaginationButtons';
import Pagination from '../models/Pagination'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: '#F4F6F8',
  },
  paper: {
    marginBottom: 64 + theme.spacing(4)
  },
  progress: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    textAlign: 'center'
  }
}))

export type TableCell = { value: any, visible?: boolean }
export type TableRow<T> = { cells: TableCell[], hover?: boolean, selected?: boolean, visible?: boolean, model?: T }

interface TableProps<T> extends MaterialTableProps {
  bodyrows: TableRow<T>[]
  headerrow: TableRow<T>
  progress?: { loading?: boolean, size?: number }
  pagination?: Pagination
  onChangePageHandler?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void
  onRowClick?: (model: T) => void
}

const createTableCell = ({ value, visible = true }: TableCell, index: number) =>
  <TableCell key={index}>
    <span>{visible ? value : ""}</span>
  </TableCell>

/**
 * Be careful using this function with map. Do not use it like "rows.map(createTableRow)" because that will use the items indexes in the list
 * as row keys, which will cause unholy problems with the data of the rows, as explained in https://robinpokorny.com/blog/index-as-a-key-is-an-anti-pattern/.
 * When using it with map, make sure to be using it like "rows.map( (row) => createTableRow(row) )".
 */
 function createTableRow<T>(tableRowProps: TableRow<T>, onRowClick?: (model: T) => void, rowId?: number): JSX.Element {
  const { cells, hover = true, selected = false, visible = true, model } = tableRowProps;
 
  return visible ?
    <TableRow hover={hover} key={rowId || generateKey()} selected={selected} onClick={() => model && onRowClick && onRowClick(model)} style={{ cursor: model && onRowClick ? 'pointer' : '' }}>
      {cells.map(createTableCell)}
    </TableRow> :
    <span />
};

function Table<T>(props: TableProps<T>) {
  const classes = useStyles();

  return (
    props.progress?.loading ?
      <div className={classes.progress}><CircularProgress size={props.progress?.size || 100} /></div> :
      <Paper square className={classes.paper}>
        <MaterialTable className={classes.table} size="small" {...props}>
          <TableHead className={classes.head}>
            {createTableRow(props.headerrow)}
          </TableHead>
          <TableBody>
            {props.bodyrows.map((tableRow) => createTableRow(tableRow, (row) => props.onRowClick && props.onRowClick(row)))}
          </TableBody>
        </MaterialTable>
        <PaginationButtons pagination={props.pagination} />
      </Paper >
  )
}

export default Table
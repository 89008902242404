import { requestReducerForTypes } from '../requestReducer';
import { ApiState } from '../../utils/requestAction';
import User, { ADMIN, REFERENT, Role } from '../../models/User';

export const hasRole = ({ roles }: SessionState, role: Role): boolean => !!roles && roles.includes(role)
export const isAdmin = (session: SessionState): boolean => hasRole(session, ADMIN)
export const isReferent = (session: SessionState): boolean => hasRole(session, REFERENT)
export const isLogged = ({ email }: SessionState): boolean => !!email
export const isLoggedIn = ({ email }: SessionState, user?: User) => user && user.email === email

export interface SessionState extends ApiState {
  fullName: string | null
  email: string | null
  roles: Role[] | null
  shouldUpdatePassword: boolean
}

const empty = {
  fullName: null,
  email: null,
  roles: null,
}

export default requestReducerForTypes<SessionState>(['SESSION', 'SESSION_CLOSED'],
  (state, action) => action.requestStatus === 'FETCH' ? state : { ...state, ...empty })
import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { SchoolJabberUserInput } from '../../models/SchoolJabberUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  user: SchoolJabberUserInput;
  onDismiss: () => void;
  onSubmit: (user: SchoolJabberUserInput) => void;
}

const SchoolJabberUserEditDialog: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const [user, setUser] = useState<SchoolJabberUserInput>(props.user);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar usuario jabber</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="RUEE"
          margin="normal" variant="filled" required fullWidth
          defaultValue={user.rue}
          onChange={e => setUser({ ...user, rue: e.target.value })}
        />
        <TextField
          label="URI"
          margin="normal" variant="filled" required fullWidth
          defaultValue={user.uri1}
          onChange={e => setUser({ ...user, uri1: e.target.value })}
        />
        <TextField
          label="URI 2"
          margin="normal" variant="filled" required fullWidth
          defaultValue={user.uri2}
          onChange={e => setUser({ ...user, uri2: e.target.value })}
        />
        <TextField
          label="Interno"
          margin="normal" variant="filled" required fullWidth
          defaultValue={user.intern}
          onChange={e => setUser({ ...user, intern: e.target.value })}
        />
        <TextField
          label="Incidentes técnicos"
          margin="normal" variant="filled" required fullWidth
          defaultValue={user.comments}
          onChange={e => setUser({ ...user, comments: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(user)}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SchoolJabberUserEditDialog
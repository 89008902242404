import React from 'react';
import { useHistory } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NonSchoolDayList from '../../RemoteTeacher/NonSchoolDayList/index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
);

const AdminNonSchoolDayList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <NonSchoolDayList />
      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/non-school-days/create', { title: 'Crear fechas importantes' })}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default AdminNonSchoolDayList
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ButtonItem from './ButtonItem'

export interface NavItemProps {
  uri: string
  title: string
  icon: string
  base?: string
  nested?: boolean
  notification?: React.ReactNode
}

const NavItem: React.FC<NavItemProps> = (props) => {
  const { uri, title, base } = props
  const history = useHistory()
  const location = useLocation()
  const path = `/${base}/${uri}`
  
  return (
    <ButtonItem
      selected={location.pathname === path}
      onClick={() => history.push(path, { title })}
      {...props} />
  )
}

export default NavItem
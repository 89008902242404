import React from 'react';
import moment from 'moment';
import { AssignationInput } from '../../models/Assignation';
import Pagination from '../../models/Pagination';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import ColoredTableRow from '../ColoredTableRow';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 5
    },
    paper: {
      marginBottom: 64 + theme.spacing(4)
    },
    progress: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
    statusCell: {
      textAlign: 'center'
    }
  }),
);

export interface Row {
  data: AssignationInput;
  action?: 'create' | 'update' | 'error';
}

interface Props {
  rows: Row[];
  loading: boolean;
  pagination: Pagination;
  onChangePage: (page: number) => void;
  onClickEdit: (row: Row, index: number) => void;
}

const AssignationInputListTable: React.FC<Props> = (props) => {  
  const classes = useStyles();

  const mapActionToColor = (action?: string) => {
    switch (action) {
      case 'create': return 'green';
      case 'update': return 'yellow';
      case 'error': return 'red';
      default: return 'default';
    }
  }

  const getDayOfWeek = (data: AssignationInput): string => {
    return moment(data.dayOfWeek, 'e').format('dddd');
  }

  return (
    <Paper square className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow selected={true}>
            <TableCell>RUEE</TableCell>
            <TableCell>Grado</TableCell>
            <TableCell>Día</TableCell>
            <TableCell>Horario</TableCell>
            <TableCell>Comienzo</TableCell>
            <TableCell>DR DNI</TableCell>
            <TableCell>DA DNI</TableCell>
            <TableCell>DA Nombre</TableCell>
            <TableCell>DA Apellido</TableCell>
            <TableCell>DA Email</TableCell>
            <TableCell>DA Teléfono (sin +598)</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) =>
            <ColoredTableRow key={index} color={mapActionToColor(row.action)}>
              <TableCell>{row.data.school.rue}</TableCell>
              <TableCell>{row.data.grade}</TableCell>
              <TableCell>{getDayOfWeek(row.data)}</TableCell>
              <TableCell>{`${row.data.sinceTime} - ${row.data.untilTime}`}</TableCell>
              <TableCell>{moment(row.data.firstClassDate).format('DD/MM')}</TableCell>
              <TableCell>{row.data.remoteTeacher.documentNumber}</TableCell>
              <TableCell>{row.data.classroomTeacher.documentNumber}</TableCell>
              <TableCell>{row.data.classroomTeacher.firstName}</TableCell>
              <TableCell>{row.data.classroomTeacher.lastName}</TableCell>
              <TableCell>{row.data.classroomTeacher.email}</TableCell>
              <TableCell>{row.data.classroomTeacher.phoneNumber}</TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  className={classes.iconButton}
                  onClick={() => props.onClickEdit(row, index)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </ColoredTableRow>
          )}
        </TableBody>
      </Table>
      { props.loading &&
        <div className={classes.progress}>
          <CircularProgress size={100} />
        </div>
      }
      { props.pagination &&
        <TablePagination
          component="div"
          page={props.pagination.page}
          count={props.pagination.count}
          rowsPerPage={props.pagination.size}
          rowsPerPageOptions={[props.pagination.size]}
          onChangePage={(e, page) => props.onChangePage && props.onChangePage(page < 0 ? 0 : page)}
          labelDisplayedRows={info => `${info.from}-${info.to} de ${info.count}`}
        />
      }
    </Paper>
  );
}

export default AssignationInputListTable
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { connectApp, AppProp } from '../../reducers'
import { isLogged } from '../../reducers/auth/session'
import { fetchSession } from '../../actions/auth/session'
import { getToken } from '../../utils/fetchApi'
import Wrapper from '../components/Wrapper/Wrapper'

const AuthWrapper: React.FC<AppProp<'session'>> = ({ session, ...rest }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => { // Always fetch the session if it doesnt exist
    if (getToken() && !isLogged(session))
      dispatch(fetchSession(history, location))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return <Wrapper show={isLogged(session)} redirect={!getToken()} {...rest} />
}

export default connectApp('session')(AuthWrapper)



import requestAction from '../utils/requestAction'
import { AvailabilitySlot } from '../models/AvailabilitySlot'
import { pushAlert } from './alert'

const makeRequest = requestAction('AVAILABILITYSLOTS')

export const fetchAvailabilitySlots =
  makeRequest('availability-slots', 'GET')

export const updateAvailabilitySlots = (availabilitySlots: AvailabilitySlot[]) =>
  makeRequest('availability-slots', 'PUT', { availabilitySlots }, (dispatch) => {
    dispatch(pushAlert('Listo, ya tenemos tu disponibilidad'))
  })

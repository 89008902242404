import { Icon, Box, Link, Button, Paper, Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import { DateRange, MoreHoriz, CheckCircle, Cancel } from '@material-ui/icons'
import { default as DialogActions, default as DialogContent } from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { FC, useEffect } from "react"
import { useDispatch } from 'react-redux'
import Class, { kindsToDescription, statusToDescription } from '../../../models/Class'
import Assignation from "../../../models/Assignation"
import School from "../../../models/School"
import useStyles from "./styles"
import AssignationSummary from "../AssignationSummary";
import { DialogBasicProps } from "../Utils/Dialogs/AnahiDialogIconButton";
import { AppProp, connectApp } from "../../../reducers"
import { fetchHistoryGroupClasses } from '../../../actions/class/historyGroupClassList'
import moment from 'moment'
import { Filters, filtersToSearch } from '../../../views/components/Utils/Filters/Filter'
import { ActivityState, activityStateToDescription, reasonToDescription, Phase, phaseToDescription } from "../../../models/Class"
import { useLocation } from "react-router-dom"

interface ViewInfoClassProps {
  iClass: Class
}

interface TextInfoProps {
  title: string
  text: string | undefined
  lf?: boolean
}

const ViewInfoClass: FC<ViewInfoClassProps> = ({ iClass }: ViewInfoClassProps ) => {

  const BoxTextInfo: FC<TextInfoProps> = (props: TextInfoProps ) =>
    <Box style={{paddingTop:"8px"}}>
      <TextInfo {...props}/>
    </Box>

  const TextInfo:  FC<TextInfoProps> = ({ title, text, lf = false }: TextInfoProps ) =>
    <Typography component={'span'}>
      <Box fontWeight='fontWeightMedium' display={lf ? 'block' : 'inline'}>
        {title}
      </Box>
      {!lf ? text: <Typography variant="body2">{text}</Typography>}
    </Typography>

  const PendingIcon = () => <Icon style={{borderRadius:"12px", backgroundColor:"yellow"}}><MoreHoriz/></Icon> 
  const DictatedIcon = () => <Icon style={{color:"green"}}><CheckCircle/></Icon> 
  const CancelledIcon = () => <Icon style={{color:"red"}}><Cancel/></Icon> 
  
  return (
    <div>
      <Paper elevation={12} style={{margin: "8px", padding:"8px"}}>
        <Box display="flex" flexDirection="row" flexWrap='wrap'>
          <Typography component={'span'}>{iClass.sinceDateMoment().format('dddd DD/MM HH:mm') + " hs "}|&nbsp;</Typography>
          {iClass.kind!=='O' ? <Typography style={{display: 'flex', justifyContent:'center'}}><DateRange/>{`${iClass.kind}`}&nbsp;|</Typography> 
                             : <Typography>{kindsToDescription(iClass.kind)}&nbsp;|</Typography>}
          <Typography component={'span'} style={{display: 'flex', justifyContent:'center'}}>                 
                &nbsp;{iClass.isPending() ? <PendingIcon/> : iClass.isDictated() ? <DictatedIcon/> : <CancelledIcon/>}
                &nbsp;{`${statusToDescription(iClass.status)}`}&nbsp;
                {iClass.platform?.description ? <Typography>a través de {iClass.platform.description}</Typography>: <></>}
                &nbsp;|&nbsp;
          </Typography>
          <TextInfo title="DR: " text={iClass.teacher?.fullName}/>          
        </Box>

        <Box display="flex" style={{paddingTop:"8px", alignItems: 'flex-end', justifyContent: 'space-between'}}>
          {iClass.screenshot ? 
                <Link href={iClass.screenshot} target='_blank' underline='always' variant="body1">Link captura</Link>: <></>}
          {iClass.proposal ? 
                <BoxTextInfo title='' text={iClass.proposal.description}/>: <></>}
          {iClass.phase ? 
                <BoxTextInfo title="Etapa: " text={phaseToDescription(iClass.phase as Phase)}/> : <></>}
          {iClass.activityWasConcluded ? 
                <BoxTextInfo title="Actividad hecha: " text={activityStateToDescription(iClass.activityWasConcluded as ActivityState)}/> : <></>}
        </Box>

        {iClass.isCancelled() && iClass.cancellationReason ? 
              <BoxTextInfo title="Motivo: " text={reasonToDescription(iClass.cancellationReason)}/> : <></>}
        {iClass.hadIssuesDuringTheCall ? 
              <BoxTextInfo title="" text="Hubo problemas técnicos"/> : <></>}
        {iClass.technicalNotes ? 
              <BoxTextInfo title="Notas técnicas: " text={iClass.technicalNotes} lf/> : <></>}
        {iClass.pedagogicalNotes ? 
              <BoxTextInfo title="Notas pedagógicas: " text={iClass.pedagogicalNotes} lf/> : <></>}
        {iClass.agreements ? 
              <BoxTextInfo title="Acuerdos con DA: " text={iClass.agreements} lf/> : <></>}
        {iClass.complementaryNotes ? 
              <BoxTextInfo title="Notas complementarias: " text={iClass.complementaryNotes} lf/> : <></>}
      </Paper>
    </div>
  )
}

export type HistoryGroupClassReportProps = DialogBasicProps & { assignation: Assignation } & AppProp<'historyGroupClassList'>

const HistoryGroupClassReportDialog: FC<HistoryGroupClassReportProps> = ({ onClose, open, assignation, historyGroupClassList }) => {
  const stylesClassList = useStyles()
  const dispatch = useDispatch()
  const prefix = (useLocation().pathname.includes('referent/') ? 'references/' : '' )
  
  const school: School = assignation.group.school

  const params = filtersToSearch({   
    assignationId: `${assignation.id}`,
    from: `${moment().startOf('year').startOf('day')}`,
    to: `${moment().endOf('week').endOf('day')}`,
  } as Filters)

  useEffect(() => {
    if( open )
      dispatch(fetchHistoryGroupClasses(`?${params}`, prefix));
  }, [dispatch, params, open, prefix]);

  
  const CloseButton = () =>
      <Button
        className={stylesClassList.button}
        variant="contained"
        color="primary"
        size="small"
        onClick={(e)=>{ e.stopPropagation(); onClose()}}>
        Cerrar
      </Button>

  return (
    ( historyGroupClassList.classes ? 

    <Dialog fullWidth scroll="body" maxWidth="md" open={open} onClose={onClose}>

      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Historial de Clases del Grupo {assignation.group.grade}</Typography>
          <CloseButton/>
        </Box>
      </DialogTitle>

      <DialogContent dividers>

      <Paper elevation={0} style={{padding:"0px 0px 8px 8px"}}>
        <AssignationSummary
          department={school.department}
          grade={assignation.group.grade}
          schoolNumber={school.number}
          classroomTeacher={assignation.group.teacher}
        /> 
      </Paper>
        {open && historyGroupClassList.classes && 
          historyGroupClassList.classes.slice(0).reverse().map((selectedClass, index) => (  
            <ViewInfoClass key={index} iClass={new Class(selectedClass)}/>))
        }
      </DialogContent>

      <DialogActions>
        <CloseButton/>
      </DialogActions>

    </Dialog>
    : <></> ))
}

export default connectApp('historyGroupClassList')(HistoryGroupClassReportDialog)

import React from 'react'
import { useLocation } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const Header: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" noWrap className={classes.title}>
          {location.state ? (location.state as any).title : ''}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Header

import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchJabberUsers } from '../../../actions/schoolJabberUser/schoolJabberUserList'
import SchoolJabberUserListTable from '../../../components/SchoolJabberUser/SchoolJabberUserListTable'
import { AppState } from '../../../reducers'
import { SchoolJabberUserListState } from '../../../reducers/schoolJabberUser/schoolJabberUserList'
import { FiltersBar } from '../../components/Utils/Filters/Filter'
import { FilterRue } from '../../components/Utils/Filters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
)

interface Props {
  schoolJabberUserList: SchoolJabberUserListState
}

const SchoolJabberUserList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchJabberUsers(history.location.search))
  }, [dispatch, history.location.search])

  return (
    <div>
      <FiltersBar>
        <FilterRue />
      </FiltersBar>

      <SchoolJabberUserListTable
        rows={(props.schoolJabberUserList.users || []).map(s => ({ data: s }))}
        loading={props.schoolJabberUserList.isFetching}
        pagination={props.schoolJabberUserList.pagination}
      />

      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/school-jabber-users/create', { title: 'Crear usuarios jabber' })}>
        <AddIcon />
      </Fab>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  schoolJabberUserList: state.schoolJabberUserList
})

export default connect(mapStateToProps, null)(SchoolJabberUserList)
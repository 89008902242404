import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { ErrorLabel } from './ShowLabel'

interface NewPasswordProps {
  onValidPassword: (newPassword: string | null) => void
}

const NewPassword: React.FC<NewPasswordProps> = ({ onValidPassword }) => {
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined)
  const samePassword = password === confirmPassword

  const change = cb => event => {
    const newValue = event.target.value
    const correctPassword = newValue === password || newValue === confirmPassword
    cb(newValue)
    onValidPassword(correctPassword ? newValue : null)
  }

  return (<>
    <TextField
      label="Nueva contraseña"
      margin="normal"
      variant="outlined"
      type="password"
      onChange={change(value => setPassword(value))}
      fullWidth
      required
    />
    <TextField
      label="Confirmar nueva contraseña"
      margin="normal"
      variant="outlined"
      type="password"
      onChange={change(value => setConfirmPassword(value))}
      fullWidth
      required
    />
    <ErrorLabel
      show={password && confirmPassword && !samePassword}
      message="La nueva contraseña no conicide."
    />
  </>)
}
export default NewPassword
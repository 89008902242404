import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import NonSchoolDay from '../../models/NonSchoolDay';


export interface NonSchoolDayListState extends ApiState {
  days?: NonSchoolDay[]
}

export default requestReducer<NonSchoolDayListState>('NON_SCHOOL_DAY_LIST')

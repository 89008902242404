import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { SchoolContactInput } from '../../models/SchoolContact';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  contact: SchoolContactInput;
  onDismiss: () => void;
  onSubmit: (contact: SchoolContactInput) => void;
}

const SchoolContactEditDialog: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const [contact, setContact] = useState<SchoolContactInput>(props.contact);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar contacto escolar</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="RUEE"
          margin="normal" variant="filled" required fullWidth
          defaultValue={contact.rue}
          onChange={e => setContact({ ...contact, rue: e.target.value })}
        />
        <TextField
          label="Nombre"
          margin="normal" variant="filled" required fullWidth
          defaultValue={contact.fullName}
          onChange={e => setContact({ ...contact, fullName: e.target.value })}
        />
        <TextField
          label="Cargo"
          margin="normal" variant="filled" required fullWidth
          defaultValue={contact.position}
          onChange={e => setContact({ ...contact, position: e.target.value })}
        />
        <TextField
          label="Teléfono (sin +598)"
          margin="normal" variant="filled" required fullWidth
          defaultValue={contact.phoneNumber}
          onChange={e => setContact({ ...contact, phoneNumber: e.target.value })}
        />
        <TextField
          label="Email"
          margin="normal" variant="filled" required fullWidth
          defaultValue={contact.email}
          onChange={e => setContact({ ...contact, email: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(contact)}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SchoolContactEditDialog
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { NonSchoolDayListState } from '../../../reducers/nonSchoolDay/nonSchoolDayList';
import { fetchDays } from '../../../actions/nonSchoolDay/nonSchoolDayList';
import NonSchoolDayListTable from '../../../components/NonSchoolDay/NonSchoolDayListTable';


interface Props {
  dayList?: NonSchoolDayListState;
}

const NonSchoolDayList: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDays());
  }, [dispatch]);

  return (
    <NonSchoolDayListTable
      rows={(props.dayList!.days || []).map(s => ({ data: s }))}
      loading={props.dayList!.isFetching}
    />
  );
}

const mapStateToProps = (state: AppState) => ({
  dayList: state.nonSchoolDayList
})

export default connect(mapStateToProps, null)(NonSchoolDayList)
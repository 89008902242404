import React, { FC, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import TooltipIconButton from '../../../../components/TooltipIconButton'

export interface DialogBasicProps {
  open: boolean,
  onClose: () => void,
}

type AnahiDialogIconButton<DialogSpecificProps> = {
  id: string,
  tooltip: string,
  ButtonIcon: FC,
  disabled: boolean,
  TheDialog: FC<DialogSpecificProps & DialogBasicProps>,
  dialogProps: DialogSpecificProps
}

export const AnahiDialogIconButton: <DialogSpecificProps>(props: AnahiDialogIconButton<DialogSpecificProps>) => JSX.Element =
  ({ id, tooltip, ButtonIcon, disabled, TheDialog, dialogProps }) => {
    const [open, setOpen] = useState<boolean>(false)

    return <div>
      <Tooltip id={id} title={tooltip}>
        <span>
          <TooltipIconButton onClick={(e) => { e.stopPropagation(); setOpen(true)}} disabled={disabled} >
            <ButtonIcon />
          </TooltipIconButton >
        </span>
      </Tooltip>

      <TheDialog {...dialogProps} open={open} onClose={() => setOpen(false)} />
    </div >
  }

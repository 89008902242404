
import requestAction, { alertError, alertMessage } from "../../utils/requestAction"
import { juxt } from 'ramda'
import Assignation from "../../models/Assignation"
import { fetchAssignations } from './assignationList';

export default (assignation: Assignation, history) =>
  requestAction('ASSIGNATION_DELETE')(`assignations/${assignation.id}`, 'DELETE', null,
    juxt([fetchAssignations(history.location.search), alertMessage("Asignacion eliminada")]),
    alertError("No se puede eliminar esta asignacion")
  )
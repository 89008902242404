import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row'
    },
    filter: {
      display: 'flex',
      alignItems: 'flex-end',
      // these two are for aligning bottom the only item inside each filter div
      marginRight: theme.spacing(2),
    },
    filterInput: {
      minWidth: 80,
      flexGrow: 1
    },
    filterDate: {
      maxWidth: theme.spacing(15),
      flexGrow: 2
    },
    filterButtonClear: {
      minWidth: 56,
      marginRight: theme.spacing(2)
    },
    filterButtonSearch: {
      minWidth: 56
    },
    formControl: {
      minWidth: 120,
      flexGrow: 1
    },
  })
)
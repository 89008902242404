import { Checkbox } from '@material-ui/core'
import React from 'react'

interface Props {
  onChange: (newChecked: boolean) => void,
  defaultChecked: boolean
}

export const SentToCeibal = (props: Props) => {
  const { onChange, defaultChecked } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sentToCeibal: boolean = event.target.checked
    onChange(sentToCeibal)
  }

  return <Checkbox
    checked={defaultChecked}
    onChange={handleChange}
    onClick={(e) => e.stopPropagation()}
    inputProps={{ 'aria-label': 'En SVC' }}
  />
}
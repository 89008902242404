import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Paper, Container, Typography, TextField, Checkbox, FormControlLabel, FormHelperText, InputProps, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fetchConfig, updateConfig } from '../../../actions/config';
import { Configuration } from '../../../models/Configuration';
import { ConfigState } from '../../../reducers/config';
import { AppState } from '../../../reducers/index';
import ProgressButton from '../../components/Utils/ProgressButton';
import useStyles from './styles';
import moment from 'moment';

interface Props {
  config: ConfigState;
}

const ConfigurationView: React.FC<Props> = ({ config }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [changed, setChanged] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<Configuration>(config.config || {} );
  const moneyInputProps = { step: "0.01", currencyPrefix: <InputAdornment position="start">$</InputAdornment>}

  const change = (attr: string) => (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setChanged(true)
    const newConfiguration = { ...configuration }
    newConfiguration[attr] = event.target.valueAsNumber
    setConfiguration(newConfiguration)
  }

  const changeAvailabilityUpdEnabled = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true)
    setConfiguration({ ...configuration, availabilityUpdateEnabled: event.target.checked })
  }

  const changeLastClassDate = (date: MaterialUiPickersDate) => {
    setChanged(true)
    setConfiguration({ ...configuration, lastClassDate: date?.toDate() })
  }

  const dispatch = useDispatch();
  const changeEntity = (event: FormEvent) => {
    event.preventDefault()
    dispatch(updateConfig(configuration))
    setChanged(false)
  }

  useEffect(() => {
    dispatch(fetchConfig);
    setLoading(true)
  }, [dispatch]);


  useEffect(() => {
    if (!config.isFetching && config.config)
      {
      setConfiguration(config.config)
      setLoading(false)
      }
  }, [config.isFetching, config.config])

  return (
    <Container>
      <Paper square className={classes.paper}>
        {!loading &&
        <form onSubmit={changeEntity}>
          <Typography variant="h6">
            Disponibilidad de Docentes Remotos
          </Typography>
          <ChangeEntityField
            label="Máximo de slots por docentes"
            attr="maxLegalSlots"
            change={change}
            entity={configuration}
          />
          <ChangeEntityField
            label="Mínimo de slots por docentes"
            attr="minLegalSlots"
            change={change}
            entity={configuration}
          />
          <KeyboardDatePicker
            label="Fecha de última clase"
            margin="normal"
            format="DD/MM"
            value={configuration.lastClassDate || null}
            onChange={changeLastClassDate}
            disableToolbar
            fullWidth
            required
            KeyboardButtonProps={{ 'aria-label': 'Fecha de última clase' }}
          />
          <FormControlLabel
            label="Habilitar actualización de disponibilidad Horaria"
            control={<Checkbox
              checked={configuration.availabilityUpdateEnabled}
              onChange={changeAvailabilityUpdEnabled()}
            />
            }
          />
          <FormHelperText>Última habilitación: {moment(configuration.availabilityUpdateEnabledDate).format("LLLL")}</FormHelperText>
          <Typography variant="h6" className={classes.subtitle}>
            Montos a pagar
          </Typography>

          <ChangeEntityField
            label="Monto de una clase"
            attr="classWorthAmount"
            change={change}
            entity={configuration}
            inputProps={moneyInputProps}
          />
          <ChangeEntityField
            label="Monto fijo mensual"
            attr="fixedSettlementAmount"
            change={change}
            entity={configuration}
            inputProps={moneyInputProps}
          />

          <ProgressButton state={config} disabled={!changed}>
            Guardar
          </ProgressButton>
        </form>
        }
      </Paper>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  config: state.config
})

export default connect<Props, {}, {}, AppState>(mapStateToProps, {})(ConfigurationView)

interface ChangeEntityFieldProps {
  label: string,
  attr: string,
  entity: Configuration,
  change: (attr: string) => any,
  inputProps?: InputProps['inputProps']
}

const ChangeEntityField: React.FC<ChangeEntityFieldProps> = ({ label, attr, entity, change , inputProps = {}}) => (
  <TextField
    label={label}
    margin="normal"
    type="number"
    inputProps={{step: inputProps.step}}
    InputProps={{startAdornment: inputProps.currencyPrefix}}
    defaultValue={entity[attr]}
    onChange={change(attr)}
    fullWidth
    required
  />
)

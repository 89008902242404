import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchAssignations } from '../../../actions/assignation/assignationList'
import { RT } from '../../../models/User'
import AssignationList from '../../components/AssignationList'

const TeacherAssignationList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAssignations(history.location.search))
  }, [dispatch, history.location.search])

  return <AssignationList
    enabledFilters={['department', 'schoolNumber', 'status', 'weekday']}
    role={RT}
  />
}

export default TeacherAssignationList
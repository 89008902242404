import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchContacts } from '../../../actions/schoolContact/schoolContactList'
import SchoolContactListTable from '../../../components/SchoolContact/SchoolContactListTable'
import { AppState } from '../../../reducers'
import { SchoolContactListState } from '../../../reducers/schoolContact/schoolContactList'
import { FiltersBar } from '../../components/Utils/Filters/Filter'
import { FilterRue } from '../../components/Utils/Filters'
import { FilterFullName, FilterPosition } from '../../components/Utils/Filters/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
)

interface Props {
  schoolContactList: SchoolContactListState
}

const SchoolContactList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchContacts(history.location.search))
  }, [dispatch, history.location.search])

  return (
    <div>
      <FiltersBar >
        <FilterRue />
        <FilterFullName />
        <FilterPosition />
      </FiltersBar>

      <SchoolContactListTable
        rows={(props.schoolContactList.contacts || []).map(s => ({ data: s }))}
        loading={props.schoolContactList.isFetching}
        pagination={props.schoolContactList.pagination || undefined}
      />

      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/school-contacts/create', { title: 'Crear contactos escolares' })}>
        <AddIcon />
      </Fab>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  schoolContactList: state.schoolContactList
})

export default connect(mapStateToProps, null)(SchoolContactList)
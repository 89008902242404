import { SettlementRecord } from "../../../models/SettlementRecord";
import * as React from 'react'
import Table from '../../../components/Table'
import moment from "moment";
import Pagination from "../../../models/Pagination";

interface SettlementRecordsTableProps {
  records?: SettlementRecord[]
  pagination?: Pagination
  loading?: boolean
}

export default (props: SettlementRecordsTableProps) =>
  <Table
    headerrow={{
      hover: true,
      cells: [
        { value: 'Fecha' },
        { value: 'Periodo' },
        { value: 'DNI' },
        { value: 'Apellido/s' },
        { value: 'Nombre/s' },
        { value: 'Mail' },
        { value: 'Tipo' },
        { value: 'Descripcion' }
      ]
    }}
    bodyrows={props.records!
      .sort((firstRecord, secondRecord) => firstRecord.date > secondRecord.date ? -1 : 1)
      .map(record => ({
        cells: [
          { value: dateToLocalFormat(record.date) },
          { value: `${dateToLocalFormat(record.from)} -> ${dateToLocalFormat(record.to)}` },
          { value: record.teacher.documentNumber },
          { value: record.teacher.lastName },
          { value: record.teacher.firstName },
          { value: record.teacher.user?.email },
          { value: record.type },
          { value: record.description }
        ]
      }))}
    progress={{ loading: props.loading }}
    pagination={props.pagination}
  />

const dateToLocalFormat = (date: Date): string => moment(date).format('DD/MM/YYYY')
import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import littleAnahi from '../../../images/littleAnahi.png'

const InfoCard: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.infoCard}>
      <Typography variant="subtitle1">
        <img alt="info" src={littleAnahi} />&nbsp;&nbsp;
        {children}
      </Typography>
    </Paper>
  )
}

export default InfoCard;
import { ApiAction, PaginatedApiState, initialPaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import Class from '../../models/Class'

export interface HistoryGroupClassListState extends PaginatedApiState {
  classes?: Class[]
}

export default (state: HistoryGroupClassListState = initialPaginatedApiState, action: ApiAction ) => {
      return requestReducer<HistoryGroupClassListState>('HISTORY_GROUP_CLASS_LIST')(state, action)
}
import { PaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import SchoolContact from '../../models/SchoolContact'


export interface SchoolContactListState extends PaginatedApiState {
  contacts?: SchoolContact[]
}

export default requestReducer<SchoolContactListState>('SCHOOL_CONTACT_LIST')

import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { SchoolInput } from '../../models/School';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  school: SchoolInput;
  onDismiss: () => void;
  onSubmit: (school: SchoolInput) => void;
}

const SchoolEditDialog: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const [school, setSchool] = useState<SchoolInput>(props.school);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar escuela</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="RUEE"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.rue}
          onChange={e => setSchool({ ...school, rue: e.target.value })}
        />
        <TextField
          label="Departamento"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.department}
          onChange={e => setSchool({ ...school, department: e.target.value })}
        />
        <TextField
          label="Número"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.number}
          onChange={e => setSchool({ ...school, number: e.target.value })}
        />
        <TextField
          label="Área"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.area}
          onChange={e => setSchool({ ...school, area: e.target.value })}
        />
        <TextField
          label="Zona geográfica"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.zone}
          onChange={e => setSchool({ ...school, zone: e.target.value })}
        />
        <TextField
          label="Categoría"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.category}
          onChange={e => setSchool({ ...school, category: e.target.value })}
        />
        <TextField
          label="Turno"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.shiftName}
          onChange={e => setSchool({ ...school, shiftName: e.target.value })}
        />
        <TextField
          label="Horario"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.shiftTimes}
          onChange={e => setSchool({ ...school, shiftTimes: e.target.value })}
        />
        <TextField
          label="Nombre"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.name}
          onChange={e => setSchool({ ...school, name: e.target.value })}
        />
        <TextField
          label="Calle"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.streetName}
          onChange={e => setSchool({ ...school, streetName: e.target.value })}
        />
        <TextField
          label="Altura"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.streetNumber}
          onChange={e => setSchool({ ...school, streetNumber: e.target.value })}
        />
        <TextField
          label="Localidad"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.locality}
          onChange={e => setSchool({ ...school, locality: e.target.value })}
        />
        <TextField
          label="Teléfono (sin +598)"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.phoneNumber}
          onChange={e => setSchool({ ...school, phoneNumber: e.target.value })}
        />
        <TextField
          label="Email"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.email}
          onChange={e => setSchool({ ...school, email: e.target.value })}
        />
        <TextField
          label="Comparte"
          margin="normal" variant="filled" required fullWidth
          defaultValue={school.share}
          onChange={e => setSchool({ ...school, share: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(school)}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SchoolEditDialog
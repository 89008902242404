import { initialApiState, ApiState, ApiAction, RequestActionType } from '../utils/requestAction';


export const requestReducerForTypes = <State extends ApiState>(types: RequestActionType[], cb: (s: State, a: ApiAction) => State = _ => _) => (
  state: State = initialApiState as State,
  action: ApiAction
): State => {

  const merge = (state: State, action: ApiAction) => ({
    ...state,
    ...action.newApiState,
    ...action.requestStatus !== 'ERROR' ? { ...action.response, error: undefined } : {},
  })

  if (types.includes(action.type)) {
    switch (action.type) {
      case 'CLEAR':
        return initialApiState as State
      default:
        return cb(merge(state, action), action)
    }
  }

  return state
}

export default <State extends ApiState>(type: RequestActionType, cb: (s: State, a: ApiAction) => State = _ => _) =>
  requestReducerForTypes([type], cb)

export const volatilRequestReducer = <State extends ApiState>(type: RequestActionType, cb: (s: State, a: ApiAction) => State = _ => _) =>
  requestReducerForTypes([type, 'CLEAR'], cb)
import React from 'react'
import MakeReportIcon from '@material-ui/icons/Assignment'
import EditReportIcon from '@material-ui/icons/Edit'
import ViewReportIcon from '@material-ui/icons/Visibility'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Link } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/DateRange'
import MoveClassIcon from '@material-ui/icons/Update'
import Class from '../../models/Class'
import ClassReportDialog from '../../views/RemoteTeacher/ClassReport/ClassReport'
import DeleteClassDialog from '../../views/Administrator/DeleteClassDialog/DeleteClassDialog'
import CreateClassDialog from '../../views/RemoteTeacher/CreateClassDialog/CreateClassDialog'
import { AnahiDialogIconButton } from '../../views/components/Utils/Dialogs/AnahiDialogIconButton'
import { AppProp, connectApp } from '../../reducers'
import { isAdmin } from '../../reducers/auth/session'
import HistoryGroupClassReportDialog from '../../views/components/HistoryGroupClassesView'

interface ClassButton {
  aClass: Class
}

type ReportButtonProps = ClassButton & AppProp<'session'>

const UnmappedReportButton: React.FC<ReportButtonProps> = ({ aClass, session }) =>
  aClass.canBeReported() ? <GenericReportButton aClass={aClass} tooltip="Crear Informe" icon={MakeReportIcon} />
    : aClass.canBeEdited() || isAdmin(session!) ? <GenericReportButton aClass={aClass} tooltip="Editar Informe" icon={EditReportIcon} />
      : <SeeReportButton aClass={aClass} />

export const ReportButton = connectApp('session')(UnmappedReportButton)
export const SeeReportButton: React.FC<ClassButton> = (props) => <GenericReportButton tooltip="Ver Informe" icon={ViewReportIcon} disabled={true} {...props} />

const GenericReportButton = ({ aClass, tooltip, icon, disabled = false }) =>
  <AnahiDialogIconButton id='report-class-button'
    tooltip={tooltip}
    ButtonIcon={icon}
    disabled={false}
    TheDialog={ClassReportDialog}
    dialogProps={{ selectedClass: aClass, disabled }}
  />

export const DeleteClassButton: React.FC<ClassButton> = ({ aClass }) =>
  <AnahiDialogIconButton id='delete-class-button'
    tooltip={aClass.isRescheduled() ? 'No se puede eliminar una clase reagendada' : 'Eliminar clase'}
    ButtonIcon={DeleteForeverIcon}
    disabled={aClass.isRescheduled()}
    TheDialog={DeleteClassDialog}
    dialogProps={{ selectedClass: aClass }}
  />

export const RescheduleClassButton: React.FC<ClassButton> = ({ aClass }) =>
  <AnahiDialogIconButton id='reschedule-class-button'
    tooltip='Reprogramar la clase'
    ButtonIcon={ScheduleIcon}
    disabled={false}
    TheDialog={CreateClassDialog}
    dialogProps={{ assignation: aClass.assignation, classToReschedule: aClass }}
  />

export const MoveClassButton: React.FC<ClassButton> = ({ aClass }) =>
  <AnahiDialogIconButton id='move-class-button'
    tooltip='Mover la clase'
    ButtonIcon={MoveClassIcon}
    disabled={false}
    TheDialog={CreateClassDialog}
    dialogProps={{ assignation: aClass.assignation, updatableClass: aClass }}
  />

export const ViewHistoryGroupClasses: React.FC<ClassButton> = ({ aClass }) => { 
    return (
      <AnahiDialogIconButton id='report-history-group-classes'
        tooltip={'Ver Historial de Grupo'}
        ButtonIcon={()=><Link underline="always" variant="body2">{aClass.assignation.group.grade.toUpperCase()}</Link>}
        disabled={false}
        TheDialog={HistoryGroupClassReportDialog}
        dialogProps={{ assignation: aClass.assignation }}  />
    )}

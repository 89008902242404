import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAllTeachers } from '../../../actions/remoteTeacher/remoteTeacherList'
import sendBillRequests from '../../../actions/sendBillRequests'
import sendSettlements from '../../../actions/sendSettlements'
import RemoteTeacher from '../../../models/RemoteTeacher'
import { AppProp, connectApp } from '../../../reducers'
import RemoteTeacherSelectionTable from './RemoteTeacherSelectionTable'
import { PaymentMailsPeriodPicker } from './PaymentMailsPeriodPicker'

type MailSenderProps = AppProp<'remoteTeacherList'>

const settlement = 'liquidación'
export const settlements = 'liquidaciones'
const billRequest = 'solicitud de factura'
export const billRequests = 'solicitudes de facturas'

export type SingularPaymentMailConcept = typeof settlement | typeof billRequest
export type PluralPaymentMailConcept = typeof settlements | typeof billRequests //Es importante que ambos sean femeninos por los pronombres

const MailSender: React.FC<MailSenderProps> = ({ remoteTeacherList }) => {
  const dispatch = useDispatch()
  const [selectedTeachers, setSelectedTeachers] = useState<RemoteTeacher[]>([])

  useEffect(() => {
    dispatch(fetchAllTeachers())
  }, [dispatch]);

  return <>
    <PaymentMailsPeriodPicker
      singularConcept={settlement}
      pluralConcept={settlements}
      teachers={selectedTeachers}
      action={(interval, teachers) => dispatch(sendSettlements(interval, teachers))}      
    />
    <PaymentMailsPeriodPicker
      singularConcept={billRequest}
      pluralConcept={billRequests}
      teachers={selectedTeachers}
      action={(interval, teachers) => dispatch(sendBillRequests(interval, teachers))}      
    />
    {remoteTeacherList.teachers && 
      <RemoteTeacherSelectionTable 
        teachers={ remoteTeacherList.teachers } 
        onChange={ selectedRTs => setSelectedTeachers(selectedRTs) }
      />
    }
  </>
}

export default connectApp('remoteTeacherList')(MailSender)
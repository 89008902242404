import { AppState } from '../reducers/index'
import { Dispatch } from 'react'
import { Action } from 'redux'
import Pagination from '../models/Pagination'
import fetchApi, { HttpMethod, getToken } from './fetchApi'
import { pushAlert } from '../actions/alert'
import { updateNotifications } from '../actions/notifications'

type RequestStatus = 'FETCH' | 'FETCHING' | 'ERROR'

export type RequestActionType = 'AVAILABILITYSLOTS'
  | 'ASSIGNATION_BULK_UPSERT'
  | 'ASSIGNATION_CONFIRM'
  | 'ASSIGNATION_DETAIL'
  | 'ASSIGNATION_NOTIFY_SCHOOLS'
  | 'ASSIGNATION_NOTIFY_TEACHERS'
  | 'ASSIGNATION_LIST'
  | 'ASSIGNATION_DELETE'
  | 'CLASS_HAS_SCHEDULE_FREE'
  | 'CLASS_LIST'
  | 'CLASS_UPDATE'
  | 'CLASS_INSERT'
  | 'CLASS_DELETE'
  | 'CLASSROOM_TEACHER_UPDATE'
  | 'CONFIG'
  | 'FETCH_CLASS'
  | 'NOTIFY_SCHOOLS'
  | 'NOTIFY_TEACHERS'
  | 'NON_SCHOOL_DAY_BULK_UPSERT'
  | 'NON_SCHOOL_DAY_LIST'
  | 'LOGIN'
  | 'PASSWORD_RECOVERY'
  | 'PROFILE'
  | 'REMOTE_TEACHER_BULK_UPSERT'
  | 'REMOTE_TEACHER_DETAIL'
  | 'REMOTE_TEACHER_LIST'
  | 'REMOTE_TEACHER_UPSERT'
  | 'SESSION'
  | 'SESSION_CLOSED'
  | 'SCHOOL_BULK_UPSERT'
  | 'SCHOOL_DETAIL'
  | 'SCHOOL_LIST'
  | 'SCHOOL_UPSERT'
  | 'SCHOOL_CONTACT_BULK_UPSERT'
  | 'SCHOOL_CONTACT_DETAIL'
  | 'SCHOOL_CONTACT_LIST'
  | 'SCHOOL_CONTACT_UPSERT'
  | 'SCHOOL_JABBER_USER_BULK_UPSERT'
  | 'SCHOOL_JABBER_USER_DETAIL'
  | 'SCHOOL_JABBER_USER_LIST'
  | 'SCHOOL_JABBER_USER_UPSERT'
  | 'SET_CLASS'
  | 'UPDATE_CREDENTIALS'
  | 'CLEAR'
  | 'UPDATE_NOTIFICATIONS'
  | 'SEND_SETTLEMENTS'
  | 'SEND_BILLREQUESTS'
  | 'SETTLEMENT_RECORD_LIST'
  | 'PLATFORM_LIST'
  | 'PROPOSAL_LIST'
  | 'HISTORY_GROUP_CLASS_LIST'

export interface ApiAction extends Action<RequestActionType> {
  requestStatus?: RequestStatus,
  newApiState: ApiState,
  response?: any,
}

export interface ApiState {
  isFetching: boolean,
  didInvalidate: boolean,
  error?: Error,
}

export const initialApiState: ApiState = {
  isFetching: false,
  didInvalidate: false,
}


export interface PaginatedApiState extends ApiState {
  pagination: Pagination
}

export const initialPaginatedApiState: PaginatedApiState = {
  ...initialApiState,
  pagination: {
    size: 100,
    count: 0,
    page: 0,
  }
}

export const dispatchResponse = (type) => (dispatch, _, response) => dispatch({ type, ...response })
export const alertMessage = (message, isError?) => (dispatch) => dispatch(pushAlert(message, isError))
export const alertError = (message) => alertMessage(message, true)

const requestAction = (type: RequestActionType, params?: string) => (route: string, method: HttpMethod, body?: any,
  handleResponse: (dispatch: Dispatch<any>, getState: () => AppState, response: any) => void = _ => { },
  handleError: (dispatch: Dispatch<any>, err: Error) => void = _ => { }) =>
  async (dispatch: Dispatch<ApiAction>, getState: () => AppState) => {
    let token = getToken()

    try {
      dispatch({
        type,
        requestStatus: 'FETCHING',
        newApiState: {
          isFetching: true,
          didInvalidate: false,
        }
      })

      const search = params || ''
      const response = await fetchApi(token, method, `${route}${search}`, body)
      if (response.error) throw new Error(response.error)

      dispatch({
        type,
        requestStatus: 'FETCH',
        response,
        newApiState: {
          isFetching: false,
          didInvalidate: false,
        }
      })

      response.notifications && updateNotifications(response.notifications)(dispatch)

      handleResponse(dispatch, getState, response)

    } catch (error) {

      dispatch({
        type,
        requestStatus: 'ERROR',
        newApiState: {
          isFetching: false,
          didInvalidate: true,
          error: error as Error
        }
      })

      handleError(dispatch, error as Error)
    }
  }

export default requestAction
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppState } from '../../../reducers'
import { isAdmin, isLoggedIn } from '../../../reducers/auth/session';
import { AssignationStatus } from '../../../models/Assignation'
import { fetchAssignation } from '../../../actions/assignation/assignationDetail'
import { Grid, Typography } from '@material-ui/core'
import AssignationHeader from './AssignationHeader'
import AssignationInformation from './AssignationInformation'
import AssignationSchool from './AssignationSchool'
import AssignationRemoteTeacher from './AssignationRemoteTeacher'
import AssignationClassroomTeacher from './AssignationClassroomTeacher'
import AssignationSchoolJabberUser from './AssignationSchoolJabberUser'
import AssignationSchoolContact from './AssignationSchoolContact'

export interface AssignationDetailProps {
  getStatusSubtitle?: (status: AssignationStatus) => string | null
  getStatusButtons?: React.ReactNode
  disabled?: boolean
}

const AssignationDetail: React.FC<StateProps & AssignationDetailProps> = ({ assignationDetail, session, getStatusSubtitle, getStatusButtons, disabled = false }) => {
  const dispatch = useDispatch()
  const params = useParams<{ assignationId: string }>()

  useEffect(() => {
    dispatch(fetchAssignation(params.assignationId))
  }, [params.assignationId, dispatch])

  if (!assignationDetail || !assignationDetail.assignation) {
    return null
  }

  const assignation = assignationDetail.assignation
  const started = assignationDetail.started 
  const { group, teacher } = assignation

  return (
    <div>
      <AssignationHeader assignation={assignation} {...({ getStatusSubtitle, getStatusButtons })} />
      <Grid container spacing={2}>
        <Section name="Escuela">
          <Grid item xs={6}>
            <AssignationInformation disabled={disabled} started={started} />
          </Grid>
          <Grid item xs={6}>
            <AssignationSchool {...group.school} />
          </Grid>
        </Section>
        <Section name="Docentes">
          {
            teacher && !isLoggedIn(session, teacher!.user) &&
            <Grid item xs={6}>
              <AssignationRemoteTeacher assignation={assignation} disabled={!isAdmin(session)} />
            </Grid>
          }
          {
            group.teacher &&
            <Grid item xs={6}>
              <AssignationClassroomTeacher assignation={assignation} teacher={group.teacher} disabled={disabled} />
            </Grid>
          }
        </Section>
        <Section name="Jabber">
          {
            group.school.jabberUsers.map((jabber, i) =>
              <Grid item xs={6} key={i}>
                <AssignationSchoolJabberUser {...jabber} />
              </Grid>
            )
          }
        </Section>
        <Section name="Contactos Escolares">
          {
            group.school.contacts.map((contact, i) =>
              <Grid item xs={6} key={i}>
                <AssignationSchoolContact {...contact} />
              </Grid>
            )
          }
        </Section>
      </Grid>
    </div>
  )
}

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: AppState) => ({
  assignationDetail: state.assignationDetail,
  session: state.session,
})

export default connect<StateProps, {}, AssignationDetailProps, AppState>(mapStateToProps)(AssignationDetail)

const Section: React.FC<{ name: string }> = ({ name, children }) =>
  <>
    <Typography variant="h5">
      {name}
    </Typography>
    <Grid item xs={12}>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Grid>
  </>

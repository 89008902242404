import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { SchoolContactBulkUpsertState } from '../../../reducers/schoolContact/schoolContactBulkUpsert';
import { bulkUpsertContacts } from '../../../actions/schoolContact/schoolContactBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload, { FieldType } from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import SchoolContactListTable from '../../../components/SchoolContact/SchoolContactListTable';
import SchoolContactEditDialog from '../../../components/SchoolContact/SchoolContactEditDialog';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';

import { SchoolContactInput } from '../../../models/SchoolContact';

interface Props {
  contactBulkUpsert: SchoolContactBulkUpsertState;
}

const PAGE_SIZE = 10;

const CSV_FIELDS = [
  { name: 'rue', display: 'RUEE', type: FieldType.String },
  { name: 'fullName', display: 'Nombre completo', type: FieldType.String },
  { name: 'position', display: 'Cargo', type: FieldType.String },
  { name: 'phoneNumber', display: 'Teléfono (sin +598)', type: FieldType.String },
  { name: 'email', display: 'Email', type: FieldType.String }
];

const SchoolContactCsvCreation: React.FC<Props> = (props) => {  
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [contacts, setContacts] = useState<SchoolContactInput[]>([]);
  const [selected, setSelected] = useState<number|null>(null);
  const [page, setPage] = useState<number>(0);

  const handleUpload = (records: any[]) => {
    setContacts(records as SchoolContactInput[]);
    setStep(1);
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertContacts(contacts));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const handleFinish = () => {
    history.push('/admin/school-contacts', {title: 'Contactos escolares'});
  }

  const handleUpdateContact = (contact: SchoolContactInput) => {
    setContacts(contacts.map((s, i) => i === selected ? contact : s));
    setSelected(null);
  }

  const getAlertTitle = () => {
    if (selected !== null) {
      switch ((props.contactBulkUpsert.results || [])[selected].action) {
        case 'create':
          return 'Creado correctamente';
        case 'update':
          return 'Actualizado correctamente';
        case 'error':
          return 'Error';
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  const getAlertMessage = () => {
    if (selected !== null) {
      switch ((props.contactBulkUpsert.results || [])[selected].action) {
        case 'error':
          return (props.contactBulkUpsert.results || [])[selected].error;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <BulkUpsertUpload
          fields={CSV_FIELDS}
          onSubmit={handleUpload}
        />
      }

      {step === 1 &&
        <BulkUpsertConfirm
          tableComponent={
            <SchoolContactListTable
              loading={false}
              showEditButton={true}
              rows={
                contacts
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(s => ({ data: s }))
              }
              pagination={{ size: PAGE_SIZE, count: contacts.length, page }}
              onClickEdit={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleConfirm}
        />
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <SchoolContactListTable
              loading={props.contactBulkUpsert.isFetching}
              showInfoButton={true}
              rows={
                (props.contactBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(r => ({ data: r.record, action: r.action as any }))
              }
              pagination={{ size: PAGE_SIZE, count: (props.contactBulkUpsert.results || []).length, page }}
              onClickInfo={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <SchoolContactEditDialog
          contact={contacts[selected]}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateContact}
        />
      }
      
      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  contactBulkUpsert: state.schoolContactBulkUpsert
})

export default connect(mapStateToProps, null)(SchoolContactCsvCreation)
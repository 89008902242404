import { Checkbox as MaterialCheckbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { isEmpty, not } from "ramda"
import React, { ChangeEvent, FC } from "react"

interface CheckboxProps {
    disabled?: boolean
    hidden?: boolean
    helperText?: string
    label?: string
    onChange?: ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
    required?: boolean
    value?: boolean
}

const Checkbox: FC<CheckboxProps> = ({ disabled = false, hidden = false, helperText = "", label = "",
    onChange, required = false, value = false }) =>
    <div>
        {not(hidden) && <FormControl component="fieldset" fullWidth margin="normal" required={required} variant="filled">
            <FormControlLabel
                checked={Boolean(value)}
                control={<MaterialCheckbox
                    disabled={disabled}
                    value={true}
                    onChange={onChange}
                    color="primary" />}
                label={label}
            />
            {not(isEmpty(helperText)) &&
                <div className="MuiFormHelperText-contained">
                    <FormHelperText>{helperText}</FormHelperText>
                </div>
            }
        </FormControl>}
    </div>

export default Checkbox

import moment from "moment"
import { Moment } from "moment"
import { applyTo, compose, not } from "ramda"
import Class from "../../../models/Class"
import NonSchoolDay from "../../../models/NonSchoolDay"
import { isAdmin, SessionState } from "../../../reducers/auth/session"
import { isBeforeThisWeek, isOnWeekend, onInvalidDateForClassCreation } from "../../../utils/days"

export const isValidDate = (date: Moment, session: SessionState, nonSchoolDayList: NonSchoolDay[], classToReschedule?: Class): boolean => {

    const createClassvalidations = [
      (date: Moment): boolean => date?.isValid(),
      compose(not, isOnWeekend),
      compose(not, isOnNonSchoolDay(nonSchoolDayList)),
      isValidForRole(session)
    ]

    const rescheduleClassValidations = [
      ...createClassvalidations,
      compose(not, sameDate(classToReschedule))
    ]
    return (classToReschedule
      ? rescheduleClassValidations
      : createClassvalidations
    ).every(applyTo(date))
  }

export const sameDate = (classToReschedule) => (date: Moment) =>
    date?.isSame(moment(classToReschedule.sinceDate), 'date')

const isValidForRole = (session: SessionState) => (date: Moment) =>
    isAdmin(session) || !isBeforeThisWeek(date)

export const isOnNonSchoolDay = (nonSchoolDayList: NonSchoolDay[]) => (date: Moment): boolean =>
    onInvalidDateForClassCreation(date, nonSchoolDayList)
import React from 'react'
import Header from './Header'
import NavMenu from './NavMenu'
import useStyles from './styles'

const MainLayout: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Header />
      <NavMenu />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default MainLayout



import { Moment } from 'moment'
import { juxt } from 'ramda'
import requestAction, { alertMessage } from '../../utils/requestAction'
import { fetchClasses } from './classList'

type classInsertInput = {
    assignationId: number
    rescheduleClassId?: number
    sinceDate: Moment
    teacherId: number
}

const CLASS_INSERT = 'CLASS_INSERT'

export default (history, { assignationId, rescheduleClassId, sinceDate, teacherId }: classInsertInput) =>
    requestAction(CLASS_INSERT)(`class`, 'PUT', { assignationId, rescheduleClassId, sinceDate, teacherId },
        juxt([fetchClasses(history.location.search), alertMessage(rescheduleClassId ? "Clase reagendada" : "Clase creada")]))
import { ApiAction, initialPaginatedApiState, PaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import School from '../../models/School'
import { Action } from 'redux'
import { replaceEntityById } from '../../utils/reducers'


export interface SchoolListState extends PaginatedApiState {
  schools?: School[]
}

interface UpsertSchool extends Action<'UPSERT_SCHOOL'> {
  school: School
}

export default (state: SchoolListState = initialPaginatedApiState, action: ApiAction | UpsertSchool) => {
  switch (action.type) {
    case 'UPSERT_SCHOOL':
      return { ...state, schools: upsertSchool(action, state) }
    default:
      return requestReducer<SchoolListState>('SCHOOL_LIST')(state, action)
  }
}

const upsertSchool = (action, state) =>
  state.schools.some(school => school.id === action.school.id) ?
    replaceEntityById(action.school, state.schools || []) :
    state.schools.concat([action.school])
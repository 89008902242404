import { identity, isEmpty, isNil, map, or, replace, sortBy, toLower, toUpper } from 'ramda'

// https://stackoverflow.com/questions/44497388/typescript-array-to-string-literal-type
export const literalStrings = <T extends string[]>(...strs: T) => strs

export const sortAlphabetically = (elements: string[], transformation: (str: string) => string = identity): string[] => sortBy(transformation, elements)

export const toString: StringConstructor = String

export const replaceSemicolon = (replacement: string, srt: string = "") => replace(';', replacement)(srt)

export const toUpperCase = (str?: string): string => toUpper(toString(str))

export const toLowerCase = (str?: string): string => toLower(toString(str))

export const capitalize = (str: string = ""): string => replace(/^./, toUpperCase)(str)

export const arraytoStrings = (...strs: any[]): string[] => map(toString, strs)

export const isNilOrEmpty = (obj) => or(isNil(obj), isEmpty(obj))

export const sortByAdapter = <T>(elements: T[], adapter: (element: T) => string): T[] => elements.sort((firstElement, nextElement) => adapter(firstElement).toLowerCase() < adapter(nextElement).toLowerCase() ? -1 : 1)
import { ApiState, initialApiState, ApiAction } from '../../utils/requestAction'
import { volatilRequestReducer } from '../requestReducer'
import Assignation from '../../models/Assignation'
import ClassroomTeacher from '../../models/ClassroomTeacher'

export interface AssignationDetailState extends ApiState {
  assignation?: Assignation
  started?: boolean
}


export default (state: AssignationDetailState = initialApiState, action: ApiAction) => {
  switch (action.type) {
    case 'CLASSROOM_TEACHER_UPDATE':
      if (action.requestStatus === 'FETCH' && state.assignation)
        return { ...state, assignation: replaceClassroomTeacher(state.assignation, action.response.classroomTeacher) }
      else
        return state
    default:
      return volatilRequestReducer<AssignationDetailState>('ASSIGNATION_DETAIL')(state, action)
  }
}

const replaceClassroomTeacher = (assignation: Assignation, teacher: ClassroomTeacher) : Assignation => ({
  ...assignation,
  group: {
    ...assignation.group,
    teacher
  }
})
import ClearRescheduledClassIcon from '@material-ui/icons/Close'
import SeeRescheduledClassIcon from '@material-ui/icons/Event'
import { any, filter, findIndex, inc, insert, last, not, propEq } from 'ramda'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import fetchClass from '../../actions/class/classDetail'
import Class from '../../models/Class'
import Pagination from '../../models/Pagination'
import Table, { TableRow } from '../Table'
import TooltipIconButton from '../TooltipIconButton'
import { ClassTableColumn } from './ClassListColumns'
import { isAdmin, SessionState } from '../../reducers/auth/session'
import { AppState } from '../../reducers'
import { DeleteClassButton, RescheduleClassButton, MoveClassButton } from './ClassListButtons'

interface ClassListTableProps {
  columns: ClassTableColumn[]
  classes: any[]
  loading: boolean
  pagination?: Pagination
  session?: SessionState
}

//TODO: Move
type Selectable<T> = T & { selected?: boolean }


const ClassListTable = (props: ClassListTableProps) => {

  const dispatch = useDispatch()

  const initialState = () => props.classes

  const [classes, setClasses] = useState<Selectable<Class>[]>([])

  // veces que se intento sacar este useEffect: 2
  // Estamos planeando refactors a futuro: https://github.com/fundacion-sadosky/anahi-frontend/issues/311
  useEffect(() => {
    setClasses(initialState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classes])

  const sameId = (aClass: Class, anotherClass: Class): boolean => propEq('id', aClass.id)(anotherClass)

  const sameAndSelected = (aClass: Class, anotherClass: Class): boolean => sameId(aClass, anotherClass) && isSelected(aClass)

  const sameAndNotSelected = (aClass: Class, anotherClass: Class): boolean => sameId(aClass, anotherClass) && not(isSelected(aClass))

  const isSelected = ({ selected }: Selectable<Class>): boolean => Boolean(selected)

  const selectedClasses = (): Selectable<Class>[] => filter(isSelected, classes)

  const lastSelectedRescheduledClass = (): Class => last(selectedClasses()) as Class

  const findClassIndex = (sentence: (cls: Selectable<Class>) => boolean): number => findIndex(sentence, classes)

  const findNextClassIndex = (sentence: (cls: Selectable<Class>) => boolean): number => inc(findClassIndex(sentence))

  const findNextAvailableClassIndex = (aClass: Class): number => findNextClassIndex((cls: Selectable<Class>) => sameAndNotSelected(aClass, cls))

  const findNextAvailableClassIndexAfterLastSelection = () => findNextClassIndex((cls: Selectable<Class>) => sameId(lastSelectedRescheduledClass(), cls))

  const alreadySelected = (aClass: Selectable<Class>) => any((cls) => sameAndSelected(cls, aClass), classes)

  const removeClassRow = (aClass: Class): void => setClasses(filter((cls: Selectable<Class>) => not(sameAndSelected(cls, aClass)), classes))

  const createClassRow = (aClass: Selectable<Class>, parent: Selectable<Class>, classes: Selectable<Class>[]): void => {
    if (not(alreadySelected(aClass))) {
      const index: number = isSelected(parent) ? findNextAvailableClassIndexAfterLastSelection() : findNextAvailableClassIndex(parent)
      setClasses(insert(index, aClass, isSelected(parent) ? classes : initialState()))
    }
    else if (not(isSelected(parent))) setClasses(insert(findNextAvailableClassIndex(parent), aClass, initialState()))
  }

  const canDeleteOrMoveCondition = (aClass: Class) =>
    ( !(aClass.kind === 'O') && aClass.isPending() && !aClass.sentToCeibal )
  
  const canDelete = (aClass: Class) =>
    isAdmin(props.session!) || canDeleteOrMoveCondition(aClass)

  const rescheduleButton = (aClass: Class) =>
    aClass.canBeRescheduled() ? <RescheduleClassButton aClass={aClass} /> :
      aClass.isRescheduled() ? SeeReschduledClassButton(aClass) : 
      canDeleteOrMoveCondition(aClass) ? <MoveClassButton aClass={aClass} /> : <span />

  const ClearReschduleClassButton = (aClass: Class) =>
    <TooltipIconButton onClick={() => removeClassRow(aClass)} tooltip="Cerrar">
      <ClearRescheduledClassIcon />
    </TooltipIconButton>

  const SeeReschduledClassButton = (aClass: Class) =>
    <TooltipIconButton onClick={() => {
      if (aClass.rescheduledToId) dispatch(fetchClass(aClass.rescheduledToId,
        (cls: Class) => createClassRow({ selected: true, ...cls } as Selectable<Class>, aClass as Selectable<Class>, classes)))
    }}
      tooltip="Ver clase reprogramada">
      <SeeRescheduledClassIcon />
    </TooltipIconButton>

  const rows: TableRow<Class>[] = classes.map(aClass => {
    const classInstance = new Class(aClass)
    return {
      selected: isSelected(aClass),
      cells: [
        ...props.columns.map(column => column.value(classInstance)),
        rescheduleButton(classInstance),
        isSelected(aClass) ? ClearReschduleClassButton(classInstance) : <span />,
        canDelete(classInstance) ? <DeleteClassButton aClass={classInstance} /> : <span />
      ].map(value => ({ value }))
    }
  })

  return (
    <div>
      <Table
        aria-label="classrooms"
        headerrow={{ hover: false, visible: true, selected: false, cells: [...props.columns.map(({ name }) => name), "", "", "", ""].map(value => ({ value })) }}
        bodyrows={rows}
        progress={{ loading: props.loading }}
        pagination={props.pagination}
      />
    </div>
  )
}

const mapStateToProps = ({ session }: AppState) => ({ session })

export default connect<ReturnType<typeof mapStateToProps>, {}, ClassListTableProps, AppState>(mapStateToProps)(ClassListTable)

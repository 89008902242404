import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { red, lightGreen } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    cardHeader: {
      padding: theme.spacing(2),
      borderBottomColor: '#EEEEEE',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    },
    cardTitle: {
      fontWeight: 500
    },
    cardTable: {
      '& tr:nth-child(odd)': {
        backgroundColor: '#F4F6F8'
      }
    },
    cardBody: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2)
    },
    statusHeader: {
      padding: theme.spacing(2),
      borderBottomColor: '#EEEEEE',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    },
    statusTitle: {
      fontWeight: 500,
      color: '#FFFFFF'
    },
    statusBody: {
      padding: theme.spacing(2),
      display: 'flex'
    },
    statusDescription: {
      flexGrow: 1,
      paddingTop: theme.spacing(1)
    },
    statusButtons: {
      textAlign: 'right'
    },
    rejectButton: {
      marginLeft: theme.spacing(2),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[900]
      }
    },
    successButton: {
      marginLeft: theme.spacing(2),
      backgroundColor: lightGreen[500],
      '&:hover': {
        backgroundColor: lightGreen[900]
      }
    },
    sendButton: {
      marginTop: theme.spacing(2),
    }
  }),
)

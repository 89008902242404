import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'


export const BackButton = () => {
  const history = useHistory()

  return (
    <Button aria-label="back" onClick={() => history.goBack()}>
      <ArrowBackIosIcon fontSize="inherit" /> Volver
    </Button>
  )
}
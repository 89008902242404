import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    zIndex:99
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'visible',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4)
  },
  drawerToolbar: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    width: `calc(100% + 1px)`,
  },
  profileBox: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  title: {
    textTransform: 'capitalize'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  scrollable: {
    overflowY: 'auto'
  },
}));

export default useStyles
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  sendButton: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.dark,
  },
}))
import React from 'react';
import SchoolContact, { SchoolContactInput } from '../../models/SchoolContact';
import Pagination from '../../models/Pagination';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import ColoredTableRow from '../ColoredTableRow';
import PaginationButtons from '../PaginationButtons';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 5
    },
    paper: {
      marginBottom: 64 + theme.spacing(4)
    },
    progress: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
    statusCell: {
      textAlign: 'center'
    }
  }),
);

export interface Row {
  data: (SchoolContact|SchoolContactInput);
  action?: 'create' | 'update' | 'error';
}

interface Props {
  rows: Row[];
  loading: boolean;
  showEditButton?: boolean;
  onClickEdit?: (row: Row, index: number) => void;
  pagination?: Pagination;
  onChangePage?: (page: number) => void;
  showInfoButton?: boolean;
  onClickInfo?: (row: Row, index: number) => void;
}

const SchoolContactListTable: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const showEditButton = props.showEditButton || false;
  const showInfoButton = props.showInfoButton || false;

  const mapActionToColor = (action?: string) => {
    switch (action) {
      case 'create': return 'green';
      case 'update': return 'yellow';
      case 'error': return 'red';
      default: return 'default';
    }
  }

  return (
    <Paper square className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow selected={true}>
            <TableCell>RUEE</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Cargo</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Email</TableCell>
            { showEditButton && <TableCell/> }
            { showInfoButton && <TableCell/> }
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) =>
            <ColoredTableRow key={index} color={mapActionToColor(row.action)}>
              <TableCell>{(row.data as SchoolContact).school ? (row.data as SchoolContact).school.rue : (row.data as SchoolContactInput).rue}</TableCell>
              <TableCell>{row.data.fullName}</TableCell>
              <TableCell>{row.data.position}</TableCell>
              <TableCell>{(row.data as SchoolContact).fullPhoneNumber || row.data.phoneNumber}</TableCell>
              <TableCell>{row.data.email}</TableCell>
              { showEditButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickEdit && props.onClickEdit(row, index)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
              { showInfoButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickInfo && props.onClickInfo(row, index)}
                  >
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
            </ColoredTableRow>
          )}
        </TableBody>
      </Table>
      { props.loading &&
        <div className={classes.progress}>
          <CircularProgress size={100} />
        </div>
      }
      <PaginationButtons pagination={props.pagination} />
    </Paper>
  );
}

export default SchoolContactListTable
import React from 'react'
import { Button, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface DropdownButtonProps<T> {
  label: string
  options: T[]
  selected: T
  onChange: (selected: T) => void
  map?: (option: T) => string
}

function DropdownButton<T extends string>({ label, options, selected, onChange, map = x => { return x } }: DropdownButtonProps<T>) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const onSelect = (newSelected: T) => () => {
    setOpen(false)
    if (newSelected !== selected) onChange(newSelected)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label={label}>
        <Button onClick={handleToggle}>{label}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(option =>
                    <MenuItem
                      key={option}
                      selected={option === selected}
                      onClick={onSelect(option)}
                    >
                      {map(option)}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default DropdownButton
import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: 120,
      flexGrow: 2
    },
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  }))
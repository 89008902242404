import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fetching: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  assignationsQuantity: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  separator: {
    verticalAlign: "bottom",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

export default useStyles
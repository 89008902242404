import { Badge } from '@material-ui/core'
import React from 'react'
import { ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core'
import useStyles from './styles'

interface ButtonItemProps {
  title: string
  icon: string
  nested?: boolean
  notification?: React.ReactNode
  selected?: boolean
  onClick: () => void
}

const ButtonItem: React.FC<ButtonItemProps> = ({ title, icon, nested = false, notification, selected = false, onClick }) => {
  const classes = useStyles()
  return (
    <ListItem button className={nested ? classes.nested : ''}
      selected={selected}
      onClick={onClick}
    >
      <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
      <Badge badgeContent={notification} color="secondary">
        <ListItemText primary={title} />
      </Badge>
    </ListItem>
  )
}

export default ButtonItem
import React from 'react'
import { Paper, Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core'
import { useStyles } from './styles'

type Row = [string, string | ((entity: any) => string)]

interface EntityDetailsProps {
  title?: string
  entity: any //TODO: Type properties
  rows: Row[]
  gadgets?: React.ReactNode
}

const makeValue = (entity: any) => ([label, attr]: Row) => [label, typeof attr === 'string' ? entity[attr] : attr(entity)]

const makeHeaderRow = (title, classes, gadgets?) =>
  <TableRow key={title}>
    <TableCell className={classes.cardTitle}>{title}</TableCell>
    <TableCell className={classes.gadgets}>{gadgets || ""}</TableCell>
  </TableRow>

const makeRow = (label, value) =>
  <TableRow key={label}>
    <TableCell>{label}</TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>

const makeRows = (rows, entity) => rows
  .map(makeValue(entity))
  .filter(([_, value]) => value)
  .map(([label, value]) => makeRow(label, value))

const EntityDetails: React.FC<EntityDetailsProps> = ({ title, entity, rows, gadgets }) => {
  const classes = useStyles()
  const tableRows = makeRows(rows, entity)
  return (
    <Paper square>
      <Table className={classes.entityDetails}>
        <TableHead>
          {title ? makeHeaderRow(title, classes, gadgets) : null}
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default EntityDetails
import { PaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import SchoolJabberUser from '../../models/SchoolJabberUser'


export interface SchoolJabberUserListState extends PaginatedApiState {
  users?: SchoolJabberUser[]
}

export default requestReducer<SchoolJabberUserListState>('SCHOOL_JABBER_USER_LIST')

import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { lightGreen, amber, red } from '@material-ui/core/colors';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowGreen: {
      backgroundColor: lightGreen[200],
      '&:hover': {
        backgroundColor: `${lightGreen[300]} !important`
      },
      '& td': {
        borderBottomColor: lightGreen[400]
      }
    },
    rowYellow: {
      backgroundColor: amber[200],
      '&:hover': {
        backgroundColor: `${amber[300]} !important`
      },
      '& td': {
        borderBottomColor: amber[400]
      }
    },
    rowRed: {
      backgroundColor: red[200],
      '&:hover': {
        backgroundColor: `${red[300]} !important`
      },
      '& td': {
        borderBottomColor: red[400]
      }
    },
  }),
);

interface Props {
  color: 'default' | 'green' | 'yellow' | 'red';
}

const ColoredTableRow: React.FC<Props> = (props) => {  
  const classes = useStyles();

  let rowClass;
  switch (props.color) {
    case 'green':
      rowClass = classes.rowGreen;
      break;
    case 'yellow':
      rowClass = classes.rowYellow;
      break;
    case 'red':
      rowClass = classes.rowRed;
      break;
  }

  return (
    <TableRow hover={true} className={rowClass}>
      {props.children}
    </TableRow>
  );
}

export default ColoredTableRow
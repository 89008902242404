import { Button } from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete'
import AssignationSummary from "../../components/AssignationSummary";
import AnahiDialog from '../../components/Utils/Dialogs/AnahiDialog';
import { useHistory } from "react-router-dom";
import Assignation from "../../../models/Assignation";
import assignationDelete from "../../../actions/assignation/assignationDelete";
import { DialogBasicProps } from "../../components/Utils/Dialogs/AnahiDialogIconButton";

type DeleteAssignationDialogProps = DialogBasicProps & { selectedAssignation: Assignation }

const DeleteAssignationDialog: FC<DeleteAssignationDialogProps> = ({ onClose, open, selectedAssignation }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <AnahiDialog
      open={open}
      onClose={onClose}
      title='¿Segurx que desea borrar la asignación?'

      body={
        <AssignationSummary
          department={selectedAssignation.group.school.department}
          grade={selectedAssignation.group.grade}
          schoolNumber={selectedAssignation.group.school.number}
          classroomTeacher={selectedAssignation.group.teacher} />
      }

      actions={
        <><Button onClick={(e)=>{ e.stopPropagation(); onClose()}} color="primary" variant="contained">
          Cancelar
      </Button>

          <Button startIcon={<DeleteIcon />} onClick={(e)=>{ e.stopPropagation(); dispatch(assignationDelete(selectedAssignation, history)); onClose(); }} color="secondary" variant="contained">
            Eliminar la asignacion
        </Button></>
      }>

    </AnahiDialog>
  )

}

export default DeleteAssignationDialog
import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import Assignation from '../../models/Assignation';


export interface AssignationConfirmState extends ApiState {
  assignation?: Assignation
}

export default requestReducer<AssignationConfirmState>('ASSIGNATION_CONFIRM')

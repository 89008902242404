import React, { useState, FormEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../reducers'
import { updateCredentials } from '../../actions/auth/updateCredentials'
import { pushAlert } from '../../actions/alert'
import { Typography } from '@material-ui/core'
import ProgressButton from '../components/Utils/ProgressButton'
import NewPassword from '../components/Utils/NewPassword'
import AnahiCard from '../components/Utils/AnahiCard'

const Login: React.FC<StateProps> = ({ passwordRecovery }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [newPassword, setNewPassword] = useState<string | null>(null)

  const handleChangePassword = (event: FormEvent) => {
    event.preventDefault()
    dispatch(updateCredentials({ newPassword: newPassword! }, () => {
      dispatch(pushAlert('Contraseña cambiada. Ya podés ingresar.'))
      history.push('/login')
    })(history.location.search))
  }

  return (
    <AnahiCard>
      <Typography align="center" variant="subtitle1">
        Ingrese una nueva contraseña
      </Typography>
      <form onSubmit={handleChangePassword}>
        <NewPassword onValidPassword={setNewPassword} />
        <ProgressButton state={passwordRecovery} disabled={!newPassword}>
          Cambiar contraseña
        </ProgressButton>
      </form>
    </AnahiCard>
  )
}

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = ({ passwordRecovery }: AppState) => ({ passwordRecovery })

export default connect(mapStateToProps, null)(Login)

import React, { ChangeEvent } from 'react'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel, Select, TextFieldProps } from '@material-ui/core';

interface EntityFieldProps<Entity> {
  label: string
  entity: Entity
  attr: keyof Entity
  //TODO: Use TextFieldProps
  required?: boolean
  disabled?: boolean
}

export const entityTextField = <T extends {}>(change: (newEntity: T) => void): React.FC<EntityFieldProps<T> & TextFieldProps> =>
  ({ label, entity, attr, ...props }) =>
    <TextField
      label={label}
      margin="normal"
      value={entity[attr] || ''}
      onChange={onChange(change, entity, attr)}
      fullWidth
      {...props}
    />

export const entitySelectField = <T extends {}>(change: (newEntity: T) => void): React.FC<EntityFieldProps<T>> =>
  ({ label, entity, attr, children, ...props }) =>
    <FormControl margin="normal" fullWidth {...props}>
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue={entity[attr]}
        onChange={onChange(change, entity, attr)}
      >
        {children}
      </Select>
    </FormControl>

const onChange = <Entity extends {}>(change: (newEntity: Entity) => any, entity: Entity, attr: keyof Entity) => (event: ChangeEvent<{ value }>) => {
  event.preventDefault()
  change({ ...entity, [attr]: event.target.value })
}

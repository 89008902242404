import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { doLogout } from '../../actions/auth/session'
import logo from '../../images/anahi-logo-blanco.png'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'

import useStyles from './styles'
import ButtonItem from './ButtonItem'
import AdminNavItems from '../Administrator/NavItems/AdminNavItems'
import RemoteTeacherNavItems from '../RemoteTeacher/NavItems/RemoteTeacherNavItems'
import UserAvatar from './UserAvatar'

const NavMenu: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = () => dispatch(doLogout(history))

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Toolbar classes={{
        root: classes.drawerToolbar
      }}>
        <Typography variant="h6" noWrap>
          <img alt="Anahí" src={logo} />
        </Typography>
      </Toolbar>

      <div className={classes.scrollable}>
        <UserAvatar />
        <Divider />
        <List>
          <AdminNavItems />
          <RemoteTeacherNavItems />
          <ButtonItem
            title='Cerrar sesión'
            icon='arrow_back'
            onClick={logout}
          />
        </List>
      </div>
    </Drawer>

  )
}

export default NavMenu



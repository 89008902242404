import React from 'react';
import NonSchoolDay, { NonSchoolDayInput } from '../../models/NonSchoolDay';
import Pagination from '../../models/Pagination';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import ColoredTableRow from '../ColoredTableRow';
import moment from 'moment';
import { compose, prop, sortBy } from 'ramda';

const prettyDate = (day: number, month: number) => { //Date in "05/11" format
  if (!day || !month) throw new Error("Day or month are not valid")
  return moment().set('month', month - 1).set('date', day).format('DD/MM')
}

export const sortByImportantDayDate = <T,>(xs: T[], toDay: (x: T) => (NonSchoolDayInput | NonSchoolDay)): T[] => 
  sortBy(compose(importantDayToDate, toDay))(xs)

const sortRowsByDate = (rows: Row[]): Row[] => sortByImportantDayDate(rows, prop('data'))

export const importantDayToDate = (day: (NonSchoolDay | NonSchoolDayInput)): Date => {
  return new Date(0, day.month -1, day.day)
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 5
    },
    paper: {
      marginBottom: 64 + theme.spacing(4)
    },
    progress: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
    statusCell: {
      textAlign: 'center'
    }
  }),
);

export interface Row {
  data: (NonSchoolDay | NonSchoolDayInput);
  action?: 'create' | 'update' | 'error';
}

interface Props {
  rows: Row[];
  loading: boolean;
  showEditButton?: boolean;
  onClickEdit?: (index: number) => void;
  pagination?: Pagination;
  onChangePage?: (page: number) => void;
  showInfoButton?: boolean;
  onClickInfo?: (index: number) => void;
}

const NonSchoolDayListTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const showEditButton = props.showEditButton || false;
  const showInfoButton = props.showInfoButton || false;

  const mapActionToColor = (action?: string) => {
    switch (action) {
      case 'create': return 'green';
      case 'update': return 'yellow';
      case 'error': return 'red';
      default: return 'default';
    }
  }

  return (
    <Paper square className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow selected={true}>
            <TableCell>Fecha</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Tipo</TableCell>
            {showEditButton && <TableCell />}
            {showInfoButton && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortRowsByDate(props.rows).map((row, index) =>
            <ColoredTableRow key={index} color={mapActionToColor(row.action)}>
              <TableCell>{prettyDate(row.data.day, row.data.month)}</TableCell>
              <TableCell>{row.data.description}</TableCell>
              <TableCell>{row.data.type}</TableCell>
              {showEditButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickEdit && props.onClickEdit(index)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
              {showInfoButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickInfo && props.onClickInfo(index)}
                  >
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
            </ColoredTableRow>
          )}
        </TableBody>
      </Table>
      {props.loading &&
        <div className={classes.progress}>
          <CircularProgress size={100} />
        </div>
      }
      {props.pagination &&
        <TablePagination
          component="div"
          page={props.pagination.page}
          count={props.pagination.count}
          rowsPerPage={props.pagination.size}
          rowsPerPageOptions={[props.pagination.size]}
          onChangePage={(e, page) => props.onChangePage && props.onChangePage(page < 0 ? 0 : page)}
          labelDisplayedRows={info => `${info.from}-${info.to} de ${info.count}`}
        />
      }
    </Paper>
  );
}

export default NonSchoolDayListTable
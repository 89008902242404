import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import { BackButton } from '../Utils/BackButton'
import Assignation, { statusToDescription, getStatusColor } from '../../../models/Assignation'
import { AssignationDetailProps } from './index'
import { useStyles } from './styles'

interface AssignationHeaderProp extends AssignationDetailProps {
  assignation: Assignation
}
const AssignationHeader: React.FC<AssignationHeaderProp> = ({ assignation, getStatusSubtitle, getStatusButtons }) => {
  const classes = useStyles()

  const { status } = assignation

  return (<>
    <BackButton />
    <Paper square className={classes.header}>
      <div className={classes.statusHeader} style={{ backgroundColor: getStatusColor(status) }}>
        <Typography variant="subtitle1" className={classes.statusTitle}>
          {`${statusToDescription(status, 0)} (${statusToDescription(status, 1)})`}
        </Typography>
      </div>
      {getStatusSubtitle &&
        <div className={classes.statusBody}>
          <Typography variant="subtitle1" className={classes.statusDescription}>
            {getStatusSubtitle(status)}
          </Typography>
          {getStatusButtons}
        </div>
      }
    </Paper>
  </>)
}


export default AssignationHeader
import React from 'react'
import School from '../../../models/School'
import EntityDetails from '../Utils/EntityDetails'

const AssignationSchool: React.FC<School> = (school) => (
  <EntityDetails
    title={`Escuela número ${school.number}`}
    entity={school}
    rows={[
      ['RUEE', 'rue'],
      ['Departamento', 'department'],
      ['Turno', 'shiftName'],
      ['Horario', 'shiftTimes'],
      ['Teléfono', 'fullPhoneNumber'],
      ['Email', 'email'],
    ]}
  />
)

export default AssignationSchool
import SchoolJabberUser from './SchoolJabberUser'
import SchoolContact from './SchoolContact'
import { literalStrings } from '../utils/StringUtils'

export default interface School {
  id: number
  rue: string
  department: string
  number: string
  area: string
  zone: string
  category: string
  shiftName: string
  shiftTimes: string
  name: string
  streetName: string
  streetNumber: string
  locality: string
  phoneNumber: string
  fullPhoneNumber: string
  email: string
  share: string
  jabberUsers: SchoolJabberUser[]
  contacts: SchoolContact[]
}

export interface SchoolInput {
  rue?: string
  department?: string
  number?: string
  area?: string
  zone?: string
  category?: string
  shiftName?: string
  shiftTimes?: string
  name?: string
  streetName?: string
  streetNumber?: string
  locality?: string
  phoneNumber?: string
  email?: string
  share?: string
}

export interface SchoolResult {
  record: (School|SchoolInput)
  action: string
  success: boolean
  error?: string
}

export type Department = typeof departments[number]
export const departments = literalStrings(
  'ARTIGAS',
  'CANELONES',
  'CERRO LARGO',
  'COLONIA',
  'DURAZNO',
  'FLORES',
  'FLORIDA',
  'LAVALLEJA',
  'MALDONADO',
  'MONTEVIDEO',
  'PAYSANDÚ',
  'RÍO NEGRO',
  'RIVERA',
  'ROCHA',
  'SALTO',
  'SAN JOSÉ',
  'SORIANO',
  'TACUAREMBÓ',
  'TREINTA Y TRES'
  )
import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@material-ui/core';
import { useStyles } from './styles';

import { AppState } from '../../../reducers';
import { AssignationBulkUpsertState } from '../../../reducers/assignation/assignationBulkUpsert';
import { bulkUpsertAssignations } from '../../../actions/assignation/assignationBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import AssignationInputListTable from '../../../components/Assignation/AssignationInputListTable';
import AssignationListTable from '../../../components/Assignation/AssignationListTable';
import AssignationInputEditDialog from '../../../components/Assignation/AssignationInputEditDialog';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';

import { AssignationInput, AssignationStatus } from '../../../models/Assignation';
import { formatTime, slotNumber, dayNumber } from '../../../models/AvailabilitySlot';
import { statusToDescription } from '../../../models/Assignation';
import { ADMIN } from '../../../models/User';


interface Props {
  assignationBulkUpsert: AssignationBulkUpsertState;
}

const PAGE_SIZE = 10;

const CSV_FIELDS = [
  { name: 'groupId', display: 'ID GRUPO' },
  { name: 'remoteTeacherFullName', display: 'DR' },
  { name: 'schoolRue', display: 'RUEE' },
  { name: 'code', display: 'Codigo Empresa' },
  { name: 'schoolDepartment', display: 'Departamento' },
  { name: 'type', display: 'Tipo Centro' },
  { name: 'schoolNumber', display: 'Centro Ed.' },
  { name: 'schoolShiftName', display: 'Turno' },
  { name: 'grade', display: 'Grado' },
  { name: 'class', display: 'Clase' },
  { name: 'orientation', display: 'Orientación' },
  { name: 'classroomTeacherDocumentNumber', display: 'CI Maestro' },
  { name: 'classroomTeacherFirstName', display: 'Nombre Maestro' },
  { name: 'classroomTeacherLastName', display: 'Apellido Maestro' },
  { name: 'classroomTeacherEmail', display: 'Mail Maestro' },
  { name: 'classroomTeacherPhoneNumber', display: 'Tel Maestro' },
  { name: 'ingressDate', display: 'Fecha Ingreso' },
  { name: 'capacityFaceToFace', display: 'Capac. Presencial' },
  { name: 'capacityOnline', display: 'Capac. Online' },
  { name: 'institute', display: 'Instituto Adjudicado' },
  { name: 'coordinatorFullName', display: 'Nombre Coordinador' },
  { name: 'coordinatorDocumentNumber', display: 'ID Coordinador' },
  { name: 'remoteTeacherFirstName', display: 'Nombre Profesor' },
  { name: 'remoteTeacherLastName', display: 'Apellido Profesor' },
  { name: 'remoteTeacherEmail', display: 'Mail Profesor' },
  { name: 'remoteTeacherDocumentNumber', display: 'Profesor Identificador' },
  { name: 'mentorFullName', display: 'Mentor' },
  { name: 'mentorDocumentNumber', display: 'Documento Mentor' },
  { name: 'groupLevel', display: 'Nivel Grupo' },
  { name: 'dayOfWeek', display: 'Dia' },
  { name: 'sinceTime', display: 'Hora' },
  { name: 'subject', display: 'Materia' },
  { name: 'firstClassDate', display: 'Comienzo' }
];

const AssignationCsvCreation: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [assignations, setAssignations] = useState<AssignationInput[]>([]);
  const [selected, setSelected] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);

  const [initialStatus, setInitialStatus] = useState<AssignationStatus>('school-notification-pending');
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  const handleUpload = (records: any[]) => {
    setAssignations(records.map(r => ({
      grade: r.grade + r.class,
      dayOfWeek: dayNumber(r.dayOfWeek),
      slotTime: slotNumber(moment(r.sinceTime, 'H:mm:ss')),
      sinceTime: formatTime(moment(r.sinceTime, 'H:mm:ss')),
      untilTime: formatTime(moment(r.sinceTime, 'H:mm:ss').add(45, 'minutes')),
      firstClassDate: moment(r.firstClassDate, 'DD/MM/YYYY').toDate(),
      school: {
        rue: r.schoolRue,
        department: r.schoolDepartment,
        number: r.schoolNumber,
        shiftName: r.schoolShiftName,
        shiftTimes: '',
        area: '',
        zone: '',
        category: '',
        name: '',
        streetName: '',
        streetNumber: '',
        locality: '',
        phoneNumber: '',
        email: '',
        share: ''
      },
      remoteTeacher: {
        documentNumber: r.remoteTeacherDocumentNumber
      },
      classroomTeacher: {
        firstName: r.classroomTeacherFirstName,
        lastName: r.classroomTeacherLastName,
        phoneNumber: r.classroomTeacherPhoneNumber,
        email: r.classroomTeacherEmail,
        documentNumber: r.classroomTeacherDocumentNumber
      }
    })));
    setStep(1);
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertAssignations(assignations, initialStatus, forceUpdate));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const handleFinish = () => {
    history.push('/admin/assignations', { title: 'Assignaciones' });
  }

  const handleUpdateAssignation = (assignation: AssignationInput) => {
    setAssignations(assignations.map((s, i) => i === selected ? assignation : s));
    setSelected(null);
  }

  const getAlertTitle = () => {
    if (selected !== null) {
      switch ((props.assignationBulkUpsert.results || [])[selected].action) {
        case 'create':
          return 'Creada correctamente';
        case 'update':
          return 'Actualizada correctamente';
        case 'error':
          return 'Error';
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  const getAlertMessage = () => {
    if (selected !== null) {
      switch ((props.assignationBulkUpsert.results || [])[selected].action) {
        case 'error':
          return (props.assignationBulkUpsert.results || [])[selected].error;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <BulkUpsertUpload
          fields={CSV_FIELDS}
          onSubmit={handleUpload}
        />
      }

      {step === 1 &&
        <BulkUpsertConfirm
          tableComponent={
            <div>
              <div className={classes.formContainer}>
                <FormControl variant="filled" className={classes.formInputLeft}>
                  <InputLabel>Estado inicial</InputLabel>
                  <Select onChange={evt => setInitialStatus(evt.target.value as AssignationStatus)} defaultValue={initialStatus}>
                    <MenuItem value="school-notification-pending">{statusToDescription('school-notification-pending')}</MenuItem>
                    <MenuItem value="remote-teacher-notification-pending">{statusToDescription('remote-teacher-notification-pending')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.formInputRight}>
                  <InputLabel>Actualizar existentes</InputLabel>
                  <Select onChange={evt => setForceUpdate(evt.target.value === "true" ? true : false)} defaultValue={forceUpdate ? "true" : "false"}>
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Si</MenuItem>
                  </Select>
                  {forceUpdate &&
                    <FormHelperText>Se modificarán los datos de las asignaciones existentes pero no su estado</FormHelperText>
                  }
                </FormControl>

              </div>
              <AssignationInputListTable
                loading={false}
                rows={
                  assignations
                    .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                    .map(s => ({ data: s }))
                }
                pagination={{ size: PAGE_SIZE, count: assignations.length, page }}
                onClickEdit={(s, i) => setSelected((page * PAGE_SIZE) + i)}
                onChangePage={setPage}
              />
            </div>
          }
          onSubmit={handleConfirm}
        />
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <AssignationListTable
              loading={props.assignationBulkUpsert.isFetching}
              showInfoButton={true}
              rows={
                (props.assignationBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(r => ({ data: r.record, action: r.action as any }))
              }
              pagination={{ size: PAGE_SIZE, count: (props.assignationBulkUpsert.results || []).length, page }}
              onClickInfo={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
              role={ADMIN}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <AssignationInputEditDialog
          assignation={assignations[selected]}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateAssignation}
        />
      }

      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  assignationBulkUpsert: state.assignationBulkUpsert
})

export default connect(mapStateToProps, null)(AssignationCsvCreation)

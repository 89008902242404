import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AppState } from '../../../reducers'
import ClassListTable from '../../../components/ClassListTable/ClassListTable'
import { FiltersBar, FilterElement } from '../Utils/Filters/Filter'
import { FilterDepartment, FilterClassStatus, FilterFromDate, FilterToDate, FilterWeekday, FilterGrade, FilterSchoolNumber, WeekFilterButtonsBar } from '../Utils/Filters'
import { Filters } from '../Utils/Filters/Filter'
import { ClassTableColumn } from '../../../components/ClassListTable/ClassListColumns'
import { fetchPlatforms } from '../../../actions/platforms'
import { fetchProposals } from '../../../actions/proposals'

const mapStateToProps = ({ classList }: AppState) => ({ classList })

interface ClassesListProps {
  children: FilterElement[] | FilterElement
  columns: ClassTableColumn[]
}
const ClassesList: FC<ReturnType<typeof mapStateToProps> & ClassesListProps> = ({ classList, children, columns }) => {
  
  const dispatch = useDispatch()

  /* This has nothing to do with this component, yet is needed here for the platforms and proposals to be
  fetched once and not as many times as rows in the table */
  useEffect(() => {
    dispatch(fetchPlatforms())
    dispatch(fetchProposals())
  }, [dispatch])

  return (
    <div>
    <FiltersBar defaultFilters={defaultFilters}>
      {children as any}
      <FilterClassStatus multiple/>
      <FilterWeekday />
      <FilterFromDate />
      <FilterToDate />
      <FilterDepartment />
      <FilterSchoolNumber />
      <FilterGrade />
      <WeekFilterButtonsBar />
    </FiltersBar>

    <ClassListTable
      columns={columns}
      classes={classList.classes || []}
      loading={classList.isFetching}
      pagination={classList.pagination}
    />
  </div>
  )
}

export const defaultFilters : Filters = {
  from: moment().startOf('week').format(),
  to: moment().endOf('week').format(),
}

// TODO: Make connectApp function
export default connect<ReturnType<typeof mapStateToProps>, {}, ClassesListProps, AppState>(mapStateToProps)(ClassesList)
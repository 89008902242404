import React, { useState } from 'react'
import { Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AnahiDialog from './AnahiDialog'

interface EditButtonDialogProps {
  title: string
  onAccept: () => void
  onDismiss?: () => void
  disabled?: boolean
}

const EditButtonDialog: React.FC<EditButtonDialogProps> = ({ title, onAccept, onDismiss = () => {}, disabled, children }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => { setOpen(true) }

  const handleDismiss = () => {
    onDismiss()
    setOpen(false)
  }

  const handleAccept = () => { 
    onAccept()
    setOpen(false)
  }

  return (<>
    <IconButton aria-label="edit" size="small" onClick={handleOpen}>
      <EditIcon fontSize="small" />
    </IconButton>

    <AnahiDialog
      open={open}
      title={title}
      onClose={handleDismiss}
      onSubmit={handleAccept}
      body={children}
      actions={<>
        <Button color="primary" type="submit" disabled={disabled}>
          Aceptar
        </Button>
        <Button color="secondary" onClick={handleDismiss}>
          Cancelar
        </Button>
      </>}
    />
  </>)
}

export default EditButtonDialog
import { Button } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import AnahiDialog from './AnahiDialog'

interface AnahiDialogButtonProps {
  buttonText?: string
  dialogTitle?: string
  dialogBody?: React.ReactNode
  dialogActions?: (onClose: () => void) => React.ReactNode
  onSubmit?: () => void
}

export const AnahiDialogButton: React.FC<AnahiDialogButtonProps> = (props: AnahiDialogButtonProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const onClose = () => setShowDialog(false)


  return (<>
    <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}>
      {props.buttonText}
    </Button>

    <AnahiDialog
      open={showDialog}
      onClose={onClose}
      title={props.dialogTitle}
      body={props.dialogBody}
      actions={props.dialogActions ? props.dialogActions(onClose) : <></>}
      onSubmit={props.onSubmit} />
  </>)
}
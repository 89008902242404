import Assignation from './Assignation'
import User from './User'
import Teacher from './Teacher'

export interface RemoteTeacherInput {
  firstName: string
  lastName: string
  phoneNumber: string
  documentNumber: string
  province: string
  batch: string
  email: string
}

export interface RemoteTeacherResult {
  record: (RemoteTeacher | RemoteTeacherInput)
  action: string
  success: boolean
  error?: string
}

export default class RemoteTeacher extends Teacher {

  province!: string
  batch!: string
  user?: User | undefined
  slots?: any[] | undefined
  assignation?: Assignation | undefined

}
export enum ImportantDayType {
  NonSchoolDay = "Feriado",
  VacationDay = "Vacaciones",
  Other = ""
}

export default interface NonSchoolDay {
  id: number;
  day: number;
  month: number;
  type: ImportantDayType;
  description: string;
}

export interface NonSchoolDayInput {
  day: number;
  month: number;
  description: string;
  type: ImportantDayType;
}

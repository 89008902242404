import { Dispatch } from 'react';
import { AppState } from '../reducers/index';

type CombinableAction = (dispatch: Dispatch<any>, getState: () => AppState) => void | Promise<any>

const sequenceActions = (...actions: CombinableAction[]): CombinableAction =>
  (dispatch: Dispatch<any>, getState: () => AppState) =>
    actions.forEach(cb => cb(dispatch, getState))

export const volatilAction = (...actions: CombinableAction[]): CombinableAction =>
  sequenceActions(
    (dispatch) => dispatch({ type: "CLEAR" }),
    ...actions
  )

export default sequenceActions
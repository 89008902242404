import React from 'react'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import logo from '../../../images/anahi-logo-01.png'

const AnahiCard: React.FC = ({ children }) => {
  const classes = useStyles()

  return (<div className={classes.root}>
    <Container>
      <Grid container justify="center">
        <Grid item md={4} sm={8} xs={12}>
          <Typography align="center" variant="h4">
            <img alt="Anahí" src={logo} />
          </Typography>
          <Paper square className={classes.paper}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  </div>
  )
}

export default AnahiCard;
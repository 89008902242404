import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import SchoolContact from '../../models/SchoolContact';


export interface SchoolContactDetailState extends ApiState {
  contact?: SchoolContact
}

export default requestReducer<SchoolContactDetailState>('SCHOOL_CONTACT_DETAIL')

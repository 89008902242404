import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { SessionState } from '../../reducers/auth/session'
import Typography from '@material-ui/core/Typography'
import AdminWrapper from '../Administrator/AdminWrapper'
import RemoteTeacherWrapper from '../RemoteTeacher/RemoteTeacherWrapper'
import useStyles from './styles'

interface Props {
  session?: SessionState
}

const UserAvatar: React.FC<Props> = ({ session }) => {
  const classes = useStyles()
  return (
    <div className={classes.profileBox}>
      <Typography variant="subtitle1" noWrap>
        <AdminWrapper>Administrador</AdminWrapper>
        <RemoteTeacherWrapper>{session?.fullName}</RemoteTeacherWrapper>
      </Typography>
      <Typography variant="subtitle2" noWrap>
        {session?.email}
      </Typography>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  session: state.session
})

export default connect(mapStateToProps, null)(UserAvatar)



import React, { useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { AppState } from '../../../reducers/index'
import { updateAssignation } from '../../../actions/assignation/assignationUpdate'
import { confirmAssignation } from '../../../actions/assignation/assignationConfirm'
import Assignation, { AssignationStatus } from '../../../models/Assignation'
import { Button } from '@material-ui/core'
import AssignationDetail from '../../components/AssignationDetail'
import { useStyles } from '../../components/AssignationDetail/styles'
import ConfirmDialog from '../../components/Utils/Dialogs/ConfirmDialog'

export default () =>
  <AssignationDetail
    getStatusButtons={<StatusButtonsWrapped />}
    getStatusSubtitle={getStatusSubtitle} />

const StatusButtons: React.FC<Assignation> = (assignation) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { id, status } = assignation

  const handleUpdateStatus = (status: AssignationStatus) => {
    dispatch(updateAssignation({ ...assignation, status }, 'Estado actualizado'))
  }

  const onConfirm = () => {
    dispatch(confirmAssignation(id))
    closeDialog()
  }

  const [confirm, setConfirm] = useState<boolean>(false)
  const closeDialog = () => setConfirm(false)

  switch (status) {
    case 'remote-teacher-confirmation-pending':
      return (
        <div className={classes.statusButtons}>
          <Button
            className={classes.rejectButton}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleUpdateStatus('remote-teacher-re-assignation-pending')}
          >
            Rechazar
            </Button>
          <Button
            className={classes.successButton}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleUpdateStatus('classroom-teacher-confirmation-pending')}
          >
            Confirmar
            </Button>
        </div>
      )
    case 'classroom-teacher-confirmation-pending':
      return (
        <div className={classes.statusButtons}>
          <Button
            className={classes.successButton}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setConfirm(true)}
          >
            Docente de Aula Confirma
            </Button>
          {
            confirm &&
            <ConfirmDialog
              title="Confirmar Docente de Aula"
              message="¿Hablaste con la/el Docente de Aula? Confirmá hora, día y fecha de inicio de clases. Por favor, solamente aceptá cuando hayan hablado y el primer día de clase sea el indicado."
              onConfirm={onConfirm}
              onDismiss={closeDialog}
            />
          }
        </div>
      )
    default:
      return null
  }
}

const mapStateToProps = ({ assignationDetail }: AppState) => ({ ...assignationDetail.assignation! })
const StatusButtonsWrapped = connect<Assignation, {}, {}, AppState>(mapStateToProps)(StatusButtons)


const getStatusSubtitle = (status: AssignationStatus) => {
  switch (status) {
    case 'remote-teacher-confirmation-pending':
      return 'Tenés que confirmar o rechazar la asignación para continuar con el proceso.'
    case 'remote-teacher-re-assignation-pending':
      return 'Rechazaste la asignación. Buscarán un nuevo DR.'
    case 'classroom-teacher-confirmation-pending':
      return 'Tenés que contactarte con el docente de aula para confirmar el día y horario de la asignación.'
    case 'unified':
      return 'Este grupo ya se encuentra unificado a otro.'
    case 'assigned':
      return 'La asignación ya se encuentra asignada y ya tenés tus clases para este grupo.'
    case 'dropped':
      return 'La asignación ha sido dada de baja.'
    default:
      return null
  }
}
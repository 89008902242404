import moment, { Moment } from 'moment'
import { any, equals, or } from 'ramda'
import NonSchoolDay, { ImportantDayType } from '../models/NonSchoolDay'
import { literalStrings } from './StringUtils'

export enum Weekday { // Same convention than in Moment.ts
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export type Day = typeof days[number]

export const days = literalStrings(
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
)

export const weekdayToDescription = (day: number) => days[day - 1]

export const startOfTheWeek = () => moment().isoWeekday(Weekday.Monday).startOf('day')

export const endOfTheWeek = () => moment().isoWeekday(Weekday.Friday).startOf('day').add(17, 'hours').add(31, 'minutes')

export const isInThisWeek = (date?: Moment | Date | string): boolean => moment(date).isBetween(startOfTheWeek(), endOfTheWeek(), undefined, '[)')

export const isBeforeThisWeek = (date?: Moment | Date | string): boolean => moment(date).isBefore(startOfTheWeek())

export const isSameOrAfterWeekend = (date?: Moment | Date | string): boolean => moment(date).isSameOrAfter(endOfTheWeek())

export const isInFuture = (date?: Moment | Date | string): boolean => moment(date).isAfter(moment())

export const dateToString = (date: Moment): string => date.format("YYYY-MM-DDTHH:mm:ssZ")

export const isOnWeekend = (date: Moment): boolean => or(equals(date.isoWeekday(), Weekday.Saturday), equals(date.isoWeekday(), Weekday.Sunday))

const formatDateWithTimezone = (date: Moment, time: string): Moment => moment(date.format("YYYY-MM-DD") + `T${time}-03:00`)

//if valid school time changes, remember to also change it on the backend
export const isValidSchoolTime = (date: Moment): boolean => { 
  const dayStart = formatDateWithTimezone(date, "08:00")
  const dayEnd = formatDateWithTimezone(date, "17:00")
  return date.isBetween(dayStart, dayEnd, undefined, '[]')
}

export const sameDayThan = (date?: Moment | Date | string, another?: Moment | Date | string): boolean => equals(moment(date).date(), moment(another).date())

export const sameMonthThan = (date?: Moment | Date | string, another?: Moment | Date | string): boolean => equals(moment(date).month(), moment(another).month())

export const onInvalidDateForClassCreation = (date: Moment, importantDays: NonSchoolDay[]): boolean => 
  any((importantDay) => sameDate(date, toMoment(importantDay)) && isInvalidForClassCreation(importantDay), importantDays)

const sameDate = (date: Moment, another: Moment): boolean => sameDayThan(date, another) && sameMonthThan(date, another)

const isInvalidForClassCreation = (day: NonSchoolDay): boolean => day.type === ImportantDayType.NonSchoolDay || day.type === ImportantDayType.VacationDay

const toMoment = ({ day, month }: NonSchoolDay): Moment => 
  moment(`${month}-${day}-${moment().year()}`, 'MM-DD-YYYY hh:mm A Z')
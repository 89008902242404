import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { ImportantDayResult, NonSchoolDayBulkUpsertState } from '../../../reducers/nonSchoolDay/nonSchoolDayBulkUpsert';
import { bulkUpsertDays } from '../../../actions/nonSchoolDay/nonSchoolDayBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload, { FieldType } from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import NonSchoolDayListTable, { sortByImportantDayDate } from '../../../components/NonSchoolDay/NonSchoolDayListTable';
import NonSchoolDayEditDialog from '../../../components/NonSchoolDay/NonSchoolDayEditDialog';

import { ImportantDayType, NonSchoolDayInput } from '../../../models/NonSchoolDay';
import InfoCard from '../../components/Utils/InfoCard';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';
import { identity, prop } from 'ramda';


interface Props {
  dayBulkUpsert: NonSchoolDayBulkUpsertState;
}

const PAGE_SIZE = 10;

const CSV_FIELDS = [
  { name: 'day', display: 'Día', type: FieldType.Number },
  { name: 'month', display: 'Mes', type: FieldType.Number },
  { name: 'description', display: 'Descripción', type: FieldType.String },
  { name: 'type', display: 'Tipo', type: FieldType.String}
];

const NonSchoolDayCsvCreation: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [days, setDays] = useState<NonSchoolDayInput[]>([]);
  const [selected, setSelected] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);

  const handleUpload = (records: any[]) => {
    setDays((records as NonSchoolDayInput[]).filter(r => r.day).map(sanitizeInput));
    setStep(1);
  }

  const sanitizeInput = (input: NonSchoolDayInput): NonSchoolDayInput => {
    return input.type ? input : {...input, type: ImportantDayType.Other} //An empty csv field is parsed as undefined, so we need to change it to Other.
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertDays(days));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const getAlertTitle = (): string => selectedResultDay()?.success ? 'Creado correctamente' : 'Error'

  const getAlertMessage = (): string | undefined => selectedResultDay()?.error

  const sortedDaysByDate = (): NonSchoolDayInput[] => sortByImportantDayDate(days, identity)
  const sortedResultsByDate = (): ImportantDayResult[] => sortByImportantDayDate(props.dayBulkUpsert.results || [], prop('record'))

  const getSelectedBy = <T,>(sortBy: () => T[]): T | undefined => selected !== null ? sortBy()[selected] : undefined

  //The selection should be sorted by date because the important days table is sorted
  const selectedInputDay = (): NonSchoolDayInput | undefined => getSelectedBy(sortedDaysByDate)
  const selectedResultDay = (): ImportantDayResult | undefined => getSelectedBy(sortedResultsByDate)
  

  const handleFinish = () => {
    history.push('/admin/non-school-days', { title: 'Feriados' });
  }

  const handleUpdateDay = (day: NonSchoolDayInput) => {
    setDays(sortedDaysByDate().map((s, i) => i === selected ? day : s));
    setSelected(null);
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <div>
          <InfoCard>
          <span role="img" aria-label="warning">⚠️</span> En el caso de la subida por CSV de fechas imporantes, <b>se eliminarán todas las fechas existentes y se reemplazarán por las del CSV.</b>
          </InfoCard>

          <ClassesModificationInfo/>

          <BulkUpsertUpload
            fields={CSV_FIELDS}
            onSubmit={handleUpload}
          />
        </div>
      }

      {step === 1 &&
      <>
      <ClassesModificationInfo/>
      <BulkUpsertConfirm
        tableComponent={
          <NonSchoolDayListTable
            loading={false}
            showEditButton={true}
            rows={
              days
                .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                .map(s => ({ data: s }))
            }
            pagination={{ size: PAGE_SIZE, count: days.length, page }}
            onClickEdit={(index) => setSelected((page * PAGE_SIZE) + index)}
            onChangePage={setPage}
          />
        }
        onSubmit={handleConfirm}
      />
      </>
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <NonSchoolDayListTable
              loading={props.dayBulkUpsert.isFetching}
              showInfoButton={true}
              rows={
                (props.dayBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(rec => ({ data: rec.record, action: rec.action}))
              }
              pagination={{ size: PAGE_SIZE, count: (props.dayBulkUpsert.results || []).length, page }}
              onClickInfo={setSelected}
              onChangePage={setPage}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <NonSchoolDayEditDialog
          day={selectedInputDay()!}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateDay}
        />
      }

      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }   

    </div>
  );
}


const ClassesModificationInfo = () => (
<InfoCard>
  Al subir un CSV de fechas importantes se hacen automaticamente las siguientes <b>modificaciones sobre las clases</b> segun el cambio de fecha:
  <ul>
     <li>Si cambia de Feriado a día normal --  se cambia el status de las clases a Pendiente.</li>
     <li>Si cambia de Feriado a Vacaciones -- <b>se eliminan las clases</b></li>
     <li>Si cambia de día normal a Feriado -- se cambia el status de las clases a "Cancelada por al escuela CON anticipación"</li>
     <li>Si cambia de día normal a Vacaciones -- <b>se eliminan las clases</b></li>
     <li>Si cambia de Vacaciones a día normal -- se crean las clases con status "Pendiente"</li>
     <li>Si cambia de Vacaciones a Feriado -- se crean las clases con status "Cancelada por la escuela CON anticipación"</li>
  </ul>
 </InfoCard>
)


const mapStateToProps = (state: AppState) => ({
  dayBulkUpsert: state.nonSchoolDayBulkUpsert
})

export default connect(mapStateToProps, null)(NonSchoolDayCsvCreation)
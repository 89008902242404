import React from 'react';
import PersonalInformation from './PersonalInformation';
import Credentials from './Credentials';

const Profile: React.FC = () => {
  return (
  <div>
    <PersonalInformation/>
    <Credentials/>
  </div>
  )
}

export default Profile


import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AppState } from '../../../reducers/index'
import { updateAssignation } from '../../../actions/assignation/assignationUpdate'
import { confirmAssignation } from '../../../actions/assignation/assignationConfirm'
import Assignation, { AssignationStatus, assignationStatusses, statusToDescription } from '../../../models/Assignation'
import { FormControlLabel } from '@material-ui/core'
import AssignationDetail from '../../components/AssignationDetail'
import DropdownButton from '../../components/Utils/DropdownButton'
import WarningSwitch from './WarningSwitch'
import { useStyles } from './styles'
import ConfirmDialog from '../../components/Utils/Dialogs/ConfirmDialog'

export default () =>
  <AssignationDetail
    getStatusButtons={<StatusButtonsWrapped />}
    getStatusSubtitle={getStatusSubtitle} />


const StatusButtons: React.FC<Assignation> = (assignation) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { id, status, shouldSendToCeibal } = assignation

  const updateStatus = (status: AssignationStatus) => {
    dispatch(updateAssignation({ ...assignation, status }, 'Estado actualizado'))
  }

  const updateShouldSendToCeibal = (shouldSendToCeibal: boolean) => {
    dispatch(updateAssignation({ ...assignation, shouldSendToCeibal }, 'Actualizado.'))
  }

  const assignAssignation = () => {
    dispatch(confirmAssignation(id))
  }

  const updateNewStatus = (newStatus: AssignationStatus) => {
    if (newStatus === 'assigned') //TODO: Este if no debería estar en el backend?
      assignAssignation()
    else
      updateStatus(newStatus)
  }

  const onChange = (newStatus: AssignationStatus) => {
    if (status === 'assigned' || newStatus === 'assigned')
      setConfirmNewState(newStatus)
    else
      updateNewStatus(newStatus)
  }

  const onChangeCeibal = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateShouldSendToCeibal(!event.target.checked)
  }


  //TODO: componetizar
  const [confirmNewState, setConfirmNewState] = useState<AssignationStatus>()
  const closeDialog = () => setConfirmNewState(undefined)

  return (<>
    <DropdownButton<AssignationStatus>
      label="Cambiar estado"
      options={assignationStatusses}
      selected={status}
      onChange={onChange}
      map={(status) => statusToDescription(status)}
    />
    <FormControlLabel
      control={
        <WarningSwitch
          className={classes.switch}
          checked={!shouldSendToCeibal}
          onChange={onChangeCeibal}
          value="shouldSendToCeibal"
          color="primary"
        />
      }
      label="En Ceibal"
    />
    {
      confirmNewState &&
      <ConfirmDialog
        title="Confirmar cambio de estado"
        message={status === 'assigned' ? "Desasignar un grupo hará que se borren todas las clases existentes." : "Asignar un grupo creará todas las clases correspondientes"} 
        onConfirm={() => { updateNewStatus(confirmNewState); closeDialog() }}
        onDismiss={closeDialog}
      />
    }
  </>
  )
}

const mapStateToProps = ({ assignationDetail }: AppState) => ({ ...assignationDetail.assignation! })
const StatusButtonsWrapped = connect<Assignation, {}, {}, AppState>(mapStateToProps)(StatusButtons)


const getStatusSubtitle = (status: AssignationStatus) => {
  switch (status) {
    case 'assignation-pending':
      return 'Se debe asignar un docente remoto.'
    case 'school-notification-pending':
      return 'Se debe notificar a la escuela.'
    case 'school-confirmation-pending':
      return 'Se debe comunicar con la escuela para confirmar fecha y horario.'
    case 'remote-teacher-notification-pending':
      return 'Se debe enviar la notificación al docente remoto.'
    case 'school-reschedule-pending':
      return 'La escuela no ha confirmado la asignación.'
    case 'remote-teacher-confirmation-pending':
      return 'El DR debe confirmar o rechazar la asignación.'
    case 'remote-teacher-re-assignation-pending':
      return 'Se debe reasignar un DR.'
    case 'classroom-teacher-confirmation-pending':
      return 'El DR debe contactarse con el docente de aula para confirmar el día y horario de la asignación.'
    case 'unified':
      return 'Este grupo ya se encuentra unificado a otro.'
    case 'assigned':
      return 'El grupo ya se encuentra asignado y las clases creadas.'
    case 'dropped':
      return 'La asignación ha sido dada de baja.'
    default:
      return null
  }
}
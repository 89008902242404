import React from 'react'
import AlertDialog, { AlertDialogProps } from './AlertDialog'
import { Button } from '@material-ui/core'

interface ConfirmDialogProps extends AlertDialogProps {
  onConfirm: () => void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ onConfirm, ...props }) => (
  <AlertDialog {...props}>
    <Button color="primary" onClick={onConfirm}>
      Confirmar
      </Button>
  </AlertDialog>
)

export default ConfirmDialog

export const doLogin = (history, email, password) => {

  return async (dispatch, getState) => {

    try {

      const response = await fetch(`${window.config.apiURL}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password
        })
      }).then(r => r.json());

      if (response.error) { throw new Error(response.error) }

      window.localStorage.setItem('token', response.token);
      dispatch({ type: 'SESSION', response, requestStatus: 'FETCH' });


      // TODO: Duplicated
      if (response.roles.includes('administrator')) {
        history.push('/admin/schools', { title: 'Escuelas' });
        return;
      }

      if (response.roles.includes('remote-teacher')) {
        history.push('/teacher/dashboard', { title: 'Home' });
        return;
      }


    } catch (error) {

      dispatch({
        type: 'SESSION',
        requestStatus: 'ERROR',
        newApiState: {
          isFetching: false,
          didInvalidate: true,
          error: error
        }
      })

    }

  }

}
import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { SchoolJabberUserBulkUpsertState } from '../../../reducers/schoolJabberUser/schoolJabberUserBulkUpsert';
import { bulkUpsertUsers } from '../../../actions/schoolJabberUser/schoolJabberUserBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload, { FieldType } from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import SchoolJabberUserListTable from '../../../components/SchoolJabberUser/SchoolJabberUserListTable';
import SchoolJabberUserEditDialog from '../../../components/SchoolJabberUser/SchoolJabberUserEditDialog';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';

import { SchoolJabberUserInput } from '../../../models/SchoolJabberUser';

interface Props {
  userBulkUpsert: SchoolJabberUserBulkUpsertState;
}

const PAGE_SIZE = 10;

const CSV_FIELDS = [
  { name: 'rue', display: 'RUEE', type: FieldType.String },
  { name: 'uri1', display: 'URI', type: FieldType.String },
  { name: 'uri2', display: 'URI 2', type: FieldType.String },
  { name: 'intern', display: 'Interno', type: FieldType.String },
  { name: 'comments', display: 'Incidentes técnicos', type: FieldType.String }
];

const SchoolJabberUserCsvCreation: React.FC<Props> = (props) => {  
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [users, setUsers] = useState<SchoolJabberUserInput[]>([]);
  const [selected, setSelected] = useState<number|null>(null);
  const [page, setPage] = useState<number>(0);

  const handleUpload = (records: any[]) => {
    setUsers(records as SchoolJabberUserInput[]);
    setStep(1);
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertUsers(users));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const handleFinish = () => {
    history.push('/admin/school-jabber-users', {title: 'Usuarios jabber'});
  }

  const handleUpdateUser = (user: SchoolJabberUserInput) => {
    setUsers(users.map((s, i) => i === selected ? user : s));
    setSelected(null);
  }

  const getAlertTitle = () => {
    if (selected !== null) {
      switch ((props.userBulkUpsert.results || [])[selected].action) {
        case 'create':
          return 'Creado correctamente';
        case 'update':
          return 'Actualizado correctamente';
        case 'error':
          return 'Error';
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  const getAlertMessage = () => {
    if (selected !== null) {
      switch ((props.userBulkUpsert.results || [])[selected].action) {
        case 'error':
          return (props.userBulkUpsert.results || [])[selected].error;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <BulkUpsertUpload
          fields={CSV_FIELDS}
          onSubmit={handleUpload}
        />
      }

      {step === 1 &&
        <BulkUpsertConfirm
          tableComponent={
            <SchoolJabberUserListTable
              loading={false}
              showEditButton={true}
              rows={
                users
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(s => ({ data: s }))
              }
              pagination={{ size: PAGE_SIZE, count: users.length, page }}
              onClickEdit={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleConfirm}
        />
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <SchoolJabberUserListTable
              loading={props.userBulkUpsert.isFetching}
              showInfoButton={true}
              rows={
                (props.userBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(r => ({ data: r.record, action: r.action as any }))
              }
              pagination={{ size: PAGE_SIZE, count: (props.userBulkUpsert.results || []).length, page }}
              onClickInfo={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <SchoolJabberUserEditDialog
          user={users[selected]}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateUser}
        />
      }
      
      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  userBulkUpsert: state.schoolJabberUserBulkUpsert
})

export default connect(mapStateToProps, null)(SchoolJabberUserCsvCreation)
import React from 'react'
import SchoolJabberUser from '../../../models/SchoolJabberUser'
import EntityDetails from '../Utils/EntityDetails'

const AssignationSchoolJabberUser: React.FC<SchoolJabberUser> = (jabber) => (
  <EntityDetails
    title="Jabber"
    entity={jabber}
    rows={[
      ['Usuario', 'uri1'],
      ['Alternativo', 'uri2'],
      ['Interno', 'intern'],
    ]}
  />
)

export default AssignationSchoolJabberUser
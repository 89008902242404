import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { AssignationInput } from '../../models/Assignation';
import { isValidSchoolTime } from '../../utils/days';
import { AccessTime } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  assignation: AssignationInput;
  onDismiss: () => void;
  onSubmit: (assignation: AssignationInput) => void;
}

const AssignationInputEditDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [assignation, setAssignation] = useState<AssignationInput>(props.assignation);

  const startTime = moment(assignation.sinceTime, 'HH:mm')

  const canConfirm = (): boolean => isValidSchoolTime(startTime)

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar asignación</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="RUEE"
          margin="normal" required fullWidth
          defaultValue={assignation.school.rue}
          onChange={e => setAssignation({ ...assignation, school: { ...assignation.school, rue: e.target.value } })}
        />
        <TextField
          label="Grado"
          margin="normal" required fullWidth
          defaultValue={assignation.grade}
          onChange={e => setAssignation({ ...assignation, grade: e.target.value })}
        />
        <FormControl margin="normal" required fullWidth>
          <InputLabel>Día</InputLabel>
          <Select
            defaultValue={assignation.dayOfWeek}
            onChange={e => setAssignation({ ...assignation, dayOfWeek: e.target.value as number })}
          >
            <MenuItem value={0}>Lunes</MenuItem>
            <MenuItem value={1}>Martes</MenuItem>
            <MenuItem value={2}>Miércoles</MenuItem>
            <MenuItem value={3}>Jueves</MenuItem>
            <MenuItem value={4}>Viernes</MenuItem>
          </Select>
        </FormControl>
        <KeyboardTimePicker
          label="Hora de inicio"
          margin="normal" 
          required 
          fullWidth
          value={startTime}
          ampm={false}
          onChange={(newTime: MaterialUiPickersDate) => { if (newTime) setAssignation({ ...assignation, sinceTime: newTime.format('HH:mm') }) }}
          KeyboardButtonProps={{ 'aria-label': 'Hora de inicio' }}
          keyboardIcon={<AccessTime />}
          error={!isValidSchoolTime(startTime)}
          helperText={isValidSchoolTime(startTime) ? "" : "La hora inicial debe caer dentro del horario escolar"}
        />
        <KeyboardDatePicker
          label="Fecha de inicio"
          margin="normal"
          format="DD/MM"
          value={assignation.firstClassDate}
          onChange={(newDate: MaterialUiPickersDate) => { if (newDate) setAssignation({ ...assignation, firstClassDate: newDate.toDate() }) }}
          disableToolbar
          fullWidth
          required
          KeyboardButtonProps={{ 'aria-label': 'Fecha de inicio' }}
        />
        <TextField
          label="DR DNI"
          margin="normal" required fullWidth
          defaultValue={assignation.remoteTeacher.documentNumber}
          onChange={e => setAssignation({ ...assignation, remoteTeacher: { ...assignation.remoteTeacher, documentNumber: e.target.value } })}
        />
        <TextField
          label="DA DNI"
          margin="normal" required fullWidth
          defaultValue={assignation.classroomTeacher.documentNumber}
          onChange={e => setAssignation({ ...assignation, classroomTeacher: { ...assignation.classroomTeacher, documentNumber: e.target.value } })}
        />
        <TextField
          label="DA Nombre"
          margin="normal" required fullWidth
          defaultValue={assignation.classroomTeacher.firstName}
          onChange={e => setAssignation({ ...assignation, classroomTeacher: { ...assignation.classroomTeacher, firstName: e.target.value } })}
        />
        <TextField
          label="DA Apellido"
          margin="normal" required fullWidth
          defaultValue={assignation.classroomTeacher.lastName}
          onChange={e => setAssignation({ ...assignation, classroomTeacher: { ...assignation.classroomTeacher, lastName: e.target.value } })}
        />
        <TextField
          label="DA Email"
          margin="normal" required fullWidth
          defaultValue={assignation.classroomTeacher.email}
          onChange={e => setAssignation({ ...assignation, classroomTeacher: { ...assignation.classroomTeacher, email: e.target.value } })}
        />
        <TextField
          label="DA Teléfono (sin +598)"
          margin="normal" required fullWidth
          defaultValue={assignation.classroomTeacher.phoneNumber}
          onChange={e => setAssignation({ ...assignation, classroomTeacher: { ...assignation.classroomTeacher, phoneNumber: e.target.value } })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(assignation)}
          disabled={!canConfirm()}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignationInputEditDialog
import { AccessTime } from "@material-ui/icons"
import { KeyboardTimePicker as MaterialKeyboardTimePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import moment, { Moment } from "moment"
import { identity, not, T } from "ramda"
import React, { FC } from "react"

interface KeyboardTimePickerProps {
    ampm?: boolean
    date?: Moment
    disabled?: boolean
    errorMessage?: string
    format?: string
    hidden?: boolean
    helperText?: string
    onChange?: ((date: MaterialUiPickersDate, value?: string | null) => void) | undefined
    label?: string
    required?: boolean
    textAdapter?: ((value?: string) => string)
    validate?: (date?: Moment) => boolean
}

const KeyboardTimePicker: FC<KeyboardTimePickerProps> = ({ ampm = false, date = moment(), disabled = false, errorMessage = "La hora ingresada es invalida.",
    format, label = "", hidden = false, helperText = "", onChange, required = false, validate = T }) =>
    <div>
        {not(hidden) && <MaterialKeyboardTimePicker
            disabled={disabled}
            required={required}
            fullWidth
            margin="normal"
            inputVariant="filled"
            InputLabelProps={{ shrink: true }}
            KeyboardButtonProps={{ "aria-label": "change time" }}
            id="time-picker"
            onChange={onChange ? onChange : identity}
            label={label}
            ampm={ampm}
            helperText={validate(date) ? helperText : errorMessage}
            value={format ? date.format(format) : date}
            variant="dialog"
            okLabel="Aceptar"
            cancelLabel="Cancelar"
            keyboardIcon={<AccessTime />}
        />}
    </div>

export default KeyboardTimePicker
import React from 'react'
import Divider from '@material-ui/core/Divider'
import ReferentWrapper from '../ReferentWrapper'
import ReferentNavItem from './ReferentNavItem'
import { defaultFilters } from '../../components/ClassesList'
import { filtersToSearch } from '../../components/Utils/Filters/Filter'


const ReferentNavItems: React.FC = () =>
  <ReferentWrapper>
    <Divider />

    <ReferentNavItem
      uri='assignations'
      title='Asignaciones Referidos'
      icon='school'
    />

    <ReferentNavItem
      uri={`classes?${filtersToSearch(defaultFilters)}`}
      title='Clases Referidos'
      icon='event'
    />

    <ReferentNavItem
      uri='download'
      title='Descarga de Reportes'
      icon='get_app'
      nested
    />
  </ReferentWrapper>


export default ReferentNavItems
import React from 'react'
import SchoolContact from '../../../models/SchoolContact'
import EntityDetails from '../Utils/EntityDetails'

const AssignationSchoolContact: React.FC<SchoolContact> = (contact) => (
  <EntityDetails
    title="Contacto escolar"
    entity={contact}
    rows={[
      ['Cargo', 'position'],
      ['Nombre', 'fullName'],
      ['Teléfono', 'fullPhoneNumber'],
      ['Email', 'email'],
    ]}
  />
)

export default AssignationSchoolContact
import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import School from '../../models/School';


export interface SchoolDetailState extends ApiState {
  school?: School
}

export default requestReducer<SchoolDetailState>('SCHOOL_DETAIL')

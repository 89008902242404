import React from 'react'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, ButtonProps } from '@material-ui/core'
import { ApiState } from '../../../utils/requestAction'
import { ErrorLabel } from './ShowLabel'

interface ProgressButtonProps {
  state: ApiState,
  disabled?: boolean,
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  sendButton: {
    marginTop: theme.spacing(3),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-0.5),
    marginLeft: -12,
  },
}))


const ProgressButton: React.FC<ProgressButtonProps & ButtonProps> = ({ state, disabled = false, children, ...props }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.sendButton}
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        fullWidth
        disabled={!state.didInvalidate && (state.isFetching || disabled)}
        {...props}
      >
        {children}
      </Button>
      {state.isFetching && <CircularProgress size={24} className={classes.buttonProgress} />}
      <ErrorLabel //TODO: Usar Alert
        show={state.didInvalidate}
        message={state.error ? state.error.message : ""}
      />
    </div>
  )
}

export default ProgressButton
import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/index'
import { hasRole } from '../../../reducers/auth/session'
import { WrapperProps } from './Wrapper'
import Wrapper from './Wrapper';
import { Role } from '../../../models/User'

export type WrapperByRoleProps = Omit<WrapperProps, 'show'>

interface Props extends WrapperByRoleProps {
  role: Role
}

const WrapperByRole: React.FC<StateProps & Props> = ({ role, session, ...rest }) => (
  <Wrapper show={hasRole(session, role)} {...rest}/>
)

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: AppState) => ({
  session: state.session
})

export default connect<StateProps, {}, Props, AppState>(mapStateToProps)(WrapperByRole)



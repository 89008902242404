import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import indigo from '@material-ui/core/colors/indigo';
import { useHistory } from 'react-router-dom';
import { SessionState } from '../../../reducers/auth/session';
import { Paper, Typography, Button, Link } from '@material-ui/core';
import { AppState } from '../../../reducers/index';
import { connect, useDispatch } from 'react-redux';
import { ConfigState } from '../../../reducers/config';
import { fetchConfig } from '../../../actions/config';
import { ThumbUp } from '@material-ui/icons';
import littleAnahi from '../../../images/littleAnahi.png'
import InfoCard from '../../components/Utils/InfoCard';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  task: {
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: indigo[300],
    color: theme.palette.common.white,
  },
  card: {
    borderRadius: 0,
    padding: theme.spacing(2),
  }
}));

interface Props {
  session: SessionState;
  config: ConfigState;
}

const Dashboard: React.FC<Props> = ({ session, config }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfig);
  }, [dispatch]);

  var tasks: any[] = []

  const endOfClasses = moment(config.config?.lastClassDate);
  if (session.shouldUpdatePassword) tasks.push(
    <Task key="updatePass">
      Tenés una contraseña automática, por favor cambiala a una personal en <Button size="small" onClick={() => history.push('/teacher/profile', { title: 'Mi Perfil' })}
      >Mi Perfil</Button>.
    </Task>
  )
  if (config.config && config.config.availabilityUpdateEnabled) tasks.push(
    <Task key="updateAvailability">
      Te pedimos por favor que completes tu <Button size="small" onClick={() => history.push('/teacher/availability', { title: 'Disponibilidad Horaria' })}
      >Disponibilidad Horaria</Button>. El equipo de gestión te informará la fecha límite para realizarlo.
    </Task>
  )

  //TODO: task to confirm Assignations (whether any assignation is in "RT pending" or "CT pending")
  //TODO: task to inform Classes (whether any from last monday to today is in "Pending")

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.card}>
            <Typography variant="body1">
              Bienvenide a Anahí, nuestro nuevo Sistema de Gestión de Asignaciones y Registro de Clases.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Tareas pendientes
          </Typography>
        </Grid>
        {tasks.length ? tasks : <Grid item xs={12}>
          <Typography variant="body1">
            No tenés tareas pendientes
            &nbsp;&nbsp;<ThumbUp />
          </Typography>
        </Grid>}

        <Grid item xs={12}>
          <Typography variant="h6">
            Recordatorios
          </Typography>
        </Grid>
        <InfoCard>Tip: Podés reprogramar las clases canceladas, tanto las pasadas como las futuras. <b>Usalo para añadir las clases extras que necesites.</b> Podes hacerlo desde las <Link href={`/teacher/assignations`}><b>asignaciones</b></Link>.</InfoCard>
        <InfoCard><b>Fin de clases de PC:</b> {config.config && moment(endOfClasses).format("DD/MM")}</InfoCard>
        <InfoCard>Las clases se informan siempre luego de finalizarlas. El tiempo límite para informarlas es hasta los <b>viernes a las 17.30hs.</b> En caso de no hacerlo, puede que el sistema no las registre y no se reflejen en la liquidación.</InfoCard>
        <InfoCard>Recordá indicar siempre el <b>horario exacto</b> en que inició cada clase. Para ello, debes tomar como referencia el <b>registro de llamadas</b> de la plataforma utilizada (Jabber, Conference, Zoom, etc.).</InfoCard>
        <InfoCard>Si tenés dos clases seguidas en la misma escuela, <b>recordá cortar la llamada entre clase y clase</b>. De lo contrario el sistema <b>contabilizará una sola llamada y pagará una sola clase</b>.</InfoCard>
        <InfoCard>Prestá atención a los feriados de Uruguay antes de reprogramar una clase.</InfoCard>
      </Grid>
    </div>
  );
}

const Task: React.FC = ({ children }) => {
  const classes = useStyles();
  return <Grid item xs={4}>
    <Paper className={classes.task}>
      <Typography variant="body1">
        <img alt="task" src={littleAnahi} />&nbsp;&nbsp;
        {children}
      </Typography>
    </Paper>
  </Grid>
}

const mapStateToProps = (state: AppState) => ({
  session: state.session,
  config: state.config
})

export default connect<Props, {}, {}, AppState>(mapStateToProps, {})(Dashboard);

import requestAction from '../../utils/requestAction'
import School, { SchoolInput } from '../../models/School';
import { pushAlert } from '../alert';

export const upsertSchool = (school: (School | SchoolInput)) =>
  requestAction('SCHOOL_UPSERT')(
    `schools${(school as School).id ? `/${(school as School).id}` : ''}`,
    (school as School).id ? 'PUT' : 'POST',
    school,
    (dispatch, _, response) => {
      dispatch({ type: 'UPSERT_SCHOOL', ...response })
      dispatch(pushAlert("Escuela actualizada"))
    },
    (dispatch, _) => {
      dispatch(pushAlert("Error actualizando la escuela", true))
    })


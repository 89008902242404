import { Button, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import classDelete from "../../../actions/class/classDelete";
import Class, { statusToDescription } from "../../../models/Class";
import DeleteIcon from '@material-ui/icons/Delete'
import AssignationSummary from "../../components/AssignationSummary";
import AnahiDialog from '../../components/Utils/Dialogs/AnahiDialog';
import { useHistory } from "react-router-dom";
import { DialogBasicProps } from "../../components/Utils/Dialogs/AnahiDialogIconButton";

type DeleteClassDialogProps = DialogBasicProps & { selectedClass: Class }


const DeleteClassDialog: FC<DeleteClassDialogProps> = ({ onClose, open, selectedClass }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <AnahiDialog
      open={open}
      onClose={onClose}
      title='¿Segurx que desea borrar la clase?'

      body={
        <><AssignationSummary
          department={selectedClass.assignation.group.school.department}
          grade={selectedClass.assignation.group.grade}
          schoolNumber={selectedClass.assignation.group.school.number}
          classroomTeacher={selectedClass.assignation.group.teacher} />

          <Typography>Horario: <b>{selectedClass.prettyDateTime()}</b> </Typography>
          <Typography>Estado: <b>{statusToDescription(selectedClass.status)}</b> </Typography></>
      }

      actions={
        <><Button onClick={onClose} color="primary" variant="contained">
          Cancelar
      </Button>

          <Button startIcon={<DeleteIcon />} onClick={() => { dispatch(classDelete(selectedClass, history)); onClose(); }} color="secondary" variant="contained">
            Eliminar la clase
        </Button></>
      }>

    </AnahiDialog>
  )

}

export default DeleteClassDialog
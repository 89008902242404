import { AvailabilitySlot } from '../models/AvailabilitySlot';
import { ApiState } from '../utils/requestAction';
import requestReducer from './requestReducer';


export interface AvailabilitySlotsState extends ApiState {
  availabilitySlots?: AvailabilitySlot[]
  slotsOccupied?: AvailabilitySlot[]
  availabilityUpdateDate: Date
}

export default requestReducer<AvailabilitySlotsState>('AVAILABILITYSLOTS')
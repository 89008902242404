import { TextField as MaterialTextField, TextFieldProps } from "@material-ui/core"
import React, { FC } from "react"

const TextField: FC<TextFieldProps & {hidden?: boolean}> = ({ disabled, hidden = false, helperText = "", margin = "normal", multiline = true, label = "",
    onChange, required, value, rows = 5, variant = "filled" }) =>
    <>
        {
            !hidden && <MaterialTextField
                disabled={disabled}
                helperText={helperText}
                margin={margin} variant={variant} fullWidth={true}
                multiline={multiline}
                label={label}
                onChange={onChange}
                required={required}
                rows="1"
                rowsMax={String(rows)}
                value={value === undefined || value === null ? '' : value}
            />
        }
    </>

export default TextField
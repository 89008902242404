import React, { FC, ReactNode, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchConfig } from '../../../actions/config'
import { roleToURLPrefix } from '../../../App'
import AssignationListTable from '../../../components/Assignation/AssignationListTable'
import { ADMIN, Role } from '../../../models/User'
import { AppState } from '../../../reducers'
import { AssignationListState } from '../../../reducers/assignation/assignationList'
import { ConfigState } from '../../../reducers/config'
import { FilterAssignationStatus, FilterClassTime, FilterDepartment, FilterFromDate, FilterFullName, FilterGrade, FilterSchoolNumber, FilterToDate } from '../Utils/Filters'
import { FilterElement, FiltersBar } from '../Utils/Filters/Filter'
import { FilterSendToCeibal, FilterWeekday } from '../Utils/Filters/index'
import useStyles from "./styles"

type AvailableFilter = keyof ReturnType<typeof assignationFilters>
interface Props {
  assignationList: AssignationListState
  enabledFilters: AvailableFilter[]
  role: Role
  config? : ConfigState
  showInfoButton?: boolean
  showEditButton?: boolean
  children?: ReactNode
}

const AssignationList: FC<Props> = ({ assignationList, enabledFilters, role, config, showEditButton = true, showInfoButton = false, children }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfig);
  }, [dispatch]);
  
  const availableFilters = assignationFilters(role)
  const makeUrl = ({ data }) => `/${roleToURLPrefix(role)}/assignations/${data.id}`

  return (
    <div className={classes.container}>
      <FiltersBar>
        {enabledFilters.map<FilterElement>(filter => availableFilters[filter]) as any}
      </FiltersBar>

      <AssignationListTable
        loading={assignationList.isFetching}
        rows={(assignationList.assignations || []).map(data => ({ data }))}
        showInfoButton={showInfoButton}
        showEditButton={showEditButton}
        onClickInfo={row => history.push(makeUrl(row), { title: 'Ver asignación' })}
        onRowClick={row => history.push(makeUrl(row))}
        pagination={assignationList.pagination}
        role={role}
        showCreateClassButton={role === ADMIN || config?.config?.remoteTeachersCanCreateClasses}
      />

      {children}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  assignationList: state.assignationList,
  config: state.config
})

export default connect<Pick<Props, 'assignationList'>, {}, Omit<Props, 'assignationList'>, AppState>(mapStateToProps, {})(AssignationList)

const assignationFilters = (role: Role) => ({
  fullName: <FilterFullName key='FullName' label="Docente Remoto" />,
  department: <FilterDepartment key='Department' />,
  weekday: <FilterWeekday key='Weekday' />,
  status: <FilterAssignationStatus key='AssignationStatus' role={role} />,
  schoolNumber: <FilterSchoolNumber key='SchoolNumber' />,
  shouldSendToCeibal: <FilterSendToCeibal key='SendToCeibal' />,
  toDate: <FilterToDate key='ToDate' />,
  fromDate: <FilterFromDate key='FromDate' />,
  grade: <FilterGrade key='Grade' />,
  time: <FilterClassTime key='Time' />
})

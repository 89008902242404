import { combineReducers } from 'redux'
import { connect } from 'react-redux'

import passwordRecovery from './auth/passwordRecovery'
import session from './auth/session'
import updateCredentials from './auth/updateCredentials'

import schoolList from './school/schoolList'
import schoolDetail from './school/schoolDetail'
import schoolBulkUpsert from './school/schoolBulkUpsert'

import schoolContactList from './schoolContact/schoolContactList'
import schoolContactDetail from './schoolContact/schoolContactDetail'
import schoolContactUpsert from './schoolContact/schoolContactUpsert'
import schoolContactBulkUpsert from './schoolContact/schoolContactBulkUpsert'

import schoolJabberUserList from './schoolJabberUser/schoolJabberUserList'
import schoolJabberUserDetail from './schoolJabberUser/schoolJabberUserDetail'
import schoolJabberUserUpsert from './schoolJabberUser/schoolJabberUserUpsert'
import schoolJabberUserBulkUpsert from './schoolJabberUser/schoolJabberUserBulkUpsert'

import remoteTeacherList from './remoteTeacher/remoteTeacherList'
import remoteTeacherDetail from './remoteTeacher/remoteTeacherDetail'
import remoteTeacherUpsert from './remoteTeacher/remoteTeacherUpsert'
import remoteTeacherBulkUpsert from './remoteTeacher/remoteTeacherBulkUpsert'

import nonSchoolDayList from './nonSchoolDay/nonSchoolDayList'
import nonSchoolDayBulkUpsert from './nonSchoolDay/nonSchoolDayBulkUpsert'

import assignationList from './assignation/assignationList'
import assignationDetail from './assignation/assignationDetail'
import fetchClass from './class/classDetail'
import classList from './class/classList'

import assignationConfirm from './assignation/assignationConfirm'
import assignationBulkUpsert from './assignation/assignationBulkUpsert'

import notifySchools from './notifySchools'
import notifyTeachers from './notifyTeachers'

import profile from './profile'

import availabilitySlots from './availabilitySlots'

import config from './config'
import alert from './alert'
import notifications from './notifications'
import settlementRecordList from './settlementRecordList'
import platformList from './platformList'
import proposalList from './proposalList'

import historyGroupClassList from './class/historyGroupClassList'

export const reducers = combineReducers({
  passwordRecovery,
  session,
  updateCredentials,

  classList,
  schoolList,
  schoolDetail,
  schoolBulkUpsert,

  schoolContactList,
  schoolContactDetail,
  schoolContactUpsert,
  schoolContactBulkUpsert,

  schoolJabberUserList,
  schoolJabberUserDetail,
  schoolJabberUserUpsert,
  schoolJabberUserBulkUpsert,

  remoteTeacherList,
  remoteTeacherDetail,
  remoteTeacherUpsert,
  remoteTeacherBulkUpsert,

  fetchClass,

  nonSchoolDayList,
  nonSchoolDayBulkUpsert,

  assignationList,
  assignationDetail,
  assignationConfirm,
  assignationBulkUpsert,

  notifySchools,
  notifyTeachers,

  profile,

  availabilitySlots,

  config,
  alert,

  notifications,

  settlementRecordList,

  platformList,

  proposalList,

  historyGroupClassList
})

export type AppState = ReturnType<typeof reducers>

const mapStateToProps = <T extends keyof AppState>(...keys: T[]): any => (state: AppState) => Object.entries(state)
  .filter(([key,]) => keys.includes(key as T))
  .reduce((result, [key, value]) => Object.assign(result, { [key]: value }), {})

export type AppProp<T extends keyof AppState> = Pick<AppState, T>

export const connectApp = <T extends keyof AppState>(...keys: T[]) =>
  <ComponentProps>(component: React.ComponentType<ComponentProps & AppProp<T>>) =>
    connect<AppProp<T>, {}, ComponentProps, AppState>(mapStateToProps(...keys))(component as any)
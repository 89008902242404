import React from "react"
import { useState } from "react"
import { Fab } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import SendIcon from '@material-ui/icons/Send'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useStyles from "./styles"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { notifySchools } from "../../../actions/notifySchools"
import { notifyTeachers } from "../../../actions/notifyTeachers"

const AssignationManagementButtons: React.FC = () => {
  const classes = useStyles()
  const [showActions, setShowActions] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleNotifySchools = () => {
    setShowActions(false)
    dispatch(notifySchools)
  }

  const handleNotifyTeachers = () => {
    setShowActions(false)
    dispatch(notifyTeachers)
  }
  
  return <div>
    <Fab color="primary" className={classes.fabAdd} onClick={() => history.push('/admin/assignations/create', {title: 'Crear asignaciones'})}>
      <AddIcon />
    </Fab>

    <Fab color="secondary" className={classes.fabAction} onClick={() => setShowActions(true)}>
      <SendIcon />
    </Fab>

    <Dialog fullWidth maxWidth="xs" open={showActions} onClose={() => setShowActions(false)}>
      <DialogTitle>Acciones</DialogTitle>
      <DialogContent dividers>
        <Button color="primary" variant="contained" onClick={handleNotifySchools} fullWidth className={classes.actionButton}>
          Enviar notificaciones a escuelas
        </Button>
        <Button color="primary" variant="contained" onClick={handleNotifyTeachers} fullWidth className={classes.actionButton}>
          Enviar notificaciones a docentes remotos
        </Button>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setShowActions(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default AssignationManagementButtons
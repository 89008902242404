import React from 'react'
import { AppState } from '../../../reducers/index'
import { Snackbar } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { hideAlert } from '../../../actions/alert';


const useStyles = (isError: boolean) => makeStyles(theme => ({
  alert: {
    opacity: 1,
    transform: 'none',
    // transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color: '#fff',
    'font-weight': 500,
    'background-color': isError ? '#d14343' : '#4caf50',
    display: 'flex',
    padding: '6px 16px',
    'font-size': '0.875rem',
    'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
    'line-height': 1.43,
    'border-radius': '4px',
    'letter-spacing': '0.01071em',
  },
  alertIcon: {
    marginRight: theme.spacing(1),
  },
}))


const Alert: React.FC<StateProps> = ({ message, isError = false }) => {
  const classes = useStyles(isError)()
  const dispatch = useDispatch()

  return (
    <Snackbar
      open={!!message}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={() => { dispatch(hideAlert) }}
    >
      <div className={classes.alert}>
        {isError ? <ErrorOutlineIcon className={classes.alertIcon} /> : <CheckCircleOutlineIcon className={classes.alertIcon} />}
        {message}
      </div>
    </Snackbar >
  )
}


type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = ({ alert }: AppState) => alert

export default connect(mapStateToProps, null)(Alert)
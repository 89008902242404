import { createStore, applyMiddleware , Middleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reducers } from '../reducers';

export default () => {
    const middleware: Array<Middleware> = [thunk]
    if (window.config?.ENV !== 'production') {
        middleware.push(logger);
    }
    return createStore(reducers, applyMiddleware(...middleware));
}


import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import { RemoteTeacherResult } from '../../models/RemoteTeacher';


export interface RemoteTeacherBulkUpsertState extends ApiState {
  results?: RemoteTeacherResult[]
}

export default requestReducer<RemoteTeacherBulkUpsertState>('REMOTE_TEACHER_BULK_UPSERT')

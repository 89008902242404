import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import moment, { Moment } from "moment"
import { isNil, lensProp, set } from 'ramda';
import React, { FC, useEffect, useState } from "react"
import { connect, useDispatch } from 'react-redux'
import classHasScheduleFree from "../../../actions/class/classHasScheduleFree"
import classInsert from "../../../actions/class/classInsert"
import classUpdate from "../../../actions/class/classUpdate"
import { fetchDays } from "../../../actions/nonSchoolDay/nonSchoolDayList"
import { fetchAllTeachers } from "../../../actions/remoteTeacher/remoteTeacherList"
import DateAndTimePicker from "../../../components/DateAndTimePicker"
import Selector from "../../../components/Selector"
import Assignation, { sinceTimeMoment } from "../../../models/Assignation"
import Class from "../../../models/Class"
import ClassroomTeacher from "../../../models/ClassroomTeacher"
import RemoteTeacher from "../../../models/RemoteTeacher"
import { AppState } from "../../../reducers"
import { isAdmin } from '../../../reducers/auth/session'
import { isValidSchoolTime } from '../../../utils/days';
import AssignationSummary from "../../components/AssignationSummary";
import useStyles from './styles'
import { useHistory } from 'react-router-dom';
import { sortByAdapter } from "../../../utils/StringUtils";
import { isOnNonSchoolDay, isValidDate, sameDate } from "./classValidity";

interface CreateRescheduleClassInformDialogProps {
  assignation: Assignation
  classToReschedule?: Class
  updatableClass?: Class
  onClose?: () => void
  open?: boolean
}

interface CreateClassDialogState {
  classToReschedule?: Class
  updatableClass?: Class 
  classroomTeacher: ClassroomTeacher
  classDate: Moment
  hasScheduleFreeForRemoteTeacher?: boolean
  hasScheduleFreeForAssignation?: boolean
  selectedRemoteTeacher: RemoteTeacher
}

const CreateClassDialog: FC<CreateRescheduleClassInformDialogProps & StateProps> = ({ onClose = () => { }, assignation, classToReschedule, updatableClass, session, open = false, remoteTeacherList = [], nonSchoolDayList = [] }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const admin = isAdmin(session)
  const isRescheduling: boolean = Boolean(classToReschedule)
  const isMoving: boolean = Boolean(updatableClass)
  const [processing, setProcessing] = useState<boolean>(false)

  const [{ group: { grade, school: { department, number } }, id }] = useState<Assignation>(assignation)
  
  const createState = (assignation: Assignation, classToReschedule?: Class, updatableClass?: Class): CreateClassDialogState => {
    const selectedRemoteTeacher: RemoteTeacher = new RemoteTeacher(updatableClass?.teacher || classToReschedule?.teacher || assignation.teacher)
    const classroomTeacher: ClassroomTeacher = new ClassroomTeacher(assignation.group.teacher)
    const classDate: Moment = isRescheduling ? moment(classToReschedule?.sinceDate) : isMoving ? moment(updatableClass?.sinceDate) : sinceTimeMoment(assignation)
    
    return ({
      classroomTeacher,
      classDate,
      selectedRemoteTeacher
    })
  }
  
  const initialState = () => createState(assignation, classToReschedule, updatableClass)
  
  const [state, setState] = useState<CreateClassDialogState>(initialState())
  const [isDateTimeComplete, setIsDateTimeComplete] = useState<boolean>(!!state.classDate)

  useEffect(() => {
    if (admin) dispatch(fetchAllTeachers())
    dispatch(fetchDays())
    setState(initialState())
    setDate(state.classDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignation, classToReschedule])

  const closeDialogWithoutUpdate = (): void => {
    onClose()
    setState(initialState())
  }

  const closeDialogWithUpdate = (): void => {
    if (processing) return

    setProcessing(true)
    onClose()
    
    dispatch(!isMoving ? classInsert(history, set(lensProp('rescheduleClassId'), classToReschedule?.id, {
      assignationId: id,
      sinceDate: state.classDate,
      teacherId: state.selectedRemoteTeacher.id
    })) : classUpdate({...updatableClass, sinceDate: state.classDate.toString() } as Class, false, true))
  }

  const handleRemoteTeacher = ({ target: { value } }) => {
    setState({
      ...state,
      selectedRemoteTeacher: value
    })
  }

  const handleDate = (newDate: MaterialUiPickersDate) => {
    setIsDateTimeComplete(!!newDate)
    if(newDate) { setDate(newDate) }
  }

  const setDate = (newDate: Moment) => {
    const params: string = `date=${newDate!}&assignationId=${assignation.id}&remoteTeacherId=${state.selectedRemoteTeacher.id}`    
    
    dispatch(classHasScheduleFree(params, ({ hasScheduleFreeForRemoteTeacher, hasScheduleFreeForAssignation }) => {
      setState({
        ...state,
        classDate: newDate!,
        hasScheduleFreeForRemoteTeacher,
        hasScheduleFreeForAssignation
      })
    }))
  }

  const isValidTime = (date: Moment): boolean =>
    isValidSchoolTime(date) && Boolean(state.hasScheduleFreeForRemoteTeacher) && Boolean(state.hasScheduleFreeForAssignation)

  const canConfirm = (): boolean =>
    isDateTimeComplete && isValidDate(state.classDate,session,nonSchoolDayList,classToReschedule) && isValidTime(state.classDate) && Boolean(state.selectedRemoteTeacher)

  const dateErrorMessage = (): string => {

    if (isOnNonSchoolDay(nonSchoolDayList)(state.classDate)) {
      return "No se pueden reprogramar clases a días que caigan en fines de semanas, feriados o dias de vacaciones."
    }

    if (!isMoving && sameDate(state.classDate)) {
      return "No se pueden reprogramar clases para el mismo día que una clase original. Cuando una clase se dicta el mismo día de la clase original pero en otro horario, eso no se considera una reprogramación sino un cambio de horario."
    }

    return "No se puede reprogramar la clase para esta fecha."

  }

  const timeErrorMessage = (): string => {

    if (!isValidDate(state.classDate,session,nonSchoolDayList,classToReschedule)) {
      return ""
    }

    if (!isValidSchoolTime(state.classDate)) {
      return "La hora debe ser entre las 8:00 y las 17:00."
    }

    if (!state.hasScheduleFreeForRemoteTeacher) {
      return "En este horario hay otra clase del DR."
    }

    if (!state.hasScheduleFreeForAssignation) {
      return "En este horario hay otra clase del del grupo."
    }

    return "No se puede reprogramar la clase para esta hora."

  }

  // In order for the component not to break (out-of-range), we need to provide an object
  // from the remoteTeacherList, not the object classToReschedule.teacher directly.
  const selectedRemoteTeacher = () =>
    remoteTeacherList.find(rt => rt.id === state.selectedRemoteTeacher.id)

  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={closeDialogWithoutUpdate}>

      <DialogTitle>{isMoving ? "Moviendo clase" : isRescheduling ? "Reagendando clase" : "Creando clase"}</DialogTitle>

      <DialogContent dividers>
        <AssignationSummary
          department={department}
          grade={grade}
          schoolNumber={number}
          classroomTeacher={state.classroomTeacher}
        />

        <Selector
          disabled={isNil(assignation) || !admin}
          helperText="Docente Remoto asignado a la clase."
          hidden={!admin}
          menuItems={sortByAdapter(remoteTeacherList, (rt: RemoteTeacher) => rt.fullName)}
          label="Docente Remoto: "
          onChange={handleRemoteTeacher}
          textAdapter={(rt) => rt.fullName}
          value={selectedRemoteTeacher()}
          variant={"filled"}
        />

        <DateAndTimePicker
          date={state.classDate}
          disabled={isNil(assignation)}
          errorDateMessage={dateErrorMessage()}
          errorTimeMessage={timeErrorMessage()}
          label={isMoving ? "la clase" : "la nueva clase"}
          onChange={handleDate}
          validateDate={(date) => isValidDate(date,session,nonSchoolDayList,classToReschedule)}
          validateTime={isValidTime}
        />

      </DialogContent>

      <DialogActions>
        <Button className={classes.button} variant="contained" color="primary" size="small" disabled={false} onClick={closeDialogWithoutUpdate}>Cancelar</Button>
        <Button className={classes.button} variant="contained" color="primary" size="small" disabled={processing || !canConfirm()} onClick={closeDialogWithUpdate}>Aceptar</Button>
      </DialogActions>

    </Dialog>

  )

}

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = ({ remoteTeacherList, nonSchoolDayList, session }: AppState) => ({
  remoteTeacherList: remoteTeacherList?.teachers,
  nonSchoolDayList: nonSchoolDayList?.days,
  session,
})

export default connect<StateProps, {}, CreateRescheduleClassInformDialogProps, AppState>(mapStateToProps)(CreateClassDialog)
import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: -64,
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
      textAlign: 'right'
    },
  }),
);

interface Props {
  tableComponent: JSX.Element;
  onSubmit: () => void;
}

const BulkUpsertResult: React.FC<Props> = (props) => {  
  const classes = useStyles();

  return (
    <div>
      {props.tableComponent}
      <Paper square className={classes.paper}>
        <Button
          color="primary"
          variant="contained"
          onClick={props.onSubmit}
        >
          Finalizar
        </Button>
      </Paper>
    </div>
  );
}

export default BulkUpsertResult
import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(4)
    },
  }),
);

interface Props {
  step: number;
}

const BulkUpsertStepper: React.FC<Props> = (props) => {  
  const classes = useStyles();

  return (
    <Paper square className={classes.paper}>
      <Stepper activeStep={props.step}>
        <Step completed={props.step > 0}>
          <StepLabel>Seleccionar archivo</StepLabel>
        </Step>
        <Step completed={props.step > 1}>
          <StepLabel>Confirmar datos</StepLabel>
        </Step>
        <Step completed={props.step > 2}>
          <StepLabel>Ver resultados</StepLabel>
        </Step>
      </Stepper>
    </Paper>
  );
}

export default BulkUpsertStepper
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  alert: {
    backgroundColor: red[500],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  subtitle: {
    marginTop: "3%"
  }
}));

export default useStyles
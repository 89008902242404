import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Assignation from '../../../models/Assignation'
import ClassroomTeacher from '../../../models/ClassroomTeacher';
import fetchApi, { getToken } from '../../../utils/fetchApi'
import EditButtonDialog from '../Utils/Dialogs/EditButtonDialog'
import { entityTextField } from '../Utils/EntityField'
import { Typography } from '@material-ui/core'
import AutocompleteAsyncTextField from '../Utils/AutocompleteAsyncTextField'
import { upsertClassroomTeacher, updateAssignation } from '../../../actions/assignation/assignationUpdate'

interface AssignationClassroomTeacherEditProps {
  classroomTeacher: ClassroomTeacher
  assignation: Assignation
}

const AssignationClassroomTeacherEdit: React.FC<AssignationClassroomTeacherEditProps> = ({ classroomTeacher, assignation }) => {
  const dispatch = useDispatch()
  const [teacher, setTeacher] = useState<ClassroomTeacher>(classroomTeacher)

  const handleAccept = () => {
    dispatch(upsertClassroomTeacher(teacher, (response) => {
      if (!teacher.id) {
        teacher.id = response.classroomTeacher.id
      }
      if (teacher.id !== classroomTeacher.id) {
        const json = {
          id: assignation.id,
          group: { teacher }
        }
        dispatch(updateAssignation(json as any))
      }
    }))
  }

  const searchTeachers = (lastName: string) =>
    fetchApi(getToken(), 'GET', `classroom-teachers?lastName=${lastName}`)
      .then(response => response.teachers)

  const changeTeacher: (t: ClassroomTeacher) => void = setTeacher
  const TeacherTextField = useMemo(() => entityTextField(changeTeacher), [changeTeacher])

  return (
    <EditButtonDialog
      title="Editar docente de aula"
      onAccept={handleAccept}
    >

      <AutocompleteAsyncTextField<ClassroomTeacher>
        label="Apellido del nuevo docente de aula"
        search={searchTeachers}
        selectLabel={teacher => {
          if (teacher.id) {
            const dni = teacher.documentNumber
            const name = teacher.firstName ? teacher.fullName : teacher.lastName
            return dni ? `${dni} - ${name}` : `${name}`
          }
          return `Crear nuevo docente de aula`
        }
        }
        onSelect={teacher => setTeacher(teacher || classroomTeacher)}
        newEntity={lastName => ({ lastName }) as ClassroomTeacher}
      />

      <br />

      {teacher.id &&
        <Typography variant="subtitle1">
          {teacher.id === classroomTeacher.id
            ? 'O quizá quieras solamente cambiar algunos datos del docente'
            : 'Cambiando docente de aula, revisá los datos y aceptá'
          }
        </Typography>
      }

      <TeacherTextField
        label="Nombre"
        entity={teacher}
        attr="firstName"
      />
      <TeacherTextField
        label="Apellido"
        entity={teacher}
        attr="lastName"
        required
      />
      <TeacherTextField
        label="Cédula"
        entity={teacher}
        attr="documentNumber"
      />
      <TeacherTextField
        label="Email"
        entity={teacher}
        attr="email"
      />
      <TeacherTextField
        label="Teléfono"
        entity={teacher}
        attr="phoneNumber"
      />
    </EditButtonDialog>
  )
}

export default AssignationClassroomTeacherEdit


import { Button } from '@material-ui/core'
import * as React from 'react'
import SendIcon from '@material-ui/icons/Send'
import { AnahiDialogButton } from '../../components/Utils/Dialogs/AnahiDialogButton'
import RemoteTeacher from '../../../models/RemoteTeacher'
import { PluralPaymentMailConcept, SingularPaymentMailConcept } from '.'

interface PaymentMailsSenderButtonProps {
  teachersToSend: RemoteTeacher[]
  singularMailConcept: SingularPaymentMailConcept
  pluralMailConcept: PluralPaymentMailConcept
  action: () => void
}

export const PaymentMailsSenderButton = ({ teachersToSend, singularMailConcept, pluralMailConcept, action }: PaymentMailsSenderButtonProps) => {

  const dialogBody = () =>
    teachersToSend.length === 1 ? `Se va a enviar la ${singularMailConcept} a ${teachersToSend[0].fullName}` : `Se van a enviar las ${pluralMailConcept} a ${teachersToSend.length} docentes`

  return (<>
    <AnahiDialogButton
      buttonText={`Enviar ${pluralMailConcept}`}
      dialogTitle={`¿Segurx que desea enviar las ${pluralMailConcept}?`}
      dialogBody={dialogBody()}
      dialogActions={(onClose) =>
        <>
          <Button onClick={onClose} color="primary" variant="contained">
            Cancelar
          </Button>

          <Button startIcon={<SendIcon />} onClick={() => { action(); onClose(); }} color="secondary" variant="contained">
            {`Enviar las ${pluralMailConcept}`}
          </Button>
        </>
      }
    />
  </>
  )
}


import React from 'react'
import { Button, Typography } from '@material-ui/core'
import AnahiDialog from './AnahiDialog'

export interface AlertDialogProps {
  title?: string
  message?: string
  onDismiss: () => void
  open?: boolean
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open = true, onDismiss, title, message, children }) => (
  <AnahiDialog
    open={open}
    title={title}
    body={
      <Typography variant="body1">
        {message}
      </Typography>
    }
    onClose={onDismiss}
    actions={
      <>
        {children}
        <Button color="secondary" onClick={onDismiss}>
          Cerrar
          </Button>
      </>
    }
  />
)

export default AlertDialog


import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchReferencesAssignations } from '../../../actions/assignation/assignationList';
import { REFERENT } from '../../../models/User';
import AssignationList from '../../components/AssignationList'

const ReferentAssignationList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchReferencesAssignations(history.location.search))
  }, [dispatch, history.location.search])
  
  return <AssignationList
    enabledFilters={['fullName', 'department', 'schoolNumber', 'status', 'weekday']}
    showInfoButton={true}
    showEditButton={false}
    role={REFERENT}
  />
}

export default ReferentAssignationList
import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ImportantDayType, NonSchoolDayInput } from '../../models/NonSchoolDay';
import Selector from '../Selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  day: NonSchoolDayInput;
  onDismiss: () => void;
  onSubmit: (day: NonSchoolDayInput) => void;
}

const NonSchoolDayEditDialog: React.FC<Props> = (props) => {  
  const classes = useStyles();
  const [day, setDay] = useState<NonSchoolDayInput>(props.day);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar fecha importante</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Día"
          margin="normal" variant="filled" required fullWidth
          defaultValue={day.day}
          onChange={e => setDay({ ...day, day: parseInt(e.target.value) })}
        />
        <TextField
          label="Mes"
          margin="normal" variant="filled" required fullWidth
          defaultValue={day.month}
          onChange={e => setDay({ ...day, month: parseInt(e.target.value) })}
        />
        <TextField
          label="Descripción"
          margin="normal" variant="filled" required fullWidth
          defaultValue={day.description}
          onChange={e => setDay({ ...day, description: e.target.value })}
        />
        <Selector
          menuItems={Object.values(ImportantDayType)}
          label="Tipo de fecha"
          onChange={e => setDay({ ...day, type: e.target.value })}
          textAdapter={(type) => type === ImportantDayType.Other ? 'Otro' : type}
          value={day.type}
          variant={"filled"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(day)}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NonSchoolDayEditDialog
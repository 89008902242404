import { Button, Container, Link, Paper, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

interface ExportReportCardProps {
    title: string,
    description: string,
    href: string
}


export const ExportReportCard = (props: ExportReportCardProps) => {
    const classes = useStyles();
    return (
        <Container>
            <Paper square className={classes.paper}>
                <Typography variant="h5">
                    {props.title}
                </Typography>
                <p>
                    {props.description}
                    <Button variant='contained' className={classes.download}>
                        <Link href={`${window.config.apiURL}/${props.href}`} target="_blank">
                            Descargar csv
                        </Link>
                    </Button>
                </p>
            </Paper>
        </Container>
    )
}
import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { RemoteTeacherBulkUpsertState } from '../../../reducers/remoteTeacher/remoteTeacherBulkUpsert';
import { bulkUpsertTeachers } from '../../../actions/remoteTeacher/remoteTeacherBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import RemoteTeacherListTable from '../../../components/RemoteTeacher/RemoteTeacherListTable';
import RemoteTeacherEditDialog from '../../../components/RemoteTeacher/RemoteTeacherEditDialog';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';

import { RemoteTeacherInput } from '../../../models/RemoteTeacher';

interface Props {
  teacherBulkUpsert: RemoteTeacherBulkUpsertState;
}

const PAGE_SIZE = 10;

export const CSV_RT_FIELDS = [
  { name: 'documentNumber', display: 'DNI' },
  { name: 'lastName', display: 'Apellido/s' },
  { name: 'firstName', display: 'Nombre/s' },
  { name: 'email', display: 'Mail' },
  { name: 'phoneNumber', display: 'Teléfono' },
  { name: 'maxSlotsByContract', display: 'Máximo Horas' },
  { name: 'maxSlotsPreferred', display: 'Deseo' },
  { name: 'batch', display: 'Cohorte' },
  { name: 'referent.fullName', display: 'Referente' },
  { name: 'referent.documentNumber', display: 'DNI Referente', hidden: true},
  { name: 'jabberUser', display: 'Jabber' },
  { name: 'jabberLocal', display: 'Local' },
  { name: 'province', display: 'Provincia' },
  { name: 'locality', display: 'Localidad', hidden: true },
  { name: 'address', display: 'Dirección', hidden: true },
  { name: 'zipCode', display: 'Código Postal', hidden: true },
  { name: 'orderOfMerit', display: 'Orden de mérito' },
];

const RemoteTeacherCsvCreation: React.FC<Props> = (props) => {  
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [teachers, setTeachers] = useState<RemoteTeacherInput[]>([]);
  const [selected, setSelected] = useState<number|null>(null);
  const [page, setPage] = useState<number>(0);

  const handleUpload = (records: any[]) => {
    setTeachers(records as RemoteTeacherInput[]);
    setStep(1);
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertTeachers(teachers));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const handleFinish = () => {
    history.push('/admin/remote-teachers', {title: 'Docentes remotos'});
  }

  const handleUpdateTeacher = (teacher: RemoteTeacherInput) => {
    setTeachers(teachers.map((s, i) => i === selected ? teacher : s));
    setSelected(null);
  }

  const getAlertTitle = () => {
    if (selected !== null) {
      switch ((props.teacherBulkUpsert.results || [])[selected].action) {
        case 'create':
          return 'Creado correctamente';
        case 'update':
          return 'Actualizado correctamente';
        case 'error':
          return 'Error';
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  const getAlertMessage = () => {
    if (selected !== null) {
      switch ((props.teacherBulkUpsert.results || [])[selected].action) {
        case 'error':
          return (props.teacherBulkUpsert.results || [])[selected].error;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <BulkUpsertUpload
          fields={CSV_RT_FIELDS}
          onSubmit={handleUpload}
        />
      }

      {step === 1 &&
        <BulkUpsertConfirm
          tableComponent={
            <RemoteTeacherListTable
              loading={false}
              showEditButton={true}
              allFields={true}
              rows={
                teachers
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(s => ({ data: s }))
              }
              pagination={{ size: PAGE_SIZE, count: teachers.length, page }}
              onClickEdit={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleConfirm}
        />
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <RemoteTeacherListTable
              loading={props.teacherBulkUpsert.isFetching}
              showInfoButton={true}
              allFields={true}
              rows={
                (props.teacherBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(r => ({ data: r.record, action: r.action as any }))
              }
              pagination={{ size: PAGE_SIZE, count: (props.teacherBulkUpsert.results || []).length, page }}
              onClickInfo={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <RemoteTeacherEditDialog
          teacher={teachers[selected]}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateTeacher}
        />
      }
      
      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  teacherBulkUpsert: state.remoteTeacherBulkUpsert
})

export default connect(mapStateToProps, null)(RemoteTeacherCsvCreation)
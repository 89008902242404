import React, { useState, FormEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { AppState } from '../../../reducers/index'
import { SuccessLabel } from '../../components/Utils/ShowLabel'
import { updateCredentials as sendNewCredentials } from '../../../actions/auth/updateCredentials'
import { fetchSession } from '../../../actions/auth/session'
import ProgressButton from '../../components/Utils/ProgressButton'
import NewPassword from '../../components/Utils/NewPassword'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import useStyles from './styles'


const Credentials: React.FC<StateProps> = ({ session, updateCredentials }) => {
  const classes = useStyles()
  const [oldPassword, setOldPassword] = useState<string | undefined>(undefined)
  const [newPassword, setNewPassword] = useState<string | null>(null)

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const changeCredentials = (event: FormEvent) => {
    event.preventDefault()
    dispatch(sendNewCredentials({ oldPassword: oldPassword!, newPassword: newPassword! }, () => {
      if (session.shouldUpdatePassword) dispatch(fetchSession(history, location))
    })(location.search))
  }

  return (
    <Container>
      <Paper square className={classes.paper}>
        <Typography variant="h5">
          Credenciales
        </Typography>
        <form onSubmit={changeCredentials}>
          <TextField
            label="Contraseña actual"
            margin="normal"
            variant="outlined"
            type="password"
            onChange={event => setOldPassword(event.target.value)}
            fullWidth
            required
          />
          <NewPassword onValidPassword={setNewPassword}/>
          <ProgressButton state={updateCredentials} disabled={!newPassword}>
            Actualizar contraseña
          </ProgressButton>
          <SuccessLabel
            show={updateCredentials.updated}
            message="Contraseña actualizada."
          />
        </form>
      </Paper>
    </Container>
  )
}

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = ({ session, updateCredentials }: AppState) => ({ session, updateCredentials })

export default connect<StateProps, {}, {}, AppState>(mapStateToProps)(Credentials)

import requestAction from '../utils/requestAction'
import { Profile } from '../models/Profile';

const makeRequest = requestAction('PROFILE')

export const fetchProfile =
  makeRequest('profile', 'GET')


export const updateProfile = (profile: Profile) =>
  makeRequest('profile', 'PUT', profile)


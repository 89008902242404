import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  download: {
    marginLeft: theme.spacing(3),
  }
}));

export default useStyles
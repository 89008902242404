import moment, { Moment } from "moment"
import { not, T } from "ramda"
import React, { FC, useState } from "react"
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { AccessTime } from "@material-ui/icons"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

interface DateAndTimePickerProps {
    date?: Moment
    disabled?: boolean
    errorDateMessage?: string
    errorTimeMessage?: string
    hidden?: boolean
    helperText?: string
    onChange: (newDate: MaterialUiPickersDate) => void
    label?: string
    required?: boolean
    textAdapter?: (value?: string) => string
    validateDate?: (date: Moment) => boolean
    validateTime?: (date: Moment) => boolean
}

/**
 * Component that lets you choose a date and time by GUI or typing.
 * @param onChange executes everytime. Receives the date only when a complete and well-formed date is typed or chosen. Otherwise it receives null. 
 * Use `validateDate` and `validateTime` in conjunction with `errorDateMessage` and `errorTimeMessage` to give user feedback.
 */
const DateAndTimePicker: FC<DateAndTimePickerProps> = ({ date = moment(), disabled = false, errorDateMessage, errorTimeMessage, label = "", hidden = false, helperText = "", onChange, required = false, validateDate = T, validateTime = T }) => {

    const [keyboardDate, setkeyboardDate] = useState<Moment>(date)

    const onDateTimeEdited = (dateTimeFrom: (aDate: moment.Moment) => moment.Moment) => (partialDateTime: MaterialUiPickersDate) => {
        if(partialDateTime?.isValid()){
            const newDate = dateTimeFrom(partialDateTime)
            setkeyboardDate(newDate)
            onChange(newDate)
        }
        else{
            onChange(null)
        }
    }

    const momentFromDate = (onlyDate: Moment) =>
        moment(onlyDate).hours(keyboardDate.hours()).minutes(keyboardDate.minutes())

    const momentFromTime = (onlyTime: Moment) =>
        moment(onlyTime).year(keyboardDate.year()).month(keyboardDate.month()).date(keyboardDate.date())

    return (
        <div>
            {not(hidden) && 
                <div> 
                    <KeyboardDatePicker
                        label={"Fecha de " + label}
                        margin="normal"
                        format="DD/MM/YYYY"
                        value={keyboardDate}
                        onChange={onDateTimeEdited(momentFromDate)}
                        disabled={disabled}
                        required={required}
                        disableToolbar
                        KeyboardButtonProps={{ 'aria-label': "Fecha de " + label }}
                        shouldDisableDate={date => !validateDate(moment(date?.toDate()))}
                        error={!validateDate(moment(keyboardDate))}
                        helperText={(validateDate(moment(keyboardDate)) ? "" : errorDateMessage)}
                    />
                    <KeyboardTimePicker
                        label={"Hora de " + label}
                        margin="normal"
                        format="HH:mm"
                        ampm={false}
                        value={keyboardDate}
                        onChange={onDateTimeEdited(momentFromTime)}
                        disabled={disabled}
                        required={required}
                        KeyboardButtonProps={{ 'aria-label': "Hora de " + label }}
                        error={!validateTime(moment(keyboardDate))}
                        helperText={(validateTime(moment(keyboardDate)) ? "" : errorTimeMessage)}
                        keyboardIcon={<AccessTime />}
                    />
                </div>
            }
        </div>
    )
}

export default DateAndTimePicker
import { orange, red, lightGreen } from '@material-ui/core/colors'
import RemoteTeacher from './RemoteTeacher'
import Group from './Group'
import { SchoolInput } from './School'
import { find } from 'ramda'
import { literalStrings } from '../utils/StringUtils'
import moment from 'moment'

const TIME_FORMAT = "HH:mm"

export default interface Assignation {
  id: number
  slotTime: number
  dayOfWeek: number
  status: AssignationStatus
  sinceTime: string
  untilTime: string
  firstClassDate: Date
  shouldSendToCeibal: boolean
  statusComments: string | null
  group: Group
  teacher?: RemoteTeacher
  canBeDeleted: boolean
}

export const sinceTimeMoment = (assignation: Assignation) => moment(assignation.sinceTime + '-03:00', TIME_FORMAT + 'Z')

export interface AssignationInput {
  grade: string
  dayOfWeek: number
  slotTime: number
  sinceTime: string
  untilTime: string
  firstClassDate: Date
  school: SchoolInput
  remoteTeacher: {
    documentNumber: string
  }
  classroomTeacher: {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    documentNumber: string
  }
}

export interface AssignationResult {
  record: (Assignation | AssignationInput)
  action: string
  success: boolean
  error?: string
}

export type AssignationStatus = typeof assignationStatusses[number]
export const assignationStatusses = literalStrings(
  'assignation-pending', //NotAssigned
  'school-notification-pending', //Preassigned
  'school-confirmation-pending', //SchoolNotified
  'remote-teacher-notification-pending', //SchoolConfirms
  'school-reschedule-pending', //SchoolNotConfirms
  'remote-teacher-confirmation-pending', //RemoteTeacherNotified
  'remote-teacher-re-assignation-pending', //ReassignRemoteTeacher
  'classroom-teacher-confirmation-pending', //RemoteTeacherConfirms
  'unified', //Unified
  'assigned', //Assigned
  'dropped', //Dropped
)

const assignationStatusDescriptions: { [status in AssignationStatus]: string[] } = {
  'assignation-pending': ["Asignación de DR pendiente", "No asignado"],
  'school-notification-pending': ["Notificación a Escuela pendiente", "Pre-asignado"],
  'school-confirmation-pending': ["Esperando confirmación de Escuela", "Escuela avisada"],
  'remote-teacher-notification-pending': ["Notificación a DR pendiente", "Escuela confirma"],
  'school-reschedule-pending': ["Esperando horario de Escuela", "Escuela no confirma"],
  'remote-teacher-confirmation-pending': ["Confirmación de DR pendiente", "DR Informado"],
  'remote-teacher-re-assignation-pending': ["Reasignación de DR pendiente", "Reasignar DR"],
  'classroom-teacher-confirmation-pending': ["Confirmación de DA pendiente", "DR confirma"],
  'unified': ["Unificado", "Unificado"],
  'assigned': ["Asignado", "Asignado"],
  'dropped': ["Baja", "Baja"]
}

const assignationStatuses = Object.keys(assignationStatusDescriptions) as AssignationStatus[]

/**
 * 
 * @param status One AssignationStatus.
 * @param descriptionNumber Desired description style: 0 for pending action description (default), 1 for action passed (legacy description until 2019)
 * @returns The human-friendly description of the assignation status.
 */
export const statusToDescription = function (status: AssignationStatus, descriptionNumber?: 0 | 1): string {
  if (descriptionNumber === 0 || descriptionNumber === 1) return assignationStatusDescriptions[status][descriptionNumber]
  else {
    return `${statusToDescription(status, 1)} (${statusToDescription(status, 0)})`
  }
}

/**
 * 
 * @param status an AssignationStatus human-friendly description.
 * @returns The correspondant AssignationStatus for the provided description
 */
export const descriptionToStatus = (description: string): AssignationStatus => {
  const found = find(status => assignationStatusDescriptions[status].some(s => s === description.substring(0, s.length)), assignationStatuses)

  if (!found) throw new Error(`No assignation status for description: ${description}`)
  return found
}


export const getStatusColor = (status: AssignationStatus) => {
  switch (status) {
    case 'assignation-pending':
    case 'school-notification-pending':
    case 'school-confirmation-pending':
    case 'remote-teacher-notification-pending':
    case 'remote-teacher-confirmation-pending':
    case 'classroom-teacher-confirmation-pending':
      return orange[500]
    case 'unified':
    case 'assigned':
      return lightGreen[500]
    case 'school-reschedule-pending':
    case 'remote-teacher-re-assignation-pending':
    case 'dropped':
      return red[500]
    default:
      return '#EEEEEE'
  }
}

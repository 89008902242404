import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { AppState } from '../../../reducers'
import { RemoteTeacherListState } from '../../../reducers/remoteTeacher/remoteTeacherList'
import { fetchTeachers } from '../../../actions/remoteTeacher/remoteTeacherList'
import RemoteTeacherListTable from '../../../components/RemoteTeacher/RemoteTeacherListTable'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { FiltersBar } from '../../components/Utils/Filters/Filter'
import { FilterDocumentNumber, FilterFullName, FilterProvince } from '../../components/Utils/Filters/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
);

interface Props {
  remoteTeacherList: RemoteTeacherListState;
}

const RemoteTeacherList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchTeachers(history.location.search));
  }, [dispatch, history.location.search]);

  return (
    <div>
      <FiltersBar >
        <FilterFullName />
        <FilterDocumentNumber />
        <FilterProvince />
      </FiltersBar>

      <RemoteTeacherListTable
        rows={(props.remoteTeacherList.teachers || []).map(s => ({ data: s }))}
        loading={props.remoteTeacherList.isFetching}
        pagination={props.remoteTeacherList.pagination || undefined}
      />

      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/remote-teachers/create', { title: 'Crear docentes remotos' })}>
        <AddIcon />
      </Fab>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  remoteTeacherList: state.remoteTeacherList
})

export default connect(mapStateToProps, null)(RemoteTeacherList)
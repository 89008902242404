import requestAction from '../../utils/requestAction'
import { Dispatch } from 'react'
import { initialApiState, ApiAction } from '../../utils/requestAction';

const makeRequest = requestAction('SESSION')

export const fetchSession = (history, location) =>
  makeRequest('session', 'GET', undefined,
    handleResponse(location, history), 
    handleError(history)
  )

const handleResponse = (location, history) => (dispatch: Dispatch<ApiAction>, getState, response) => {
  if (response && location.pathname === '/') {
    if (response.roles.includes('administrator')) {
      history.push('/admin/schools', { title: 'Escuelas' })
      return
    }
    if (response.roles.includes('remote-teacher')) {
      history.push('/teacher/dashboard', { title: 'Home' })
      return
    }
  }
}

const handleError = history => (dispatch: Dispatch<ApiAction>, err: Error) => {
  doLogout(history)(dispatch)
}

export const doLogout = history => (dispatch: Dispatch<ApiAction>) => {
  window.localStorage.removeItem('token')
  dispatch({
    type: 'SESSION_CLOSED', 
    newApiState: initialApiState
  })
  history.push('/login')
}


import React from 'react';
import { Paper, Table } from '@material-ui/core';
import { TableProps } from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  }
}))

const AnahiTable: React.FC<TableProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper square>
      <Table className={classes.table} size="small" {...props}>
        {props.children}
      </Table>
    </Paper>

  )
}
export default AnahiTable
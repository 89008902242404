import { omit } from 'ramda'
import Class from '../../models/Class'
import requestAction from '../../utils/requestAction'
import { pushAlert } from '../alert'

const CLASS_UPDATE = 'CLASS_UPDATE'

const movingClass = ['movingClass']

const createTeacherRequestBody = (aClass: Class) =>
  omit(['assignation', 'assignationId', 'sentToCeibal', 'teacherId', 'teacher', 'rescheduledToId', 'platform', 'proposal'], { ...aClass })

const createAdminRequestBody = (aClass: Class) => omit(['assignation', 'assignationId', 'platform', 'proposal'], { ...aClass })

export default (aClass: Class, isAdmin = false, isMoving = false) =>
  requestAction(CLASS_UPDATE)(`class?classId=${aClass.id}`, 'POST', isMoving ? { ...createTeacherRequestBody(aClass), movingClass } :
                                                                        isAdmin ? createAdminRequestBody(aClass) : createTeacherRequestBody(aClass),
    (dispatch, _, response) => {
      dispatch({ type: 'UPDATE_CLASS', ...response })
      dispatch(pushAlert("Clase actualizada")) //TODO: Move to requestAction
    })
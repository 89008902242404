import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { SchoolBulkUpsertState } from '../../../reducers/school/schoolBulkUpsert';
import { bulkUpsertSchools } from '../../../actions/school/schoolBulkUpsert';

import BulkUpsertStepper from '../../../components/BulkUpsert/BulkUpsertStepper';
import BulkUpsertUpload, { FieldType } from '../../../components/BulkUpsert/BulkUpsertUpload';
import BulkUpsertConfirm from '../../../components/BulkUpsert/BulkUpsertConfirm';
import BulkUpsertResult from '../../../components/BulkUpsert/BulkUpsertResult';
import SchoolListTable from '../../../components/School/SchoolListTable';
import SchoolEditDialog from '../../../components/School/SchoolEditDialog';
import AlertDialog from '../../components/Utils/Dialogs/AlertDialog';

import { SchoolInput } from '../../../models/School';

interface Props {
  schoolBulkUpsert: SchoolBulkUpsertState;
}

const PAGE_SIZE = 10;

const CSV_FIELDS = [
  { name: 'rue', display: 'RUEE', type: FieldType.String },
  { name: 'department', display: 'Departamento', type: FieldType.String },
  { name: 'number', display: 'Número', type: FieldType.String },
  { name: 'area', display: 'Área', type: FieldType.String },
  { name: 'zone', display: 'Zona geográfica', type: FieldType.String },
  { name: 'category', display: 'Categoría', type: FieldType.String },
  { name: 'shiftName', display: 'Turno', type: FieldType.String },
  { name: 'shiftTimes', display: 'Horario', type: FieldType.String },
  { name: 'name', display: 'Nombre', type: FieldType.String },
  { name: 'streetName', display: 'Calle', type: FieldType.String },
  { name: 'streetNumber', display: 'Altura', type: FieldType.String },
  { name: 'locality', display: 'Localidad', type: FieldType.String },
  { name: 'phoneNumber', display: 'Teléfono (sin +598)', type: FieldType.String },
  { name: 'email', display: 'Email', type: FieldType.String },
  { name: 'share', display: 'Comparte', type: FieldType.String }
];

const SchoolCsvCreation: React.FC<Props> = (props) => {  
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const [schools, setSchools] = useState<SchoolInput[]>([]);
  const [selected, setSelected] = useState<number|null>(null);
  const [page, setPage] = useState<number>(0);

  const handleUpload = (records: any[]) => {
    setSchools(records as SchoolInput[]);
    setStep(1);
  }

  const handleConfirm = () => {
    dispatch(bulkUpsertSchools(schools));
    setSelected(null);
    setPage(0);
    setStep(2);
  }

  const handleFinish = () => {
    history.push('/admin/schools', {title: 'Escuelas'});
  }

  const handleUpdateSchool = (school: SchoolInput) => {
    setSchools(schools.map((s, i) => i === selected ? school : s));
    setSelected(null);
  }

  const getAlertTitle = () => {
    if (selected !== null) {
      switch ((props.schoolBulkUpsert.results || [])[selected].action) {
        case 'create':
          return 'Creada correctamente';
        case 'update':
          return 'Actualizada correctamente';
        case 'error':
          return 'Error';
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  const getAlertMessage = () => {
    if (selected !== null) {
      switch ((props.schoolBulkUpsert.results || [])[selected].action) {
        case 'error':
          return (props.schoolBulkUpsert.results || [])[selected].error;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  return (
    <div>

      <BulkUpsertStepper step={step} />

      {step === 0 &&
        <BulkUpsertUpload
          fields={CSV_FIELDS}
          onSubmit={handleUpload}
        />
      }

      {step === 1 &&
        <BulkUpsertConfirm
          tableComponent={
            <SchoolListTable
              loading={false}
              showEditButton={true}
              rows={
                schools
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(s => ({ data: s }))
              }
              pagination={{ size: PAGE_SIZE, count: schools.length, page }}
              onClickEdit={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleConfirm}
        />
      }

      {step === 2 &&
        <BulkUpsertResult
          tableComponent={
            <SchoolListTable
              loading={props.schoolBulkUpsert.isFetching}
              showInfoButton={true}
              rows={
                (props.schoolBulkUpsert.results || [])
                  .slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE)
                  .map(r => ({ data: r.record, action: r.action as any }))
              }
              pagination={{ size: PAGE_SIZE, count: (props.schoolBulkUpsert.results || []).length, page }}
              onClickInfo={(s, i) => setSelected((page * PAGE_SIZE) + i)}
              onChangePage={setPage}
            />
          }
          onSubmit={handleFinish}
        />
      }

      {selected !== null && step === 1 &&
        <SchoolEditDialog
          school={schools[selected]}
          onDismiss={() => setSelected(null)}
          onSubmit={handleUpdateSchool}
        />
      }
      
      {selected !== null && step === 2 &&
        <AlertDialog
          title={getAlertTitle()}
          message={getAlertMessage()}
          onDismiss={() => setSelected(null)}
        />
      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  schoolBulkUpsert: state.schoolBulkUpsert
})

export default connect(mapStateToProps, null)(SchoolCsvCreation)
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      alignSelf: 'flex-end',
      minHeight: 'fit-content',
      "& > *": {
        marginLeft: theme.spacing(2)
      }
    },
    form: {
      display: 'contents'
    }
  })
)
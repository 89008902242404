import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import { SchoolContactResult } from '../../models/SchoolContact';


export interface SchoolContactBulkUpsertState extends ApiState {
  results?: SchoolContactResult[]
}

export default requestReducer<SchoolContactBulkUpsertState>('SCHOOL_CONTACT_BULK_UPSERT')

import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchSettlementRecords } from '../../../actions/settlementRecordList'
import { AppProp, connectApp } from '../../../reducers'
import SettlementRecordsTable from './SettlementRecordTable'

type SettlementProps = AppProp<'settlementRecordList'>

const Settlement: React.FC<SettlementProps> = ({ settlementRecordList }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchSettlementRecords(history.location.search))
  }, [dispatch, history.location.search])

  return <>
    {settlementRecordList.records &&
      <SettlementRecordsTable
        records={settlementRecordList.records}
        loading={settlementRecordList.isFetching}
        pagination={settlementRecordList.pagination}
      />
    }
  </>

}

export default connectApp('settlementRecordList')(Settlement)
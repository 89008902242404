import React from "react"
import { getToken } from '../../../../utils/fetchApi'
import { Button, Link } from "@material-ui/core"
import { AppProp, connectApp } from '../../../../reducers'
import { isAdmin, isReferent } from '../../../../reducers/auth/session'
import DatePickerButton, { DateIntervalButtonProps } from "../../Utils/DatePickerButton"
import useStyles from "../../Utils/ExportReportsCard/styles"

type ExportButtonProps = DateIntervalButtonProps & AppProp<'session'>
const ExportClassesButton = ({ dateInterval, session }: ExportButtonProps) => {
  const classes = useStyles()

  const prefix = isAdmin(session) ? '' : isReferent(session) ? '/references' : '/remote-teachers'

  return (
    <Button variant='contained' className={classes.download}>
      {dateInterval.from && dateInterval.from.isValid() && dateInterval.to && dateInterval.to.isValid() ? (
        <Link href={`${window.config.apiURL}${prefix}/classes/export?access_token=${getToken()}&from=${dateInterval.from.format()}&to=${dateInterval.to.format()}`} target="_blank">
        Descargar csv
        </Link>
      ) : (<div>Verifique las Fechas</div>)
      }
    </Button>
  )
}

const ConnectedExportButton = connectApp('session')(ExportClassesButton)

export default () => <DatePickerButton title='Reporte de clases dictadas' Button={ConnectedExportButton} />
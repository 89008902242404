import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import SchoolJabberUser from '../../models/SchoolJabberUser';


export interface SchoolJabberUserDetailState extends ApiState {
  user?: SchoolJabberUser
}

export default requestReducer<SchoolJabberUserDetailState>('SCHOOL_JABBER_USER_DETAIL')

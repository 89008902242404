import requestAction from '../../utils/requestAction'
import Assignation from '../../models/Assignation';
import { pushAlert } from '../alert';
import ClassroomTeacher from '../../models/ClassroomTeacher';
import { volatilAction } from '../../utils/sequenceActions'

export const updateAssignation = (assignation: Assignation, successMessage: string = "Actualizado") =>
  volatilAction(requestAction('ASSIGNATION_DETAIL')(
    `assignations/${assignation.id}`, 'PUT', assignation, 
    (dispatch, _, response) => {
      dispatch({ type: 'UPDATE_ASSIGNATION', ...response })
      dispatch(pushAlert(successMessage)) //TODO: Move to requestAction?
    },
    (dispatch, errorMessage) => {
      dispatch(pushAlert(`Error actualizando la asignación: ${errorMessage.message}`, true)) 
    },
  ))

export const upsertClassroomTeacher = (teacher: ClassroomTeacher, cb: (response: any) => void) =>
  requestAction('CLASSROOM_TEACHER_UPDATE')(`classroom-teachers${sufix(teacher.id)}`, (teacher.id ? 'PUT' : 'POST'), teacher,
    (dispatch, _, response) => {
      dispatch(pushAlert("Actualizado"))
      cb(response)
    }
  )

const sufix = (id) => (id ? `/${id}` : '')
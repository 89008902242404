import requestAction from '../utils/requestAction';
import { Configuration } from '../models/Configuration';

const makeRequest = requestAction('CONFIG')

export const fetchConfig = 
  makeRequest('config', 'GET')


export const updateConfig = (config: Configuration) =>
  makeRequest('config', 'PUT', config)



import * as React from 'react'
import Table from '../../../components/Table'
import RemoteTeacher from '../../../models/RemoteTeacher'
import { useState } from 'react'
import { sortByAdapter } from '../../../utils/StringUtils'
import Checkbox from '../../../components/Checkbox'
import { Button, Container, makeStyles, Paper } from '@material-ui/core'
import useStyles from '../../components/Utils/ExportReportsCard/styles'

type SelectableRemoteTeacher = RemoteTeacher & { selected: boolean }

interface RemoteTeacherSelectionTableProps {
  teachers?: RemoteTeacher[]
  onChange: (remoteTeachers: RemoteTeacher[]) => void
}

const selfStyles = makeStyles(theme => ({
  selectionButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '1%',
    gridAutoColumns: "max-content",
    marginBottom: '1%'
  }
}))

export default (props: RemoteTeacherSelectionTableProps) => {
  const classes = {...useStyles(), ...selfStyles()}
  const initialTeachers = props.teachers?.map(rt => ({ ...rt, selected: false }))
  const [teachers, setTeachers] = useState<SelectableRemoteTeacher[]>(initialTeachers || [])
  const setTeachersAndInform = rts => {
    setTeachers(rts)
    props.onChange(rts.filter(rt => rt.selected))
  }
  const updateTeacherSelection = (teacher: SelectableRemoteTeacher, selected: boolean) => {
    setTeachersAndInform(teachers.map(rt => rt.id === teacher.id ? { ...teacher, selected: selected } : rt))
  }

  const updateAllTeachers = (selected: boolean) => {
    setTeachersAndInform(teachers.map(rt => ({ ...rt, selected: selected })))
  }

  return <Container>
    <Paper square className={classes.paper} >
      <div className={classes.selectionButtons}>
        <Button color='secondary' variant='contained' onClick={() => updateAllTeachers(true)}>Seleccionar todos</Button>
        <Button color='secondary' variant='contained' onClick={() => updateAllTeachers(false)}>Deseleccionar todos</Button>
      </div>
      <Table
        headerrow={{
          hover: true,
          cells: [
            { value: 'Docente Remoto' },
            { value: 'Elegir' }
          ]
        }}
        bodyrows={
          sortByAdapter(teachers, (rt) => rt.lastName).map(rt => ({
            cells: [
              { value: rt.fullName },
              { value: <Checkbox value={rt.selected} onChange={(_event, selected) => { updateTeacherSelection(rt, selected) }} /> }
            ]
          }))}
      />
    </Paper>
  </Container>
}
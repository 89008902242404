import React, { useState, FormEvent, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { updateAssignation } from '../../../actions/assignation/assignationUpdate'
import { isAdmin } from '../../../reducers/auth/session'
import { AppState } from '../../../reducers/index'
import Assignation from '../../../models/Assignation'
import Group from '../../../models/Group'
import { Paper, MenuItem } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import ProgressButton from '../Utils/ProgressButton'
import { entitySelectField, entityTextField } from '../Utils/EntityField'
import { days, isValidSchoolTime } from '../../../utils/days'
import { useStyles } from './styles'
import moment, { Moment } from 'moment'
import { AccessTime } from '@material-ui/icons'

interface AssignationUpdateProp {
  disabled: boolean
  started?: boolean
}

const AssignationUpdate: React.FC<StateProps & AssignationUpdateProp> = ({ assignationDetail, session, disabled, started = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [assignation, setAssignation] = useState<Assignation>(assignationDetail.assignation!)
  const [group, setGroup] = useState<Group>(assignationDetail.assignation!.group)

  const handleSaveChanges = (event: FormEvent) => {
    event.preventDefault()
    dispatch(updateAssignation({ ...assignation, group }, 'Asignación actualizada'))
  }

  const { firstClassDate } = assignation

  const startTime = moment(assignation.sinceTime, 'HH:mm')

  const changeAssignation: (assignation: Assignation) => void = setAssignation
  const AssignationSelectField = useMemo(() => entitySelectField(changeAssignation), [changeAssignation])

  const changeGroup: (group: Group) => void = setGroup
  const GroupTextField = useMemo(() => entityTextField(changeGroup), [changeGroup])

  const updateDate = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      const firstClassDate = newDate.toDate()
      setAssignation({ ...assignation, firstClassDate })
    }
  }

  const updateTime = (newTime: MaterialUiPickersDate) => {
    if (newTime) {
      const sinceTime = newTime.format('HH:mm')
      setAssignation({ ...assignation, sinceTime })
    }
  }

  const rightWeekday = (date: Moment): boolean =>
    date.isoWeekday() === assignation.dayOfWeek + 1

  return (
    <Paper square>
      <form className={classes.cardBody} onSubmit={handleSaveChanges}>
        <GroupTextField
          label="Grupo"
          entity={group}
          attr="grade"
          disabled={disabled || !isAdmin(session)}
          required
        />
        <AssignationSelectField
          label="Día"
          entity={assignation}
          attr="dayOfWeek"
          disabled={disabled}
          required
        >
          {days.map((day, i) => <MenuItem key={i} value={i}>{day}</MenuItem>)}
        </AssignationSelectField>
        <KeyboardTimePicker
          label="Hora de inicio"
          margin="normal"
          value={startTime}
          ampm={false}
          onChange={updateTime}
          fullWidth
          disabled={disabled}
          required
          KeyboardButtonProps={{ 'aria-label': 'Hora de inicio' }}
          keyboardIcon={<AccessTime />}
          error={!isValidSchoolTime(startTime)}
          helperText={isValidSchoolTime(startTime) ? "" : "La hora inicial debe caer dentro del horario escolar"}
        />
        <KeyboardDatePicker
          label="Fecha de inicio"
          margin="normal"
          format="DD/MM"
          value={firstClassDate}
          onChange={updateDate}
          disableToolbar
          fullWidth
          disabled={disabled || started}
          required
          KeyboardButtonProps={{ 'aria-label': 'Fecha de inicio' }}
          shouldDisableDate={date => !rightWeekday(moment(date?.toDate()))}
          error={!rightWeekday(moment(assignation.firstClassDate)) && !started}
          helperText={(rightWeekday(moment(assignation.firstClassDate)) || started ? "" : "Esta fecha no coincide con el día de la asignación. Si aún no comenzaste las clases, recordá modificarlo.")}
        />
        {!disabled && <ProgressButton
          state={assignationDetail}
        >
          Guardar
          </ProgressButton>
        }
      </form>
    </Paper>
  )
}

type StateProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: AppState) => ({
  assignationDetail: state.assignationDetail,
  session: state.session,
})

export default connect<StateProps, {}, AssignationUpdateProp, AppState>(mapStateToProps)(AssignationUpdate)
import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import RemoteTeacher from '../../models/RemoteTeacher';


export interface RemoteTeacherDetailState extends ApiState {
  teacher?: RemoteTeacher
}

export default requestReducer<RemoteTeacherDetailState>('REMOTE_TEACHER_DETAIL')

import React, { useState, FormEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { doLogin } from '../../actions/auth/login'
import { connectApp, AppProp } from '../../reducers';
import { sendPasswordRecovery } from '../../actions/auth/passwordRecovery'
import { Typography, TextField, Button } from '@material-ui/core'
import ProgressButton from '../components/Utils/ProgressButton'
import AnahiCard from '../components/Utils/AnahiCard'
import useStyles from './styles'

type Props = AppProp<'session' | 'passwordRecovery'>

const Login: React.FC<Props> = ({ session, passwordRecovery }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<boolean>(false)


  const handleLogin = (event: FormEvent) => {
    event.preventDefault()
    dispatch(doLogin(history, email, password))
  }

  const handlePasswordRecovery = (event: FormEvent) => {
    event.preventDefault()
    if (!email) {
      setEmailError(true)
      return;
    }
    dispatch(sendPasswordRecovery(history.location.search, email))
    setEmailError(false)
  }

  return (
    <AnahiCard>
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          margin="normal"
          variant="outlined"
          onChange={props => setEmail(props.target.value)}
          error={emailError}
          helperText={emailError ? "Ingrese su e-mail para recuperar su contraseña" : ""}
          fullWidth
          required
        />
        <TextField
          label="Contraseña"
          margin="normal"
          variant="outlined"
          type="password"
          onChange={props => setPassword(props.target.value)}
          fullWidth
          required
        />
        {session.error ?
          <Typography align="center" variant="subtitle2" className={classes.error}>
            Email o contraseña incorrectos
        </Typography>
          : null}
        <Button
          className={classes.sendButton}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
        >
          Ingresar
      </Button>
      </form>
      <ProgressButton
        state={passwordRecovery}
        variant="text"
        onClick={handlePasswordRecovery}
      >
        Olvidé mi contraseña
      </ProgressButton>
    </AnahiCard>)
}

export default connectApp('session', 'passwordRecovery')(Login)

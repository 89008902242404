import { ApiAction, PaginatedApiState, initialPaginatedApiState } from '../../utils/requestAction'
import { replaceEntityById } from '../../utils/reducers'
import requestReducer from '../requestReducer'
import Class from '../../models/Class'
import { Action } from 'redux'

export interface ClassListState extends PaginatedApiState {
  classes?: Class[]
}

interface UpdateClass extends Action<'UPDATE_CLASS'> {
  class: Class
}

export default (state: ClassListState = initialPaginatedApiState, action: ApiAction | UpdateClass) => {
  switch (action.type) {
    case 'UPDATE_CLASS':
      return { ...state, classes: replaceEntityById(action.class, state.classes || []) }
    default:
      return requestReducer<ClassListState>('CLASS_LIST')(state, action)
  }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const getToken = () => window.localStorage.getItem('token')

//TODO: No recibir el token y usar siempre getToken()
export default (token: string | null, method: HttpMethod, route: string, body?: any) =>
  fetch(`${window.config.apiURL}/${route}`, {
    method,
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(r => r.json())



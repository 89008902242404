import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchAssignations } from '../../../actions/assignation/assignationList'
import { ADMIN } from '../../../models/User'
import AssignationList from '../../components/AssignationList'
import AssignationManagementButtons from '../../components/AssignationList/AssignationManagementButtons';

const AdminAssignationList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAssignations(history.location.search))
  }, [dispatch, history.location.search])
  
  return <AssignationList
    enabledFilters={['fullName', 'department', 'schoolNumber', 'fromDate', 'toDate', 'time', 'grade', 'shouldSendToCeibal', 'status', 'weekday']}
    role={ADMIN}
  >
    <AssignationManagementButtons />
  </AssignationList>
}

export default AdminAssignationList
import { ApiAction, initialPaginatedApiState, PaginatedApiState } from '../../utils/requestAction'
import requestReducer from '../requestReducer'
import Assignation from '../../models/Assignation'
import { Action } from 'redux'
import { replaceEntityById } from '../../utils/reducers'


export interface AssignationListState extends PaginatedApiState {
  assignations?: Assignation[]
}

interface UpdateAssignation extends Action<'UPDATE_ASSIGNATION'> {
  assignation: Assignation
}

export default (state: AssignationListState = initialPaginatedApiState, action: ApiAction | UpdateAssignation) => {
  switch (action.type) {
    case 'UPDATE_ASSIGNATION':
      return { ...state, assignations: replaceEntityById(action.assignation, state.assignations || []) }
    default:
      return requestReducer<AssignationListState>('ASSIGNATION_LIST')(state, action)
  }
}
import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers'
import { Notifications } from '../../../models/Notifications'
import AdminNavItem from './AdminNavItem'

interface AssignationNavItemProps {
  notifications?: Notifications
}

const AssignationNavItem = (props: AssignationNavItemProps) => {
  const count = props.notifications?.assignations

  return <AdminNavItem
    uri='assignations'
    title='Asignaciones'
    icon='school'
    notification={count}
  />
}

const mapStateToProps = (state: AppState) => ({
  notifications: state.notifications.notifications
})

export default connect(mapStateToProps, null)(AssignationNavItem)
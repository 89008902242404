import React, { useEffect } from 'react';
import RemoteTeacher, { RemoteTeacherInput } from '../../models/RemoteTeacher';
import Pagination from '../../models/Pagination';
import { connect, useDispatch } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import ColoredTableRow from '../ColoredTableRow';
import { AppState } from '../../reducers/index';
import { ConfigState } from '../../reducers/config';
import { fetchConfig } from '../../actions/config';
import moment from 'moment';
import { CSV_RT_FIELDS } from '../../views/Administrator/RemoteTeacherCsvCreation/index';
import { getAttribute } from '../../utils/csvParser';
import PaginationButtons from '../PaginationButtons';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 5
    },
    paper: {
      marginBottom: 64 + theme.spacing(4)
    },
    progress: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
    statusCell: {
      textAlign: 'center'
    }
  }),
);

export interface Row {
  data: (RemoteTeacher|RemoteTeacherInput);
  action?: 'create' | 'update' | 'error';
}

interface Props {
  rows: Row[];
  loading: boolean;
  showEditButton?: boolean;
  onClickEdit?: (row: Row, index: number) => void;
  pagination?: Pagination;
  onChangePage?: (page: number) => void;
  showInfoButton?: boolean;
  onClickInfo?: (row: Row, index: number) => void;
  fields?: string[];
  config?: ConfigState;
  allFields?: boolean;
}

const RemoteTeacherListTable: React.FC<Props> = (props) => {  
  const classes = useStyles()
  const dispatch = useDispatch()
  const showEditButton = props.showEditButton || false;
  const showInfoButton = props.showInfoButton || false;
  const csvRTFields = props.allFields ? CSV_RT_FIELDS : CSV_RT_FIELDS.filter(({hidden}) => !hidden)

  useEffect(() => {
    dispatch(fetchConfig)
  }, [dispatch])

  const mapActionToColor = (action?: string) => {
    switch (action) {
      case 'create': return 'green';
      case 'update': return 'yellow';
      case 'error': return 'red';
      default: return 'default';
    }
  }

 const availabilityUpdated = (availabilityUpdateDate: string) => availabilityUpdateDate ? moment(availabilityUpdateDate).format("DD [de] MMMM") : 'No' 

  return (
    <Paper square className={classes.paper}>
      <Table size="small">
        <TableHead>
          <TableRow selected={true}>
            { csvRTFields.map((field) => <TableCell key={field.name}>{field.display}</TableCell>) }
            <TableCell>Disponibilidad actualizada</TableCell>
            { showEditButton && <TableCell/> }
            { showInfoButton && <TableCell/> }
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) =>
            <ColoredTableRow key={index} color={mapActionToColor(row.action)}>
              { csvRTFields.map(({name}, index) => 
                <TableCell key={index}>
                  {getAttribute(name, row.data) || (row.data['user'] ? row.data['user'][name] : '') }
                </TableCell>) }
                <TableCell>
                  {availabilityUpdated(row.data['availabilityUpdateDate'])}
                </TableCell>
              { showEditButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickEdit && props.onClickEdit(row, index)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
              { showInfoButton &&
                <TableCell>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => props.onClickInfo && props.onClickInfo(row, index)}
                  >
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              }
            </ColoredTableRow>
          )}
        </TableBody>
      </Table>
      { props.loading &&
        <div className={classes.progress}>
          <CircularProgress size={100} />
        </div>
      }
      <PaginationButtons pagination={props.pagination} />
    </Paper>
  );
}

const mapStateToProps = (state: AppState) => ({
  config: state.config
})

export default connect(mapStateToProps, null)(RemoteTeacherListTable)
import RemoteTeacher from "../models/RemoteTeacher"
import { paramsToQueryString } from "../utils/paramsToQueryString"
import requestAction, { alertError, alertMessage, RequestActionType } from "../utils/requestAction"
import { PluralPaymentMailConcept } from "../views/Administrator/PaymentMails"
import { DateInterval } from "../views/components/Utils/DatePickerButton"

interface SendPaymentMailsProps {
  type: RequestActionType
  route: string
  pluralMailConcept: PluralPaymentMailConcept
  dateInterval: DateInterval
  teachers: RemoteTeacher[]
}

export default ({ type, route, pluralMailConcept, dateInterval, teachers }: SendPaymentMailsProps) =>
  requestAction(type)(`${route}?${paramsToQueryString({from: dateInterval.from.format(), to: dateInterval.to.format()})}`, 'POST', { teacherIds: teachers.map(rt => rt.id) },
    alertMessage(`Las ${pluralMailConcept} están siendo enviadas, tardarán unos minutos.`),
    alertError(`No se pudieron enviar las ${pluralMailConcept}.`)
  )
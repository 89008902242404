import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  iframe: {
    border: 'none',
    width: '100%',
    height: '3000px'
  }
}))

export default () => {
  const classes = useStyles();

  return (
    <iframe className={classes.iframe} src="https://docs.google.com/document/u/1/d/e/2PACX-1vQgLm2n6-D4yi2IdCQKRbbVNhny-AwKsJHhCiQKozIHAwwxtzhjBiou9bpgITBsBnsY_XRduMXbcezD/pub" title="Tutorials"></iframe>
  )
}
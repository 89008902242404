import { ApiState } from '../../utils/requestAction';
import requestReducer from '../requestReducer';
import { SchoolResult } from '../../models/School';


export interface SchoolBulkUpsertState extends ApiState {
  results?: SchoolResult[]
}

export default requestReducer<SchoolBulkUpsertState>('SCHOOL_BULK_UPSERT')

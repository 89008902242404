import { Dispatch } from 'react';
import { AppState } from '../reducers/index';
import { AlertAction } from '../reducers/alert';


export const pushAlert = (message: string, isError = false) => (dispatch: Dispatch<AlertAction>, getState: () => AppState) => {
  dispatch({
    type: 'NEW_MESSAGE',
    message: message,
    isError: isError
  })
}

export const hideAlert = (dispatch: Dispatch<AlertAction>, getState: () => AppState) => {
  dispatch({ type: 'HIDE' })
}
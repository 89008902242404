import requestAction from '../../utils/requestAction'

type HasScheduleParams = {
    hasScheduleFreeForRemoteTeacher: boolean
    hasScheduleFreeForAssignation: boolean
}

export default (params: string, handleResponse?: (hasSchedule: HasScheduleParams) => void) =>
    requestAction('CLASS_HAS_SCHEDULE_FREE', params)(`classes/has-schedule-free?`, 'GET', undefined, (_dispatch, _getState, response) => {
        if (response && handleResponse) handleResponse({
            hasScheduleFreeForRemoteTeacher: Boolean(response.hasScheduleFreeForRemoteTeacher),
            hasScheduleFreeForAssignation: Boolean(response.hasScheduleFreeForAssignation)
        })
    })

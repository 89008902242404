import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchReferencesClasses } from '../../../actions/class/classList'
import { columns } from '../../../components/ClassListTable/ClassListColumns'
import ClassesList from '../../components/ClassesList'
import { FilterKind, FilterWeekday, FilterFullName } from '../../components/Utils/Filters/index'

const { department, school, group, day, date, time, remoteTeacher, kind, status, seeReportButton } = columns

const ReferentClassesList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchReferencesClasses(history.location.search))
  }, [dispatch, history.location.search])

  return <ClassesList
    columns={[department, school, group, day, date, time, remoteTeacher, kind, status, seeReportButton]}
  >
    <FilterFullName label="Docente Remoto" />
    <FilterKind />
    <FilterWeekday />
  </ClassesList>
}

export default ReferentClassesList
import InfoIcon from '@material-ui/icons/Info'
import Queue from '@material-ui/icons/Queue'
import ScheduleIcon from '@material-ui/icons/DateRange'
import moment from 'moment'
import { equals, lensProp, view } from 'ramda'
import React, { useState } from 'react'
import { Link } from '@material-ui/core'
import Assignation, { AssignationInput, statusToDescription } from '../../models/Assignation'
import Pagination from '../../models/Pagination'
import { capitalize } from '../../utils/StringUtils'
import Table, { TableRow } from '../Table'
import TooltipIconButton from '../TooltipIconButton'
import CreateClassDialog from '../../views/RemoteTeacher/CreateClassDialog/CreateClassDialog'
import { updateAssignation } from '../../actions/assignation/assignationUpdate'
import { SentToCeibal } from '../SentToCeibal'
import { useDispatch } from 'react-redux'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteAssignationDialog from '../../views/Administrator/DeleteAssignationDialog/DeleteAssignationDialog'
import { AnahiDialogIconButton } from '../../views/components/Utils/Dialogs/AnahiDialogIconButton'
import { ADMIN, REFERENT, Role } from '../../models/User'
import { useHistory } from 'react-router-dom'
import { roleToURLPrefix } from '../../App'
import { Filters, filtersToSearch } from '../../views/components/Utils/Filters/Filter'
import HistoryGroupClassReportDialog from '../../views/components/HistoryGroupClassesView'

export interface Row {
  data: (Assignation | AssignationInput)
  action?: 'create' | 'update' | 'error'
}

interface Props {
  loading: boolean
  onChangePage?: (page: number) => void
  onRowClick?: (row: Row) => void
  onClickInfo?: (row: Row, index: number) => void
  pagination?: Pagination
  rows: Row[]
  role: Role
  showEditButton?: boolean
  showInfoButton?: boolean
  showCreateClassButton?: boolean
}

const AssignationListTable: React.FC<Props> = (props) => {
  const initialState: { isOpenCreateClass: boolean, viewHistoryClasses: boolean, selectedAssignation?: Assignation } = { isOpenCreateClass: false, viewHistoryClasses: false }
  const [state, setState] = useState<typeof initialState>(initialState)
  const history = useHistory()

  const hasId = (assignation: (Assignation | AssignationInput)) => Boolean(view(lensProp('id'), assignation))

  const isAdmin = () => equals(props.role, ADMIN)
  const isReferent = () => equals(props.role, REFERENT)

  const InfoButton = (row: Row, index: number) =>
    <TooltipIconButton onClick={(e) => { e.stopPropagation(); props.onClickInfo && props.onClickInfo(row, index) }} tooltip="Información">
      <InfoIcon />
    </TooltipIconButton>

  const CreateClassButton = (row: Row, index: number) =>
    <TooltipIconButton
      onClick={(e) => { e.stopPropagation(); setState({ ...state, isOpenCreateClass: true, selectedAssignation: row.data as Assignation }) }} // CreateClassButton will never be used in views like AssignationCsvCreation. So this IS an Assignation.
      tooltip="Crear clase">
      <Queue />
    </TooltipIconButton>

  const DeleteAssignationButton = ({ assignation }) =>
    <AnahiDialogIconButton id='delete-assignation-button'
      tooltip={assignation.canBeDeleted ? 'Eliminar asignacion' : 'No se puede eliminar una asignacion con clases'}
      ButtonIcon={DeleteForeverIcon}
      disabled={!assignation.canBeDeleted}
      TheDialog={DeleteAssignationDialog}
      dialogProps={{ selectedAssignation: assignation }}
    />

  const ViewHistoryClassesLink = (row: Row, index: number) =>
    <TooltipIconButton
      onClick={(e) => { e.stopPropagation(); setState({ ...state, viewHistoryClasses: true, selectedAssignation: row.data as Assignation }) }} 
      tooltip="Ver Historial de Grupo">
      <Link underline="always" variant="body2">{(row.data as Assignation).group.grade.toUpperCase()}</Link>
    </TooltipIconButton>
      
  const ShowReschedulableClasses = (assignation: Assignation) => {
    const params = filtersToSearch({
      assignationId: `${assignation.id}`,
      from: `${moment().startOf('year').startOf('day')}`,
      to: `${moment().endOf('year').endOf('day')}`,
      rescheduled: 'false'
    } as Filters)
    const rescheduableClassesUrl = `/${roleToURLPrefix(props.role)}/classes?${params}`

   return <TooltipIconButton onClick={(e) => { e.stopPropagation(); history.push(rescheduableClassesUrl)}} tooltip="Ver clases reprogramables">
      <ScheduleIcon />
    </TooltipIconButton>
  }

  const dispatch = useDispatch()

  const sentToCeibalChanged = (assignation: Assignation) => (sentToCeibal: boolean) => {
    dispatch(updateAssignation({ id: assignation.id, shouldSendToCeibal: !sentToCeibal } as Assignation, 'Asignacion actualizada'))
  }

  const rows: TableRow<Row>[] = props.rows.map((row: Row, index: number) => {
    const { data: assignation } = row

    return {
      cells: [
        { value: hasId(assignation) ? (assignation as Assignation).group.school.department : (assignation as AssignationInput).school.department },
        { value: hasId(assignation) ? (assignation as Assignation).group.school.number : (assignation as AssignationInput).school.number },
        { value: hasId(assignation) ? ViewHistoryClassesLink(row, index) : (assignation as AssignationInput).grade }, 
        { value: capitalize(moment(assignation.dayOfWeek, 'e').format('dddd')) },
        { value: assignation.sinceTime },
        { value: moment(assignation.firstClassDate).format('DD/MM') },
        { value: hasId(assignation) ? `${capitalize((assignation as Assignation).teacher?.lastName)}, ${capitalize((assignation as Assignation).teacher?.firstName)}` : "", visible: isAdmin() || isReferent() },
        { value: hasId(assignation) ? statusToDescription((assignation as Assignation).status) : "" },
        { value: hasId(assignation) ? <SentToCeibal defaultChecked={!(assignation as Assignation).shouldSendToCeibal} onChange={sentToCeibalChanged(assignation as Assignation)} /> : "", visible: isAdmin() }, // It is in SVC if it shouldn't send to ceibal
        { value: props.showInfoButton ? InfoButton(row, index) : <span /> },
        { value: hasId(assignation) ? ShowReschedulableClasses(assignation as Assignation) : <span /> },
        { value: props.showCreateClassButton ? CreateClassButton(row, index) : <span /> },
        { value: hasId(assignation) ? <DeleteAssignationButton assignation={assignation as Assignation} /> : <span />, visible: isAdmin() }
      ],
      model: row
    }
  })

  return (
    <div>
      <Table
        aria-label="classrooms"
        headerrow={{
          hover: true,
          cells: [
            { value: "Departamento" },
            { value: "N° Escuela" },
            { value: "Grado" },
            { value: "Día" },
            { value: "Horario" },
            { value: "Fecha inicio" },
            { value: "DR", visible: isAdmin() || isReferent() },
            { value: "Estado" },
            { value: "En SVC", visible: isAdmin() },
            { value: "" },
            { value: "Reprogramar" },
            { value: "Clase", visible: props.showCreateClassButton },
            { value: "", visible: isAdmin() }
          ]
        }}
        bodyrows={rows}
        progress={{ loading: props.loading }}
        pagination={props.pagination}
        onChangePageHandler={(_, page) => props.onChangePage && props.onChangePage(page < 0 ? 0 : page)}
        onRowClick={(row: Row) => props.onRowClick && props.onRowClick(row)}
      />
      {state.isOpenCreateClass && <CreateClassDialog
        assignation={state.selectedAssignation!}
        onClose={() => setState({ ...state, isOpenCreateClass: false })}
        open={state.isOpenCreateClass}
      />}
      {state.viewHistoryClasses && <HistoryGroupClassReportDialog
        assignation={state.selectedAssignation!}
        onClose={() => setState({ ...state, viewHistoryClasses: false })}
        open={state.viewHistoryClasses}
      />}
    </div>
  )
}

export default AssignationListTable
import { Moment } from 'moment'
import { Day, days } from '../utils/days'

export const slotsTime = [
  { since: '08:30', until: '09:15' },
  { since: '09:25', until: '10:10' },
  { since: '10:20', until: '11:05' },
  { since: '11:15', until: '12:00' },
  { since: '13:30', until: '14:15' },
  { since: '14:25', until: '15:10' },
  { since: '15:20', until: '16:05' },
  { since: '16:15', until: '17:00' },
]

export const formatTime = (time : Moment): string => time.format('HH:mm')

export const dayNumber = (day: Day): number => days.indexOf(day)

export const slotNumber = (sinceTime: Moment): number => {
  return slotsTime.findIndex((time) => formatTime(sinceTime) === time.since)
}

export interface AvailabilitySlot {
  slotTime: number
  dayOfWeek: number
  isAssigned?: boolean
}

export const sameSlot = ({ dayOfWeek, slotTime }: AvailabilitySlot) => {
  const slot1 = { dayOfWeek, slotTime }
  return ({ dayOfWeek, slotTime }: AvailabilitySlot) => {
    const slot2 = { dayOfWeek, slotTime }
    return JSON.stringify(slot1) === JSON.stringify(slot2)
  }
} 

export const toSlotString = (slotTime: number) => `${slotsTime[slotTime].since} - ${slotsTime[slotTime].until}`
